@import "mixins/icons";
@import "form-elements/radio-pills";

.form-group {
  margin-bottom: 1rem;
  @include media-breakpoint-up(lg) {
    margin-bottom: 1.5rem;
  }
  .error {
    font-size: $font-size-sm;
  }
}

.form-heading {
  margin-bottom: .5rem;
  font-family: $font-family-questa-sans;
  font-size: 1.375rem;
  font-weight: $font-weight-bold;
  line-height: 1.33;

  @include media-breakpoint-up(md) {
    margin-bottom: 1rem;
    font-size: 1.5rem;
  }
}

label {
  font-family: $font-family-hk-grotesk;
  color: $gray-700;
  &.col-form-label {
    padding-top: 0;
  }
}

legend {
  @extend label;
}

.form-wizard {
  .form-heading {
    margin-bottom: 1rem;
    line-height: $h3-line-height;
    color: $gray-800;
    @include media-breakpoint-up(lg) {
      margin-bottom: 1.5rem;
    }
  }
  .form-group {
    margin-bottom: 1rem;
    @include media-breakpoint-up(lg) {
      margin-bottom: 1.5rem;
    }

    label:not(.custom-control-label):not(.form-heading) {
      line-height: 1.5;
    }

    .form-text {
      font-size: 14px;
      line-height: 1.43;
    }

    .text-danger {
      margin-top: .5rem;
      line-height: 1.25;
    }
  }

  .mvm-show-project-description-field {
    .form-group {
      margin-bottom: .5rem;
    }
  }
}

.form-horizontal {
  .form-row {
    display: flex;
    align-items: center;

    &.radio-row,
    &.checkbox-row {
      align-items: flex-start;

      .custom-control-label {
        width: 100%;
        cursor: pointer;
      }
    }

    &.text-area-row {
      align-items: flex-start;

      .control-label {
        margin-top: 11px;
      }
    }
  }

  .control-label,
  .form-help-text {
    width: 100%;

    @include media-breakpoint-up(md) {
      float: right;
      margin-right: 1.25rem;
      margin-bottom: 0;
      text-align: right;
    }

    &.checkbox {
      padding: 0;

      &::after,
      &::before {
        margin: 0;
      }
    }
  }

  .control-label + .form-help-text {
    margin-top: -8px;

    @include media-breakpoint-up(md) {
      margin-top: 0;
    }
  }
}

.sd-form-row-valid {
  .form-control,
  .btn,
  label,
  button,
  select {
    border-color: $success;
  }
}

.sd-form-row-invalid {
  .btn {
    border-color: $danger;
  }

  .form-help-text {
    display: block;
    color: $gray-600;
  }
}

.input-icon {

  &.wizard {
    right: $form-validation-icon-margin-left;
  }

  &.icon-valid {
    background-image: get-check-icon($success);
  }

  &.icon-loading {
    width: 2rem;
    background-image: $custom-icon-loading;
  }
}

.sd-custom-control-label-group {
  width: 100%;
  line-height: $custom-control-input-group-label-line-height;
}

.was-validated {
  .sd-radio-button,
  .sd-checkbox-button {
    .custom-control-input:valid + .custom-control-label {
      color: $gray-700;
      &::before {
        background-color: transparent;
        border-color: $gray-400;
      }
    }
  }

}

.sd-radio-button,
.sd-checkbox-button {
  min-height: auto;
  border: 1px solid $gray-300;
  border-radius: $input-button-radius;
  box-shadow: 0 3px 0 0 rgba(51, 20, 5, .03);

  &:hover {
    cursor: pointer;
    border-color: $secondary-light;
    box-shadow: 0 3px 0 0 rgba(62, 156, 250, .25);
  }

  &.selected {
    line-height: 1.5;
    border: 2px solid $secondary;
    box-shadow: none;
    .custom-control-label {
      padding-left: 38px;
      line-height: 1.5;
      color: $gray-800;
    }
  }

  &.no-validation-icon {
    .input-icon.icon-invalid {
      display: none;
    }
  }

  &.vertical {
    &:last-child {
      margin-bottom: 0;
    }
    margin-bottom: .5rem;
  }

  &.horizontal {
    &.stretched {
      width: 100%;
    }

    &:first-child {
      margin-left: 0;
    }
    margin-left: .5rem;
  }

  &.tiles {
    .custom-control-input:checked ~ .custom-control-label,
    .custom-control-input:active:not(:disabled) ~ .custom-control-label {
      &::before {
        display: none;
        background: none;
      }
      &::after {
        display: none;
        background-image: none;
      }
    }

    .custom-control {
      padding-left: 0;
    }
    .custom-checkbox,
    .custom-radio {
      .custom-control-label {
        padding: .5rem 1rem;
        &::before {
          display: none;
        }
        &::after {
          display: none;
        }
      }
    }
  }

  .custom-checkbox,
  .custom-radio {
    padding-left: 0;
    .custom-control-label {
      padding: 8px 0 8px 39px;
      &::before {
        top: .75rem;
        left: 14px;
      }
      &::after {
        top: .75rem;
        left: 14px;
      }
    }
  }

  .custom-control-label {
    width: 100%;
    line-height: $line-height-base;
    &:hover {
      cursor: inherit;
    }
  }

  .custom-control {
    min-height: auto;
  }
}

.custom-control-input.is-invalid {
  // When the custom checkbox is unchecked
  ~ .custom-control-label {
    color: inherit; // Keep the default text color

    &::before {
      border-color: #ced4da; // Default border color
    }
  }

  // When the custom checkbox is focused
  &:focus ~ .custom-control-label::before {
    border-color: #ced4da; // Default border color
    box-shadow: none;      // Remove red glow
  }

  &:focus {
    &:not(:checked) ~ .custom-control-label::before {
      border-color: #ced4da; // Default border color
    }
  }

  // When the custom checkbox is checked
  &:checked ~ .custom-control-label::before {
    background-color: transparent;
    border-color: #ced4da; // Default border color
  }
}

.custom-textarea {
  min-height: 164px;
  max-height: 404px;
}


/* Selector needs to be more strict than bootstrap to override the hero */
/* stylelint-disable selector-no-qualifying-type */
select.form-control.form-control-hero:not([size]):not([multiple]) {
  height: $hero-form-control-height-md;
}
/* stylelint-enable selector-no-qualifying-type */

.form-control {
  color: $gray-700;
  border: 2px solid $gray-300;
  @include form-control-focus($ignore-warning: true);
  &.form-control-lg {
    padding: calc(#{$input-padding-y-lg} - .125rem) $input-padding-x-lg calc(#{$input-padding-y-lg} - .125rem) $input-padding-x-lg;
    line-height: $input-btn-line-height-lg;
  }
  @include media-breakpoint-up(lg) {
    &.form-control-lg-lg {
      height: $input-height-lg;
      padding: calc(#{$input-padding-y-lg} - .125rem) $input-padding-x-lg calc(#{$input-padding-y-lg} - .125rem) $input-padding-x-lg;
      font-size: $font-size-lg;
      line-height: $input-btn-line-height-lg;
    }
  }

  &.form-control-hero {
    @extend .border-radius;
    height: $input-height-hero;
    padding: calc(#{$input-padding-y-hero} - .125rem) $input-padding-x-hero;
    font-size: $font-size-hero;
    line-height: $input-btn-line-height-hero;

    &.custom-select {
      padding-right: calc(#{$input-padding-x-hero} + .75rem);
    }
  }
}

.form-control-edit-phone-number {
  &:read-only {
    background-color: transparent;

    &::placeholder {
      color: $gray-700;
    }
  }

  cursor: pointer;

  &:active ~ .icon-modify-verification-phone {
    filter: brightness(0) saturate(100%) invert(55%) sepia(41%) saturate(3159%) hue-rotate(109deg) brightness(100%) contrast(85%);
  }

  &:hover ~ .icon-modify-verification-phone {
    filter: brightness(0) saturate(100%) invert(38%) sepia(70%) saturate(1062%) hue-rotate(116deg) brightness(92%) contrast(101%);
  }

  &.is-valid,
  &.is-invalid {
    background-image: unset;
  }

  &.is-valid ~ .icon-modify-verification-phone {
    filter: brightness(0) saturate(100%) invert(70%) sepia(41%) saturate(5205%) hue-rotate(103deg) brightness(95%) contrast(85%);
  }

  &.is-invalid ~ .icon-modify-verification-phone {
    filter: brightness(0) saturate(100%) invert(52%) sepia(29%) saturate(4634%) hue-rotate(329deg) brightness(89%) contrast(109%);
  }
}

.custom-radio,
.custom-checkbox {
  .custom-control-label {
    font-size: $font-size-base;
    color: $gray-700;
  }
}

.custom-checkbox .custom-control-label {
  &::before {
    border-radius: 2px;
  }
}

.custom-radio {
  .custom-control-label {
    padding: 0;
    font-size: $font-size-base;

    &:hover {
      cursor: pointer;
    }

    &::before {
      margin: 0;
    }

    &::after {
      margin: 0;
    }
  }

  .custom-control-input:checked ~ .custom-control-label {
    &::after {
      @include radio-icon(1.125rem, $primary);
    }
  }

  .custom-control-input:active:not(:disabled) ~ .custom-control-label::after {
    @include radio-icon(1.125rem, $primary);
  }
}

.custom-control-inline .custom-control-label {
  margin-left: .625rem;
}

.input-wrapper {
  display: flex;

  .input-group:first-child {
    .form-control {
      border-radius: $border-radius;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .input-group:last-child {
    .form-control {
      border-radius: $border-radius;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}

.custom-control {
  .custom-control-label:hover {
    cursor: pointer;
  }
}

/* The text-transform needs to be important, otherwise it is not applied on IE10 */
/* stylelint-disable declaration-no-important */
::placeholder { /* Recent browsers */
  text-transform: none !important;
}

@include media-breakpoint-up(md) {
  .form-control.hide-placeholder::placeholder {
    color: #fff;
  }
}

/* stylelint-enable declaration-no-important */

.btn-input-group {
  border-width: $input-border-width;
}

//Workaround for date-picker issue
//https://github.com/angular-ui/bootstrap/issues/6534
//https://github.com/angular-ui/bootstrap/issues/5446
/* stylelint-disable declaration-no-important */
.uib-day.ng-leave-prepare {
  display: none !important;
}

.sd-image-radio-button {
  .custom-control-label {
    padding: 10px 15px !important;
  }
}

/* stylelint-enable declaration-no-important */

.datepicker-input-icon {
  padding-right: 0;
  padding-left: 29px;
  font-size: 13px;
  line-height: 1.5rem;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Cpath fill='%23999' fill-rule='nonzero' d='M17 8H3v9h14V8zM3 7h14V4h-1V3h1a1 1 0 0 1 1 1v13a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h1v1H3v3zm4-3V3h6v1H7zM5 1.5a.5.5 0 0 1 1 0v4a.5.5 0 0 1-1 0v-4zm9 0a.5.5 0 1 1 1 0v4a.5.5 0 1 1-1 0v-4z'/%3E%3C/svg%3E%0A") no-repeat left 6px center;
  border-radius: 0;
}

.contact-details-container {
  @include media-breakpoint-up(md) {
    border-left: none;
    .row-items {
      border-left: solid 1px $gray-300;
    }
  }
}

.notification-message-box {
  position: absolute;
  z-index: 1;
  padding: 1rem;
  margin-top: 4px;
  font-size: $font-size-sm;
  color: $gray-600;
  background: $white;
  border: 1px solid $gray-300;
  border-radius: 4px;
  box-shadow: $box-shadow-lg;
}

.move-phone-verification-container {
  /* stylelint-disable */
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
  /* stylelint-enable */
}
