@charset "UTF-8";
/**
  Import Variables
 */
.sd-text-gray-100 {
  color: #faf8f7 !important;
}

.sd-text-gray-150 {
  color: #f5f3f2 !important;
}

.sd-text-gray-200 {
  color: #f5f3f2 !important;
}

.sd-text-gray-300 {
  color: #e6e4e3 !important;
}

.sd-text-gray-400 {
  color: #ccc9c8 !important;
}

.sd-text-gray-500 {
  color: #a6a2a1 !important;
}

.sd-text-gray-600 {
  color: #73706f !important;
}

.sd-text-gray-700 {
  color: #403e3d !important;
}

.sd-text-gray-800 {
  color: #1a1817 !important;
}

/**
  Import common SASS files

  TODO: Copy the parts that we need to the move styleguide directory
 */
/**
  Import mixins before _utilities
 */
.bg-gray-100 {
  background-color: #faf8f7 !important;
}

a.bg-gray-100:hover, a.bg-gray-100:focus,
button.bg-gray-100:hover,
button.bg-gray-100:focus {
  background-color: rgb(230.3846153846, 220.5384615385, 215.6153846154) !important;
}

.bg-gray-200 {
  background-color: #f5f3f2 !important;
}

a.bg-gray-200:hover, a.bg-gray-200:focus,
button.bg-gray-200:hover,
button.bg-gray-200:focus {
  background-color: rgb(222.8260869565, 216.3913043478, 213.1739130435) !important;
}

.bg-gray-300 {
  background-color: #e6e4e3 !important;
}

a.bg-gray-300:hover, a.bg-gray-300:focus,
button.bg-gray-300:hover,
button.bg-gray-300:focus {
  background-color: rgb(205.9433962264, 202.0188679245, 200.0566037736) !important;
}

.bg-gray-400 {
  background-color: #ccc9c8 !important;
}

a.bg-gray-400:hover, a.bg-gray-400:focus,
button.bg-gray-400:hover,
button.bg-gray-400:focus {
  background-color: rgb(179.4622641509, 175.0188679245, 173.5377358491) !important;
}

.bg-gray-500 {
  background-color: #a6a2a1 !important;
}

a.bg-gray-500:hover, a.bg-gray-500:focus,
button.bg-gray-500:hover,
button.bg-gray-500:focus {
  background-color: rgb(141.1967213115, 136.0819672131, 134.8032786885) !important;
}

.bg-gray-600 {
  background-color: #73706f !important;
}

a.bg-gray-600:hover, a.bg-gray-600:focus,
button.bg-gray-600:hover,
button.bg-gray-600:focus {
  background-color: rgb(89.0486725664, 86.7256637168, 85.9513274336) !important;
}

.bg-gray-700 {
  background-color: #403e3d !important;
}

a.bg-gray-700:hover, a.bg-gray-700:focus,
button.bg-gray-700:hover,
button.bg-gray-700:focus {
  background-color: rgb(37.888, 36.704, 36.112) !important;
}

.bg-gray-800 {
  background-color: #1a1817 !important;
}

a.bg-gray-800:hover, a.bg-gray-800:focus,
button.bg-gray-800:hover,
button.bg-gray-800:focus {
  background-color: black !important;
}

.bg-gray-900 {
  background-color: #212529 !important;
}

a.bg-gray-900:hover, a.bg-gray-900:focus,
button.bg-gray-900:hover,
button.bg-gray-900:focus {
  background-color: rgb(10.2567567568, 11.5, 12.7432432432) !important;
}

.bg-gray-150 {
  background-color: #f5f3f2 !important;
}

a.bg-gray-150:hover, a.bg-gray-150:focus,
button.bg-gray-150:hover,
button.bg-gray-150:focus {
  background-color: rgb(222.8260869565, 216.3913043478, 213.1739130435) !important;
}

.page-loading {
  display: none;
}

[ng\:cloak],
[ng-cloak],
[data-ng-cloak],
[x-ng-cloak],
.ng-cloak,
.x-ng-cloak {
  display: none !important;
}

.sd-m-0 {
  margin: 0 !important;
}

.sd-mt-0,
.sd-my-0 {
  margin-top: 0 !important;
}

.sd-mr-0,
.sd-mx-0 {
  margin-right: 0 !important;
}

.sd-mb-0,
.sd-my-0 {
  margin-bottom: 0 !important;
}

.sd-ml-0,
.sd-mx-0 {
  margin-left: 0 !important;
}

.sd-m-0_25 {
  margin: 0.25rem !important;
}

.sd-mt-0_25,
.sd-my-0_25 {
  margin-top: 0.25rem !important;
}

.sd-mr-0_25,
.sd-mx-0_25 {
  margin-right: 0.25rem !important;
}

.sd-mb-0_25,
.sd-my-0_25 {
  margin-bottom: 0.25rem !important;
}

.sd-ml-0_25,
.sd-mx-0_25 {
  margin-left: 0.25rem !important;
}

.sd-m-0_5 {
  margin: 0.5rem !important;
}

.sd-mt-0_5,
.sd-my-0_5 {
  margin-top: 0.5rem !important;
}

.sd-mr-0_5,
.sd-mx-0_5 {
  margin-right: 0.5rem !important;
}

.sd-mb-0_5,
.sd-my-0_5 {
  margin-bottom: 0.5rem !important;
}

.sd-ml-0_5,
.sd-mx-0_5 {
  margin-left: 0.5rem !important;
}

.sd-m-0_75 {
  margin: 0.75rem !important;
}

.sd-mt-0_75,
.sd-my-0_75 {
  margin-top: 0.75rem !important;
}

.sd-mr-0_75,
.sd-mx-0_75 {
  margin-right: 0.75rem !important;
}

.sd-mb-0_75,
.sd-my-0_75 {
  margin-bottom: 0.75rem !important;
}

.sd-ml-0_75,
.sd-mx-0_75 {
  margin-left: 0.75rem !important;
}

.sd-m-1 {
  margin: 1rem !important;
}

.sd-mt-1,
.sd-my-1 {
  margin-top: 1rem !important;
}

.sd-mr-1,
.sd-mx-1 {
  margin-right: 1rem !important;
}

.sd-mb-1,
.sd-my-1 {
  margin-bottom: 1rem !important;
}

.sd-ml-1,
.sd-mx-1 {
  margin-left: 1rem !important;
}

.sd-m-1_125 {
  margin: 1.125rem !important;
}

.sd-mt-1_125,
.sd-my-1_125 {
  margin-top: 1.125rem !important;
}

.sd-mr-1_125,
.sd-mx-1_125 {
  margin-right: 1.125rem !important;
}

.sd-mb-1_125,
.sd-my-1_125 {
  margin-bottom: 1.125rem !important;
}

.sd-ml-1_125,
.sd-mx-1_125 {
  margin-left: 1.125rem !important;
}

.sd-m-1_25 {
  margin: 1.25rem !important;
}

.sd-mt-1_25,
.sd-my-1_25 {
  margin-top: 1.25rem !important;
}

.sd-mr-1_25,
.sd-mx-1_25 {
  margin-right: 1.25rem !important;
}

.sd-mb-1_25,
.sd-my-1_25 {
  margin-bottom: 1.25rem !important;
}

.sd-ml-1_25,
.sd-mx-1_25 {
  margin-left: 1.25rem !important;
}

.sd-m-1_5 {
  margin: 1.5rem !important;
}

.sd-mt-1_5,
.sd-my-1_5 {
  margin-top: 1.5rem !important;
}

.sd-mr-1_5,
.sd-mx-1_5 {
  margin-right: 1.5rem !important;
}

.sd-mb-1_5,
.sd-my-1_5 {
  margin-bottom: 1.5rem !important;
}

.sd-ml-1_5,
.sd-mx-1_5 {
  margin-left: 1.5rem !important;
}

.sd-m-1_75 {
  margin: 1.75rem !important;
}

.sd-mt-1_75,
.sd-my-1_75 {
  margin-top: 1.75rem !important;
}

.sd-mr-1_75,
.sd-mx-1_75 {
  margin-right: 1.75rem !important;
}

.sd-mb-1_75,
.sd-my-1_75 {
  margin-bottom: 1.75rem !important;
}

.sd-ml-1_75,
.sd-mx-1_75 {
  margin-left: 1.75rem !important;
}

.sd-m-2 {
  margin: 2rem !important;
}

.sd-mt-2,
.sd-my-2 {
  margin-top: 2rem !important;
}

.sd-mr-2,
.sd-mx-2 {
  margin-right: 2rem !important;
}

.sd-mb-2,
.sd-my-2 {
  margin-bottom: 2rem !important;
}

.sd-ml-2,
.sd-mx-2 {
  margin-left: 2rem !important;
}

.sd-m-2_5 {
  margin: 2.5rem !important;
}

.sd-mt-2_5,
.sd-my-2_5 {
  margin-top: 2.5rem !important;
}

.sd-mr-2_5,
.sd-mx-2_5 {
  margin-right: 2.5rem !important;
}

.sd-mb-2_5,
.sd-my-2_5 {
  margin-bottom: 2.5rem !important;
}

.sd-ml-2_5,
.sd-mx-2_5 {
  margin-left: 2.5rem !important;
}

.sd-m-2_75 {
  margin: 2.75rem !important;
}

.sd-mt-2_75,
.sd-my-2_75 {
  margin-top: 2.75rem !important;
}

.sd-mr-2_75,
.sd-mx-2_75 {
  margin-right: 2.75rem !important;
}

.sd-mb-2_75,
.sd-my-2_75 {
  margin-bottom: 2.75rem !important;
}

.sd-ml-2_75,
.sd-mx-2_75 {
  margin-left: 2.75rem !important;
}

.sd-m-3 {
  margin: 3rem !important;
}

.sd-mt-3,
.sd-my-3 {
  margin-top: 3rem !important;
}

.sd-mr-3,
.sd-mx-3 {
  margin-right: 3rem !important;
}

.sd-mb-3,
.sd-my-3 {
  margin-bottom: 3rem !important;
}

.sd-ml-3,
.sd-mx-3 {
  margin-left: 3rem !important;
}

.sd-m-3_25 {
  margin: 3.25rem !important;
}

.sd-mt-3_25,
.sd-my-3_25 {
  margin-top: 3.25rem !important;
}

.sd-mr-3_25,
.sd-mx-3_25 {
  margin-right: 3.25rem !important;
}

.sd-mb-3_25,
.sd-my-3_25 {
  margin-bottom: 3.25rem !important;
}

.sd-ml-3_25,
.sd-mx-3_25 {
  margin-left: 3.25rem !important;
}

.sd-m-3_5 {
  margin: 3.5rem !important;
}

.sd-mt-3_5,
.sd-my-3_5 {
  margin-top: 3.5rem !important;
}

.sd-mr-3_5,
.sd-mx-3_5 {
  margin-right: 3.5rem !important;
}

.sd-mb-3_5,
.sd-my-3_5 {
  margin-bottom: 3.5rem !important;
}

.sd-ml-3_5,
.sd-mx-3_5 {
  margin-left: 3.5rem !important;
}

.sd-m-4 {
  margin: 4rem !important;
}

.sd-mt-4,
.sd-my-4 {
  margin-top: 4rem !important;
}

.sd-mr-4,
.sd-mx-4 {
  margin-right: 4rem !important;
}

.sd-mb-4,
.sd-my-4 {
  margin-bottom: 4rem !important;
}

.sd-ml-4,
.sd-mx-4 {
  margin-left: 4rem !important;
}

.sd-m-5 {
  margin: 5rem !important;
}

.sd-mt-5,
.sd-my-5 {
  margin-top: 5rem !important;
}

.sd-mr-5,
.sd-mx-5 {
  margin-right: 5rem !important;
}

.sd-mb-5,
.sd-my-5 {
  margin-bottom: 5rem !important;
}

.sd-ml-5,
.sd-mx-5 {
  margin-left: 5rem !important;
}

.sd-m-5_25 {
  margin: 5.25rem !important;
}

.sd-mt-5_25,
.sd-my-5_25 {
  margin-top: 5.25rem !important;
}

.sd-mr-5_25,
.sd-mx-5_25 {
  margin-right: 5.25rem !important;
}

.sd-mb-5_25,
.sd-my-5_25 {
  margin-bottom: 5.25rem !important;
}

.sd-ml-5_25,
.sd-mx-5_25 {
  margin-left: 5.25rem !important;
}

.sd-m-5_5 {
  margin: 5.5rem !important;
}

.sd-mt-5_5,
.sd-my-5_5 {
  margin-top: 5.5rem !important;
}

.sd-mr-5_5,
.sd-mx-5_5 {
  margin-right: 5.5rem !important;
}

.sd-mb-5_5,
.sd-my-5_5 {
  margin-bottom: 5.5rem !important;
}

.sd-ml-5_5,
.sd-mx-5_5 {
  margin-left: 5.5rem !important;
}

.sd-m-6 {
  margin: 6rem !important;
}

.sd-mt-6,
.sd-my-6 {
  margin-top: 6rem !important;
}

.sd-mr-6,
.sd-mx-6 {
  margin-right: 6rem !important;
}

.sd-mb-6,
.sd-my-6 {
  margin-bottom: 6rem !important;
}

.sd-ml-6,
.sd-mx-6 {
  margin-left: 6rem !important;
}

.sd-m-8 {
  margin: 8rem !important;
}

.sd-mt-8,
.sd-my-8 {
  margin-top: 8rem !important;
}

.sd-mr-8,
.sd-mx-8 {
  margin-right: 8rem !important;
}

.sd-mb-8,
.sd-my-8 {
  margin-bottom: 8rem !important;
}

.sd-ml-8,
.sd-mx-8 {
  margin-left: 8rem !important;
}

.sd-m-9 {
  margin: 9rem !important;
}

.sd-mt-9,
.sd-my-9 {
  margin-top: 9rem !important;
}

.sd-mr-9,
.sd-mx-9 {
  margin-right: 9rem !important;
}

.sd-mb-9,
.sd-my-9 {
  margin-bottom: 9rem !important;
}

.sd-ml-9,
.sd-mx-9 {
  margin-left: 9rem !important;
}

.sd-p-0 {
  padding: 0 !important;
}

.sd-pt-0,
.sd-py-0 {
  padding-top: 0 !important;
}

.sd-pr-0,
.sd-px-0 {
  padding-right: 0 !important;
}

.sd-pb-0,
.sd-py-0 {
  padding-bottom: 0 !important;
}

.sd-pl-0,
.sd-px-0 {
  padding-left: 0 !important;
}

.sd-p-0_25 {
  padding: 0.25rem !important;
}

.sd-pt-0_25,
.sd-py-0_25 {
  padding-top: 0.25rem !important;
}

.sd-pr-0_25,
.sd-px-0_25 {
  padding-right: 0.25rem !important;
}

.sd-pb-0_25,
.sd-py-0_25 {
  padding-bottom: 0.25rem !important;
}

.sd-pl-0_25,
.sd-px-0_25 {
  padding-left: 0.25rem !important;
}

.sd-p-0_5 {
  padding: 0.5rem !important;
}

.sd-pt-0_5,
.sd-py-0_5 {
  padding-top: 0.5rem !important;
}

.sd-pr-0_5,
.sd-px-0_5 {
  padding-right: 0.5rem !important;
}

.sd-pb-0_5,
.sd-py-0_5 {
  padding-bottom: 0.5rem !important;
}

.sd-pl-0_5,
.sd-px-0_5 {
  padding-left: 0.5rem !important;
}

.sd-p-0_75 {
  padding: 0.75rem !important;
}

.sd-pt-0_75,
.sd-py-0_75 {
  padding-top: 0.75rem !important;
}

.sd-pr-0_75,
.sd-px-0_75 {
  padding-right: 0.75rem !important;
}

.sd-pb-0_75,
.sd-py-0_75 {
  padding-bottom: 0.75rem !important;
}

.sd-pl-0_75,
.sd-px-0_75 {
  padding-left: 0.75rem !important;
}

.sd-p-1 {
  padding: 1rem !important;
}

.sd-pt-1,
.sd-py-1 {
  padding-top: 1rem !important;
}

.sd-pr-1,
.sd-px-1 {
  padding-right: 1rem !important;
}

.sd-pb-1,
.sd-py-1 {
  padding-bottom: 1rem !important;
}

.sd-pl-1,
.sd-px-1 {
  padding-left: 1rem !important;
}

.sd-p-1_125 {
  padding: 1.125rem !important;
}

.sd-pt-1_125,
.sd-py-1_125 {
  padding-top: 1.125rem !important;
}

.sd-pr-1_125,
.sd-px-1_125 {
  padding-right: 1.125rem !important;
}

.sd-pb-1_125,
.sd-py-1_125 {
  padding-bottom: 1.125rem !important;
}

.sd-pl-1_125,
.sd-px-1_125 {
  padding-left: 1.125rem !important;
}

.sd-p-1_25 {
  padding: 1.25rem !important;
}

.sd-pt-1_25,
.sd-py-1_25 {
  padding-top: 1.25rem !important;
}

.sd-pr-1_25,
.sd-px-1_25 {
  padding-right: 1.25rem !important;
}

.sd-pb-1_25,
.sd-py-1_25 {
  padding-bottom: 1.25rem !important;
}

.sd-pl-1_25,
.sd-px-1_25 {
  padding-left: 1.25rem !important;
}

.sd-p-1_5 {
  padding: 1.5rem !important;
}

.sd-pt-1_5,
.sd-py-1_5 {
  padding-top: 1.5rem !important;
}

.sd-pr-1_5,
.sd-px-1_5 {
  padding-right: 1.5rem !important;
}

.sd-pb-1_5,
.sd-py-1_5 {
  padding-bottom: 1.5rem !important;
}

.sd-pl-1_5,
.sd-px-1_5 {
  padding-left: 1.5rem !important;
}

.sd-p-1_75 {
  padding: 1.75rem !important;
}

.sd-pt-1_75,
.sd-py-1_75 {
  padding-top: 1.75rem !important;
}

.sd-pr-1_75,
.sd-px-1_75 {
  padding-right: 1.75rem !important;
}

.sd-pb-1_75,
.sd-py-1_75 {
  padding-bottom: 1.75rem !important;
}

.sd-pl-1_75,
.sd-px-1_75 {
  padding-left: 1.75rem !important;
}

.sd-p-2 {
  padding: 2rem !important;
}

.sd-pt-2,
.sd-py-2 {
  padding-top: 2rem !important;
}

.sd-pr-2,
.sd-px-2 {
  padding-right: 2rem !important;
}

.sd-pb-2,
.sd-py-2 {
  padding-bottom: 2rem !important;
}

.sd-pl-2,
.sd-px-2 {
  padding-left: 2rem !important;
}

.sd-p-2_5 {
  padding: 2.5rem !important;
}

.sd-pt-2_5,
.sd-py-2_5 {
  padding-top: 2.5rem !important;
}

.sd-pr-2_5,
.sd-px-2_5 {
  padding-right: 2.5rem !important;
}

.sd-pb-2_5,
.sd-py-2_5 {
  padding-bottom: 2.5rem !important;
}

.sd-pl-2_5,
.sd-px-2_5 {
  padding-left: 2.5rem !important;
}

.sd-p-2_75 {
  padding: 2.75rem !important;
}

.sd-pt-2_75,
.sd-py-2_75 {
  padding-top: 2.75rem !important;
}

.sd-pr-2_75,
.sd-px-2_75 {
  padding-right: 2.75rem !important;
}

.sd-pb-2_75,
.sd-py-2_75 {
  padding-bottom: 2.75rem !important;
}

.sd-pl-2_75,
.sd-px-2_75 {
  padding-left: 2.75rem !important;
}

.sd-p-3 {
  padding: 3rem !important;
}

.sd-pt-3,
.sd-py-3 {
  padding-top: 3rem !important;
}

.sd-pr-3,
.sd-px-3 {
  padding-right: 3rem !important;
}

.sd-pb-3,
.sd-py-3 {
  padding-bottom: 3rem !important;
}

.sd-pl-3,
.sd-px-3 {
  padding-left: 3rem !important;
}

.sd-p-3_25 {
  padding: 3.25rem !important;
}

.sd-pt-3_25,
.sd-py-3_25 {
  padding-top: 3.25rem !important;
}

.sd-pr-3_25,
.sd-px-3_25 {
  padding-right: 3.25rem !important;
}

.sd-pb-3_25,
.sd-py-3_25 {
  padding-bottom: 3.25rem !important;
}

.sd-pl-3_25,
.sd-px-3_25 {
  padding-left: 3.25rem !important;
}

.sd-p-3_5 {
  padding: 3.5rem !important;
}

.sd-pt-3_5,
.sd-py-3_5 {
  padding-top: 3.5rem !important;
}

.sd-pr-3_5,
.sd-px-3_5 {
  padding-right: 3.5rem !important;
}

.sd-pb-3_5,
.sd-py-3_5 {
  padding-bottom: 3.5rem !important;
}

.sd-pl-3_5,
.sd-px-3_5 {
  padding-left: 3.5rem !important;
}

.sd-p-4 {
  padding: 4rem !important;
}

.sd-pt-4,
.sd-py-4 {
  padding-top: 4rem !important;
}

.sd-pr-4,
.sd-px-4 {
  padding-right: 4rem !important;
}

.sd-pb-4,
.sd-py-4 {
  padding-bottom: 4rem !important;
}

.sd-pl-4,
.sd-px-4 {
  padding-left: 4rem !important;
}

.sd-p-5 {
  padding: 5rem !important;
}

.sd-pt-5,
.sd-py-5 {
  padding-top: 5rem !important;
}

.sd-pr-5,
.sd-px-5 {
  padding-right: 5rem !important;
}

.sd-pb-5,
.sd-py-5 {
  padding-bottom: 5rem !important;
}

.sd-pl-5,
.sd-px-5 {
  padding-left: 5rem !important;
}

.sd-p-5_25 {
  padding: 5.25rem !important;
}

.sd-pt-5_25,
.sd-py-5_25 {
  padding-top: 5.25rem !important;
}

.sd-pr-5_25,
.sd-px-5_25 {
  padding-right: 5.25rem !important;
}

.sd-pb-5_25,
.sd-py-5_25 {
  padding-bottom: 5.25rem !important;
}

.sd-pl-5_25,
.sd-px-5_25 {
  padding-left: 5.25rem !important;
}

.sd-p-5_5 {
  padding: 5.5rem !important;
}

.sd-pt-5_5,
.sd-py-5_5 {
  padding-top: 5.5rem !important;
}

.sd-pr-5_5,
.sd-px-5_5 {
  padding-right: 5.5rem !important;
}

.sd-pb-5_5,
.sd-py-5_5 {
  padding-bottom: 5.5rem !important;
}

.sd-pl-5_5,
.sd-px-5_5 {
  padding-left: 5.5rem !important;
}

.sd-p-6 {
  padding: 6rem !important;
}

.sd-pt-6,
.sd-py-6 {
  padding-top: 6rem !important;
}

.sd-pr-6,
.sd-px-6 {
  padding-right: 6rem !important;
}

.sd-pb-6,
.sd-py-6 {
  padding-bottom: 6rem !important;
}

.sd-pl-6,
.sd-px-6 {
  padding-left: 6rem !important;
}

.sd-p-8 {
  padding: 8rem !important;
}

.sd-pt-8,
.sd-py-8 {
  padding-top: 8rem !important;
}

.sd-pr-8,
.sd-px-8 {
  padding-right: 8rem !important;
}

.sd-pb-8,
.sd-py-8 {
  padding-bottom: 8rem !important;
}

.sd-pl-8,
.sd-px-8 {
  padding-left: 8rem !important;
}

.sd-p-9 {
  padding: 9rem !important;
}

.sd-pt-9,
.sd-py-9 {
  padding-top: 9rem !important;
}

.sd-pr-9,
.sd-px-9 {
  padding-right: 9rem !important;
}

.sd-pb-9,
.sd-py-9 {
  padding-bottom: 9rem !important;
}

.sd-pl-9,
.sd-px-9 {
  padding-left: 9rem !important;
}

.sd-m-auto {
  margin: auto !important;
}

.sd-mt-auto,
.sd-my-auto {
  margin-top: auto !important;
}

.sd-mr-auto,
.sd-mx-auto {
  margin-right: auto !important;
}

.sd-mb-auto,
.sd-my-auto {
  margin-bottom: auto !important;
}

.sd-ml-auto,
.sd-mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .sd-m-sm-0 {
    margin: 0 !important;
  }
  .sd-mt-sm-0,
  .sd-my-sm-0 {
    margin-top: 0 !important;
  }
  .sd-mr-sm-0,
  .sd-mx-sm-0 {
    margin-right: 0 !important;
  }
  .sd-mb-sm-0,
  .sd-my-sm-0 {
    margin-bottom: 0 !important;
  }
  .sd-ml-sm-0,
  .sd-mx-sm-0 {
    margin-left: 0 !important;
  }
  .sd-m-sm-0_25 {
    margin: 0.25rem !important;
  }
  .sd-mt-sm-0_25,
  .sd-my-sm-0_25 {
    margin-top: 0.25rem !important;
  }
  .sd-mr-sm-0_25,
  .sd-mx-sm-0_25 {
    margin-right: 0.25rem !important;
  }
  .sd-mb-sm-0_25,
  .sd-my-sm-0_25 {
    margin-bottom: 0.25rem !important;
  }
  .sd-ml-sm-0_25,
  .sd-mx-sm-0_25 {
    margin-left: 0.25rem !important;
  }
  .sd-m-sm-0_5 {
    margin: 0.5rem !important;
  }
  .sd-mt-sm-0_5,
  .sd-my-sm-0_5 {
    margin-top: 0.5rem !important;
  }
  .sd-mr-sm-0_5,
  .sd-mx-sm-0_5 {
    margin-right: 0.5rem !important;
  }
  .sd-mb-sm-0_5,
  .sd-my-sm-0_5 {
    margin-bottom: 0.5rem !important;
  }
  .sd-ml-sm-0_5,
  .sd-mx-sm-0_5 {
    margin-left: 0.5rem !important;
  }
  .sd-m-sm-0_75 {
    margin: 0.75rem !important;
  }
  .sd-mt-sm-0_75,
  .sd-my-sm-0_75 {
    margin-top: 0.75rem !important;
  }
  .sd-mr-sm-0_75,
  .sd-mx-sm-0_75 {
    margin-right: 0.75rem !important;
  }
  .sd-mb-sm-0_75,
  .sd-my-sm-0_75 {
    margin-bottom: 0.75rem !important;
  }
  .sd-ml-sm-0_75,
  .sd-mx-sm-0_75 {
    margin-left: 0.75rem !important;
  }
  .sd-m-sm-1 {
    margin: 1rem !important;
  }
  .sd-mt-sm-1,
  .sd-my-sm-1 {
    margin-top: 1rem !important;
  }
  .sd-mr-sm-1,
  .sd-mx-sm-1 {
    margin-right: 1rem !important;
  }
  .sd-mb-sm-1,
  .sd-my-sm-1 {
    margin-bottom: 1rem !important;
  }
  .sd-ml-sm-1,
  .sd-mx-sm-1 {
    margin-left: 1rem !important;
  }
  .sd-m-sm-1_125 {
    margin: 1.125rem !important;
  }
  .sd-mt-sm-1_125,
  .sd-my-sm-1_125 {
    margin-top: 1.125rem !important;
  }
  .sd-mr-sm-1_125,
  .sd-mx-sm-1_125 {
    margin-right: 1.125rem !important;
  }
  .sd-mb-sm-1_125,
  .sd-my-sm-1_125 {
    margin-bottom: 1.125rem !important;
  }
  .sd-ml-sm-1_125,
  .sd-mx-sm-1_125 {
    margin-left: 1.125rem !important;
  }
  .sd-m-sm-1_25 {
    margin: 1.25rem !important;
  }
  .sd-mt-sm-1_25,
  .sd-my-sm-1_25 {
    margin-top: 1.25rem !important;
  }
  .sd-mr-sm-1_25,
  .sd-mx-sm-1_25 {
    margin-right: 1.25rem !important;
  }
  .sd-mb-sm-1_25,
  .sd-my-sm-1_25 {
    margin-bottom: 1.25rem !important;
  }
  .sd-ml-sm-1_25,
  .sd-mx-sm-1_25 {
    margin-left: 1.25rem !important;
  }
  .sd-m-sm-1_5 {
    margin: 1.5rem !important;
  }
  .sd-mt-sm-1_5,
  .sd-my-sm-1_5 {
    margin-top: 1.5rem !important;
  }
  .sd-mr-sm-1_5,
  .sd-mx-sm-1_5 {
    margin-right: 1.5rem !important;
  }
  .sd-mb-sm-1_5,
  .sd-my-sm-1_5 {
    margin-bottom: 1.5rem !important;
  }
  .sd-ml-sm-1_5,
  .sd-mx-sm-1_5 {
    margin-left: 1.5rem !important;
  }
  .sd-m-sm-1_75 {
    margin: 1.75rem !important;
  }
  .sd-mt-sm-1_75,
  .sd-my-sm-1_75 {
    margin-top: 1.75rem !important;
  }
  .sd-mr-sm-1_75,
  .sd-mx-sm-1_75 {
    margin-right: 1.75rem !important;
  }
  .sd-mb-sm-1_75,
  .sd-my-sm-1_75 {
    margin-bottom: 1.75rem !important;
  }
  .sd-ml-sm-1_75,
  .sd-mx-sm-1_75 {
    margin-left: 1.75rem !important;
  }
  .sd-m-sm-2 {
    margin: 2rem !important;
  }
  .sd-mt-sm-2,
  .sd-my-sm-2 {
    margin-top: 2rem !important;
  }
  .sd-mr-sm-2,
  .sd-mx-sm-2 {
    margin-right: 2rem !important;
  }
  .sd-mb-sm-2,
  .sd-my-sm-2 {
    margin-bottom: 2rem !important;
  }
  .sd-ml-sm-2,
  .sd-mx-sm-2 {
    margin-left: 2rem !important;
  }
  .sd-m-sm-2_5 {
    margin: 2.5rem !important;
  }
  .sd-mt-sm-2_5,
  .sd-my-sm-2_5 {
    margin-top: 2.5rem !important;
  }
  .sd-mr-sm-2_5,
  .sd-mx-sm-2_5 {
    margin-right: 2.5rem !important;
  }
  .sd-mb-sm-2_5,
  .sd-my-sm-2_5 {
    margin-bottom: 2.5rem !important;
  }
  .sd-ml-sm-2_5,
  .sd-mx-sm-2_5 {
    margin-left: 2.5rem !important;
  }
  .sd-m-sm-2_75 {
    margin: 2.75rem !important;
  }
  .sd-mt-sm-2_75,
  .sd-my-sm-2_75 {
    margin-top: 2.75rem !important;
  }
  .sd-mr-sm-2_75,
  .sd-mx-sm-2_75 {
    margin-right: 2.75rem !important;
  }
  .sd-mb-sm-2_75,
  .sd-my-sm-2_75 {
    margin-bottom: 2.75rem !important;
  }
  .sd-ml-sm-2_75,
  .sd-mx-sm-2_75 {
    margin-left: 2.75rem !important;
  }
  .sd-m-sm-3 {
    margin: 3rem !important;
  }
  .sd-mt-sm-3,
  .sd-my-sm-3 {
    margin-top: 3rem !important;
  }
  .sd-mr-sm-3,
  .sd-mx-sm-3 {
    margin-right: 3rem !important;
  }
  .sd-mb-sm-3,
  .sd-my-sm-3 {
    margin-bottom: 3rem !important;
  }
  .sd-ml-sm-3,
  .sd-mx-sm-3 {
    margin-left: 3rem !important;
  }
  .sd-m-sm-3_25 {
    margin: 3.25rem !important;
  }
  .sd-mt-sm-3_25,
  .sd-my-sm-3_25 {
    margin-top: 3.25rem !important;
  }
  .sd-mr-sm-3_25,
  .sd-mx-sm-3_25 {
    margin-right: 3.25rem !important;
  }
  .sd-mb-sm-3_25,
  .sd-my-sm-3_25 {
    margin-bottom: 3.25rem !important;
  }
  .sd-ml-sm-3_25,
  .sd-mx-sm-3_25 {
    margin-left: 3.25rem !important;
  }
  .sd-m-sm-3_5 {
    margin: 3.5rem !important;
  }
  .sd-mt-sm-3_5,
  .sd-my-sm-3_5 {
    margin-top: 3.5rem !important;
  }
  .sd-mr-sm-3_5,
  .sd-mx-sm-3_5 {
    margin-right: 3.5rem !important;
  }
  .sd-mb-sm-3_5,
  .sd-my-sm-3_5 {
    margin-bottom: 3.5rem !important;
  }
  .sd-ml-sm-3_5,
  .sd-mx-sm-3_5 {
    margin-left: 3.5rem !important;
  }
  .sd-m-sm-4 {
    margin: 4rem !important;
  }
  .sd-mt-sm-4,
  .sd-my-sm-4 {
    margin-top: 4rem !important;
  }
  .sd-mr-sm-4,
  .sd-mx-sm-4 {
    margin-right: 4rem !important;
  }
  .sd-mb-sm-4,
  .sd-my-sm-4 {
    margin-bottom: 4rem !important;
  }
  .sd-ml-sm-4,
  .sd-mx-sm-4 {
    margin-left: 4rem !important;
  }
  .sd-m-sm-5 {
    margin: 5rem !important;
  }
  .sd-mt-sm-5,
  .sd-my-sm-5 {
    margin-top: 5rem !important;
  }
  .sd-mr-sm-5,
  .sd-mx-sm-5 {
    margin-right: 5rem !important;
  }
  .sd-mb-sm-5,
  .sd-my-sm-5 {
    margin-bottom: 5rem !important;
  }
  .sd-ml-sm-5,
  .sd-mx-sm-5 {
    margin-left: 5rem !important;
  }
  .sd-m-sm-5_25 {
    margin: 5.25rem !important;
  }
  .sd-mt-sm-5_25,
  .sd-my-sm-5_25 {
    margin-top: 5.25rem !important;
  }
  .sd-mr-sm-5_25,
  .sd-mx-sm-5_25 {
    margin-right: 5.25rem !important;
  }
  .sd-mb-sm-5_25,
  .sd-my-sm-5_25 {
    margin-bottom: 5.25rem !important;
  }
  .sd-ml-sm-5_25,
  .sd-mx-sm-5_25 {
    margin-left: 5.25rem !important;
  }
  .sd-m-sm-5_5 {
    margin: 5.5rem !important;
  }
  .sd-mt-sm-5_5,
  .sd-my-sm-5_5 {
    margin-top: 5.5rem !important;
  }
  .sd-mr-sm-5_5,
  .sd-mx-sm-5_5 {
    margin-right: 5.5rem !important;
  }
  .sd-mb-sm-5_5,
  .sd-my-sm-5_5 {
    margin-bottom: 5.5rem !important;
  }
  .sd-ml-sm-5_5,
  .sd-mx-sm-5_5 {
    margin-left: 5.5rem !important;
  }
  .sd-m-sm-6 {
    margin: 6rem !important;
  }
  .sd-mt-sm-6,
  .sd-my-sm-6 {
    margin-top: 6rem !important;
  }
  .sd-mr-sm-6,
  .sd-mx-sm-6 {
    margin-right: 6rem !important;
  }
  .sd-mb-sm-6,
  .sd-my-sm-6 {
    margin-bottom: 6rem !important;
  }
  .sd-ml-sm-6,
  .sd-mx-sm-6 {
    margin-left: 6rem !important;
  }
  .sd-m-sm-8 {
    margin: 8rem !important;
  }
  .sd-mt-sm-8,
  .sd-my-sm-8 {
    margin-top: 8rem !important;
  }
  .sd-mr-sm-8,
  .sd-mx-sm-8 {
    margin-right: 8rem !important;
  }
  .sd-mb-sm-8,
  .sd-my-sm-8 {
    margin-bottom: 8rem !important;
  }
  .sd-ml-sm-8,
  .sd-mx-sm-8 {
    margin-left: 8rem !important;
  }
  .sd-m-sm-9 {
    margin: 9rem !important;
  }
  .sd-mt-sm-9,
  .sd-my-sm-9 {
    margin-top: 9rem !important;
  }
  .sd-mr-sm-9,
  .sd-mx-sm-9 {
    margin-right: 9rem !important;
  }
  .sd-mb-sm-9,
  .sd-my-sm-9 {
    margin-bottom: 9rem !important;
  }
  .sd-ml-sm-9,
  .sd-mx-sm-9 {
    margin-left: 9rem !important;
  }
  .sd-p-sm-0 {
    padding: 0 !important;
  }
  .sd-pt-sm-0,
  .sd-py-sm-0 {
    padding-top: 0 !important;
  }
  .sd-pr-sm-0,
  .sd-px-sm-0 {
    padding-right: 0 !important;
  }
  .sd-pb-sm-0,
  .sd-py-sm-0 {
    padding-bottom: 0 !important;
  }
  .sd-pl-sm-0,
  .sd-px-sm-0 {
    padding-left: 0 !important;
  }
  .sd-p-sm-0_25 {
    padding: 0.25rem !important;
  }
  .sd-pt-sm-0_25,
  .sd-py-sm-0_25 {
    padding-top: 0.25rem !important;
  }
  .sd-pr-sm-0_25,
  .sd-px-sm-0_25 {
    padding-right: 0.25rem !important;
  }
  .sd-pb-sm-0_25,
  .sd-py-sm-0_25 {
    padding-bottom: 0.25rem !important;
  }
  .sd-pl-sm-0_25,
  .sd-px-sm-0_25 {
    padding-left: 0.25rem !important;
  }
  .sd-p-sm-0_5 {
    padding: 0.5rem !important;
  }
  .sd-pt-sm-0_5,
  .sd-py-sm-0_5 {
    padding-top: 0.5rem !important;
  }
  .sd-pr-sm-0_5,
  .sd-px-sm-0_5 {
    padding-right: 0.5rem !important;
  }
  .sd-pb-sm-0_5,
  .sd-py-sm-0_5 {
    padding-bottom: 0.5rem !important;
  }
  .sd-pl-sm-0_5,
  .sd-px-sm-0_5 {
    padding-left: 0.5rem !important;
  }
  .sd-p-sm-0_75 {
    padding: 0.75rem !important;
  }
  .sd-pt-sm-0_75,
  .sd-py-sm-0_75 {
    padding-top: 0.75rem !important;
  }
  .sd-pr-sm-0_75,
  .sd-px-sm-0_75 {
    padding-right: 0.75rem !important;
  }
  .sd-pb-sm-0_75,
  .sd-py-sm-0_75 {
    padding-bottom: 0.75rem !important;
  }
  .sd-pl-sm-0_75,
  .sd-px-sm-0_75 {
    padding-left: 0.75rem !important;
  }
  .sd-p-sm-1 {
    padding: 1rem !important;
  }
  .sd-pt-sm-1,
  .sd-py-sm-1 {
    padding-top: 1rem !important;
  }
  .sd-pr-sm-1,
  .sd-px-sm-1 {
    padding-right: 1rem !important;
  }
  .sd-pb-sm-1,
  .sd-py-sm-1 {
    padding-bottom: 1rem !important;
  }
  .sd-pl-sm-1,
  .sd-px-sm-1 {
    padding-left: 1rem !important;
  }
  .sd-p-sm-1_125 {
    padding: 1.125rem !important;
  }
  .sd-pt-sm-1_125,
  .sd-py-sm-1_125 {
    padding-top: 1.125rem !important;
  }
  .sd-pr-sm-1_125,
  .sd-px-sm-1_125 {
    padding-right: 1.125rem !important;
  }
  .sd-pb-sm-1_125,
  .sd-py-sm-1_125 {
    padding-bottom: 1.125rem !important;
  }
  .sd-pl-sm-1_125,
  .sd-px-sm-1_125 {
    padding-left: 1.125rem !important;
  }
  .sd-p-sm-1_25 {
    padding: 1.25rem !important;
  }
  .sd-pt-sm-1_25,
  .sd-py-sm-1_25 {
    padding-top: 1.25rem !important;
  }
  .sd-pr-sm-1_25,
  .sd-px-sm-1_25 {
    padding-right: 1.25rem !important;
  }
  .sd-pb-sm-1_25,
  .sd-py-sm-1_25 {
    padding-bottom: 1.25rem !important;
  }
  .sd-pl-sm-1_25,
  .sd-px-sm-1_25 {
    padding-left: 1.25rem !important;
  }
  .sd-p-sm-1_5 {
    padding: 1.5rem !important;
  }
  .sd-pt-sm-1_5,
  .sd-py-sm-1_5 {
    padding-top: 1.5rem !important;
  }
  .sd-pr-sm-1_5,
  .sd-px-sm-1_5 {
    padding-right: 1.5rem !important;
  }
  .sd-pb-sm-1_5,
  .sd-py-sm-1_5 {
    padding-bottom: 1.5rem !important;
  }
  .sd-pl-sm-1_5,
  .sd-px-sm-1_5 {
    padding-left: 1.5rem !important;
  }
  .sd-p-sm-1_75 {
    padding: 1.75rem !important;
  }
  .sd-pt-sm-1_75,
  .sd-py-sm-1_75 {
    padding-top: 1.75rem !important;
  }
  .sd-pr-sm-1_75,
  .sd-px-sm-1_75 {
    padding-right: 1.75rem !important;
  }
  .sd-pb-sm-1_75,
  .sd-py-sm-1_75 {
    padding-bottom: 1.75rem !important;
  }
  .sd-pl-sm-1_75,
  .sd-px-sm-1_75 {
    padding-left: 1.75rem !important;
  }
  .sd-p-sm-2 {
    padding: 2rem !important;
  }
  .sd-pt-sm-2,
  .sd-py-sm-2 {
    padding-top: 2rem !important;
  }
  .sd-pr-sm-2,
  .sd-px-sm-2 {
    padding-right: 2rem !important;
  }
  .sd-pb-sm-2,
  .sd-py-sm-2 {
    padding-bottom: 2rem !important;
  }
  .sd-pl-sm-2,
  .sd-px-sm-2 {
    padding-left: 2rem !important;
  }
  .sd-p-sm-2_5 {
    padding: 2.5rem !important;
  }
  .sd-pt-sm-2_5,
  .sd-py-sm-2_5 {
    padding-top: 2.5rem !important;
  }
  .sd-pr-sm-2_5,
  .sd-px-sm-2_5 {
    padding-right: 2.5rem !important;
  }
  .sd-pb-sm-2_5,
  .sd-py-sm-2_5 {
    padding-bottom: 2.5rem !important;
  }
  .sd-pl-sm-2_5,
  .sd-px-sm-2_5 {
    padding-left: 2.5rem !important;
  }
  .sd-p-sm-2_75 {
    padding: 2.75rem !important;
  }
  .sd-pt-sm-2_75,
  .sd-py-sm-2_75 {
    padding-top: 2.75rem !important;
  }
  .sd-pr-sm-2_75,
  .sd-px-sm-2_75 {
    padding-right: 2.75rem !important;
  }
  .sd-pb-sm-2_75,
  .sd-py-sm-2_75 {
    padding-bottom: 2.75rem !important;
  }
  .sd-pl-sm-2_75,
  .sd-px-sm-2_75 {
    padding-left: 2.75rem !important;
  }
  .sd-p-sm-3 {
    padding: 3rem !important;
  }
  .sd-pt-sm-3,
  .sd-py-sm-3 {
    padding-top: 3rem !important;
  }
  .sd-pr-sm-3,
  .sd-px-sm-3 {
    padding-right: 3rem !important;
  }
  .sd-pb-sm-3,
  .sd-py-sm-3 {
    padding-bottom: 3rem !important;
  }
  .sd-pl-sm-3,
  .sd-px-sm-3 {
    padding-left: 3rem !important;
  }
  .sd-p-sm-3_25 {
    padding: 3.25rem !important;
  }
  .sd-pt-sm-3_25,
  .sd-py-sm-3_25 {
    padding-top: 3.25rem !important;
  }
  .sd-pr-sm-3_25,
  .sd-px-sm-3_25 {
    padding-right: 3.25rem !important;
  }
  .sd-pb-sm-3_25,
  .sd-py-sm-3_25 {
    padding-bottom: 3.25rem !important;
  }
  .sd-pl-sm-3_25,
  .sd-px-sm-3_25 {
    padding-left: 3.25rem !important;
  }
  .sd-p-sm-3_5 {
    padding: 3.5rem !important;
  }
  .sd-pt-sm-3_5,
  .sd-py-sm-3_5 {
    padding-top: 3.5rem !important;
  }
  .sd-pr-sm-3_5,
  .sd-px-sm-3_5 {
    padding-right: 3.5rem !important;
  }
  .sd-pb-sm-3_5,
  .sd-py-sm-3_5 {
    padding-bottom: 3.5rem !important;
  }
  .sd-pl-sm-3_5,
  .sd-px-sm-3_5 {
    padding-left: 3.5rem !important;
  }
  .sd-p-sm-4 {
    padding: 4rem !important;
  }
  .sd-pt-sm-4,
  .sd-py-sm-4 {
    padding-top: 4rem !important;
  }
  .sd-pr-sm-4,
  .sd-px-sm-4 {
    padding-right: 4rem !important;
  }
  .sd-pb-sm-4,
  .sd-py-sm-4 {
    padding-bottom: 4rem !important;
  }
  .sd-pl-sm-4,
  .sd-px-sm-4 {
    padding-left: 4rem !important;
  }
  .sd-p-sm-5 {
    padding: 5rem !important;
  }
  .sd-pt-sm-5,
  .sd-py-sm-5 {
    padding-top: 5rem !important;
  }
  .sd-pr-sm-5,
  .sd-px-sm-5 {
    padding-right: 5rem !important;
  }
  .sd-pb-sm-5,
  .sd-py-sm-5 {
    padding-bottom: 5rem !important;
  }
  .sd-pl-sm-5,
  .sd-px-sm-5 {
    padding-left: 5rem !important;
  }
  .sd-p-sm-5_25 {
    padding: 5.25rem !important;
  }
  .sd-pt-sm-5_25,
  .sd-py-sm-5_25 {
    padding-top: 5.25rem !important;
  }
  .sd-pr-sm-5_25,
  .sd-px-sm-5_25 {
    padding-right: 5.25rem !important;
  }
  .sd-pb-sm-5_25,
  .sd-py-sm-5_25 {
    padding-bottom: 5.25rem !important;
  }
  .sd-pl-sm-5_25,
  .sd-px-sm-5_25 {
    padding-left: 5.25rem !important;
  }
  .sd-p-sm-5_5 {
    padding: 5.5rem !important;
  }
  .sd-pt-sm-5_5,
  .sd-py-sm-5_5 {
    padding-top: 5.5rem !important;
  }
  .sd-pr-sm-5_5,
  .sd-px-sm-5_5 {
    padding-right: 5.5rem !important;
  }
  .sd-pb-sm-5_5,
  .sd-py-sm-5_5 {
    padding-bottom: 5.5rem !important;
  }
  .sd-pl-sm-5_5,
  .sd-px-sm-5_5 {
    padding-left: 5.5rem !important;
  }
  .sd-p-sm-6 {
    padding: 6rem !important;
  }
  .sd-pt-sm-6,
  .sd-py-sm-6 {
    padding-top: 6rem !important;
  }
  .sd-pr-sm-6,
  .sd-px-sm-6 {
    padding-right: 6rem !important;
  }
  .sd-pb-sm-6,
  .sd-py-sm-6 {
    padding-bottom: 6rem !important;
  }
  .sd-pl-sm-6,
  .sd-px-sm-6 {
    padding-left: 6rem !important;
  }
  .sd-p-sm-8 {
    padding: 8rem !important;
  }
  .sd-pt-sm-8,
  .sd-py-sm-8 {
    padding-top: 8rem !important;
  }
  .sd-pr-sm-8,
  .sd-px-sm-8 {
    padding-right: 8rem !important;
  }
  .sd-pb-sm-8,
  .sd-py-sm-8 {
    padding-bottom: 8rem !important;
  }
  .sd-pl-sm-8,
  .sd-px-sm-8 {
    padding-left: 8rem !important;
  }
  .sd-p-sm-9 {
    padding: 9rem !important;
  }
  .sd-pt-sm-9,
  .sd-py-sm-9 {
    padding-top: 9rem !important;
  }
  .sd-pr-sm-9,
  .sd-px-sm-9 {
    padding-right: 9rem !important;
  }
  .sd-pb-sm-9,
  .sd-py-sm-9 {
    padding-bottom: 9rem !important;
  }
  .sd-pl-sm-9,
  .sd-px-sm-9 {
    padding-left: 9rem !important;
  }
  .sd-m-sm-auto {
    margin: auto !important;
  }
  .sd-mt-sm-auto,
  .sd-my-sm-auto {
    margin-top: auto !important;
  }
  .sd-mr-sm-auto,
  .sd-mx-sm-auto {
    margin-right: auto !important;
  }
  .sd-mb-sm-auto,
  .sd-my-sm-auto {
    margin-bottom: auto !important;
  }
  .sd-ml-sm-auto,
  .sd-mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .sd-m-md-0 {
    margin: 0 !important;
  }
  .sd-mt-md-0,
  .sd-my-md-0 {
    margin-top: 0 !important;
  }
  .sd-mr-md-0,
  .sd-mx-md-0 {
    margin-right: 0 !important;
  }
  .sd-mb-md-0,
  .sd-my-md-0 {
    margin-bottom: 0 !important;
  }
  .sd-ml-md-0,
  .sd-mx-md-0 {
    margin-left: 0 !important;
  }
  .sd-m-md-0_25 {
    margin: 0.25rem !important;
  }
  .sd-mt-md-0_25,
  .sd-my-md-0_25 {
    margin-top: 0.25rem !important;
  }
  .sd-mr-md-0_25,
  .sd-mx-md-0_25 {
    margin-right: 0.25rem !important;
  }
  .sd-mb-md-0_25,
  .sd-my-md-0_25 {
    margin-bottom: 0.25rem !important;
  }
  .sd-ml-md-0_25,
  .sd-mx-md-0_25 {
    margin-left: 0.25rem !important;
  }
  .sd-m-md-0_5 {
    margin: 0.5rem !important;
  }
  .sd-mt-md-0_5,
  .sd-my-md-0_5 {
    margin-top: 0.5rem !important;
  }
  .sd-mr-md-0_5,
  .sd-mx-md-0_5 {
    margin-right: 0.5rem !important;
  }
  .sd-mb-md-0_5,
  .sd-my-md-0_5 {
    margin-bottom: 0.5rem !important;
  }
  .sd-ml-md-0_5,
  .sd-mx-md-0_5 {
    margin-left: 0.5rem !important;
  }
  .sd-m-md-0_75 {
    margin: 0.75rem !important;
  }
  .sd-mt-md-0_75,
  .sd-my-md-0_75 {
    margin-top: 0.75rem !important;
  }
  .sd-mr-md-0_75,
  .sd-mx-md-0_75 {
    margin-right: 0.75rem !important;
  }
  .sd-mb-md-0_75,
  .sd-my-md-0_75 {
    margin-bottom: 0.75rem !important;
  }
  .sd-ml-md-0_75,
  .sd-mx-md-0_75 {
    margin-left: 0.75rem !important;
  }
  .sd-m-md-1 {
    margin: 1rem !important;
  }
  .sd-mt-md-1,
  .sd-my-md-1 {
    margin-top: 1rem !important;
  }
  .sd-mr-md-1,
  .sd-mx-md-1 {
    margin-right: 1rem !important;
  }
  .sd-mb-md-1,
  .sd-my-md-1 {
    margin-bottom: 1rem !important;
  }
  .sd-ml-md-1,
  .sd-mx-md-1 {
    margin-left: 1rem !important;
  }
  .sd-m-md-1_125 {
    margin: 1.125rem !important;
  }
  .sd-mt-md-1_125,
  .sd-my-md-1_125 {
    margin-top: 1.125rem !important;
  }
  .sd-mr-md-1_125,
  .sd-mx-md-1_125 {
    margin-right: 1.125rem !important;
  }
  .sd-mb-md-1_125,
  .sd-my-md-1_125 {
    margin-bottom: 1.125rem !important;
  }
  .sd-ml-md-1_125,
  .sd-mx-md-1_125 {
    margin-left: 1.125rem !important;
  }
  .sd-m-md-1_25 {
    margin: 1.25rem !important;
  }
  .sd-mt-md-1_25,
  .sd-my-md-1_25 {
    margin-top: 1.25rem !important;
  }
  .sd-mr-md-1_25,
  .sd-mx-md-1_25 {
    margin-right: 1.25rem !important;
  }
  .sd-mb-md-1_25,
  .sd-my-md-1_25 {
    margin-bottom: 1.25rem !important;
  }
  .sd-ml-md-1_25,
  .sd-mx-md-1_25 {
    margin-left: 1.25rem !important;
  }
  .sd-m-md-1_5 {
    margin: 1.5rem !important;
  }
  .sd-mt-md-1_5,
  .sd-my-md-1_5 {
    margin-top: 1.5rem !important;
  }
  .sd-mr-md-1_5,
  .sd-mx-md-1_5 {
    margin-right: 1.5rem !important;
  }
  .sd-mb-md-1_5,
  .sd-my-md-1_5 {
    margin-bottom: 1.5rem !important;
  }
  .sd-ml-md-1_5,
  .sd-mx-md-1_5 {
    margin-left: 1.5rem !important;
  }
  .sd-m-md-1_75 {
    margin: 1.75rem !important;
  }
  .sd-mt-md-1_75,
  .sd-my-md-1_75 {
    margin-top: 1.75rem !important;
  }
  .sd-mr-md-1_75,
  .sd-mx-md-1_75 {
    margin-right: 1.75rem !important;
  }
  .sd-mb-md-1_75,
  .sd-my-md-1_75 {
    margin-bottom: 1.75rem !important;
  }
  .sd-ml-md-1_75,
  .sd-mx-md-1_75 {
    margin-left: 1.75rem !important;
  }
  .sd-m-md-2 {
    margin: 2rem !important;
  }
  .sd-mt-md-2,
  .sd-my-md-2 {
    margin-top: 2rem !important;
  }
  .sd-mr-md-2,
  .sd-mx-md-2 {
    margin-right: 2rem !important;
  }
  .sd-mb-md-2,
  .sd-my-md-2 {
    margin-bottom: 2rem !important;
  }
  .sd-ml-md-2,
  .sd-mx-md-2 {
    margin-left: 2rem !important;
  }
  .sd-m-md-2_5 {
    margin: 2.5rem !important;
  }
  .sd-mt-md-2_5,
  .sd-my-md-2_5 {
    margin-top: 2.5rem !important;
  }
  .sd-mr-md-2_5,
  .sd-mx-md-2_5 {
    margin-right: 2.5rem !important;
  }
  .sd-mb-md-2_5,
  .sd-my-md-2_5 {
    margin-bottom: 2.5rem !important;
  }
  .sd-ml-md-2_5,
  .sd-mx-md-2_5 {
    margin-left: 2.5rem !important;
  }
  .sd-m-md-2_75 {
    margin: 2.75rem !important;
  }
  .sd-mt-md-2_75,
  .sd-my-md-2_75 {
    margin-top: 2.75rem !important;
  }
  .sd-mr-md-2_75,
  .sd-mx-md-2_75 {
    margin-right: 2.75rem !important;
  }
  .sd-mb-md-2_75,
  .sd-my-md-2_75 {
    margin-bottom: 2.75rem !important;
  }
  .sd-ml-md-2_75,
  .sd-mx-md-2_75 {
    margin-left: 2.75rem !important;
  }
  .sd-m-md-3 {
    margin: 3rem !important;
  }
  .sd-mt-md-3,
  .sd-my-md-3 {
    margin-top: 3rem !important;
  }
  .sd-mr-md-3,
  .sd-mx-md-3 {
    margin-right: 3rem !important;
  }
  .sd-mb-md-3,
  .sd-my-md-3 {
    margin-bottom: 3rem !important;
  }
  .sd-ml-md-3,
  .sd-mx-md-3 {
    margin-left: 3rem !important;
  }
  .sd-m-md-3_25 {
    margin: 3.25rem !important;
  }
  .sd-mt-md-3_25,
  .sd-my-md-3_25 {
    margin-top: 3.25rem !important;
  }
  .sd-mr-md-3_25,
  .sd-mx-md-3_25 {
    margin-right: 3.25rem !important;
  }
  .sd-mb-md-3_25,
  .sd-my-md-3_25 {
    margin-bottom: 3.25rem !important;
  }
  .sd-ml-md-3_25,
  .sd-mx-md-3_25 {
    margin-left: 3.25rem !important;
  }
  .sd-m-md-3_5 {
    margin: 3.5rem !important;
  }
  .sd-mt-md-3_5,
  .sd-my-md-3_5 {
    margin-top: 3.5rem !important;
  }
  .sd-mr-md-3_5,
  .sd-mx-md-3_5 {
    margin-right: 3.5rem !important;
  }
  .sd-mb-md-3_5,
  .sd-my-md-3_5 {
    margin-bottom: 3.5rem !important;
  }
  .sd-ml-md-3_5,
  .sd-mx-md-3_5 {
    margin-left: 3.5rem !important;
  }
  .sd-m-md-4 {
    margin: 4rem !important;
  }
  .sd-mt-md-4,
  .sd-my-md-4 {
    margin-top: 4rem !important;
  }
  .sd-mr-md-4,
  .sd-mx-md-4 {
    margin-right: 4rem !important;
  }
  .sd-mb-md-4,
  .sd-my-md-4 {
    margin-bottom: 4rem !important;
  }
  .sd-ml-md-4,
  .sd-mx-md-4 {
    margin-left: 4rem !important;
  }
  .sd-m-md-5 {
    margin: 5rem !important;
  }
  .sd-mt-md-5,
  .sd-my-md-5 {
    margin-top: 5rem !important;
  }
  .sd-mr-md-5,
  .sd-mx-md-5 {
    margin-right: 5rem !important;
  }
  .sd-mb-md-5,
  .sd-my-md-5 {
    margin-bottom: 5rem !important;
  }
  .sd-ml-md-5,
  .sd-mx-md-5 {
    margin-left: 5rem !important;
  }
  .sd-m-md-5_25 {
    margin: 5.25rem !important;
  }
  .sd-mt-md-5_25,
  .sd-my-md-5_25 {
    margin-top: 5.25rem !important;
  }
  .sd-mr-md-5_25,
  .sd-mx-md-5_25 {
    margin-right: 5.25rem !important;
  }
  .sd-mb-md-5_25,
  .sd-my-md-5_25 {
    margin-bottom: 5.25rem !important;
  }
  .sd-ml-md-5_25,
  .sd-mx-md-5_25 {
    margin-left: 5.25rem !important;
  }
  .sd-m-md-5_5 {
    margin: 5.5rem !important;
  }
  .sd-mt-md-5_5,
  .sd-my-md-5_5 {
    margin-top: 5.5rem !important;
  }
  .sd-mr-md-5_5,
  .sd-mx-md-5_5 {
    margin-right: 5.5rem !important;
  }
  .sd-mb-md-5_5,
  .sd-my-md-5_5 {
    margin-bottom: 5.5rem !important;
  }
  .sd-ml-md-5_5,
  .sd-mx-md-5_5 {
    margin-left: 5.5rem !important;
  }
  .sd-m-md-6 {
    margin: 6rem !important;
  }
  .sd-mt-md-6,
  .sd-my-md-6 {
    margin-top: 6rem !important;
  }
  .sd-mr-md-6,
  .sd-mx-md-6 {
    margin-right: 6rem !important;
  }
  .sd-mb-md-6,
  .sd-my-md-6 {
    margin-bottom: 6rem !important;
  }
  .sd-ml-md-6,
  .sd-mx-md-6 {
    margin-left: 6rem !important;
  }
  .sd-m-md-8 {
    margin: 8rem !important;
  }
  .sd-mt-md-8,
  .sd-my-md-8 {
    margin-top: 8rem !important;
  }
  .sd-mr-md-8,
  .sd-mx-md-8 {
    margin-right: 8rem !important;
  }
  .sd-mb-md-8,
  .sd-my-md-8 {
    margin-bottom: 8rem !important;
  }
  .sd-ml-md-8,
  .sd-mx-md-8 {
    margin-left: 8rem !important;
  }
  .sd-m-md-9 {
    margin: 9rem !important;
  }
  .sd-mt-md-9,
  .sd-my-md-9 {
    margin-top: 9rem !important;
  }
  .sd-mr-md-9,
  .sd-mx-md-9 {
    margin-right: 9rem !important;
  }
  .sd-mb-md-9,
  .sd-my-md-9 {
    margin-bottom: 9rem !important;
  }
  .sd-ml-md-9,
  .sd-mx-md-9 {
    margin-left: 9rem !important;
  }
  .sd-p-md-0 {
    padding: 0 !important;
  }
  .sd-pt-md-0,
  .sd-py-md-0 {
    padding-top: 0 !important;
  }
  .sd-pr-md-0,
  .sd-px-md-0 {
    padding-right: 0 !important;
  }
  .sd-pb-md-0,
  .sd-py-md-0 {
    padding-bottom: 0 !important;
  }
  .sd-pl-md-0,
  .sd-px-md-0 {
    padding-left: 0 !important;
  }
  .sd-p-md-0_25 {
    padding: 0.25rem !important;
  }
  .sd-pt-md-0_25,
  .sd-py-md-0_25 {
    padding-top: 0.25rem !important;
  }
  .sd-pr-md-0_25,
  .sd-px-md-0_25 {
    padding-right: 0.25rem !important;
  }
  .sd-pb-md-0_25,
  .sd-py-md-0_25 {
    padding-bottom: 0.25rem !important;
  }
  .sd-pl-md-0_25,
  .sd-px-md-0_25 {
    padding-left: 0.25rem !important;
  }
  .sd-p-md-0_5 {
    padding: 0.5rem !important;
  }
  .sd-pt-md-0_5,
  .sd-py-md-0_5 {
    padding-top: 0.5rem !important;
  }
  .sd-pr-md-0_5,
  .sd-px-md-0_5 {
    padding-right: 0.5rem !important;
  }
  .sd-pb-md-0_5,
  .sd-py-md-0_5 {
    padding-bottom: 0.5rem !important;
  }
  .sd-pl-md-0_5,
  .sd-px-md-0_5 {
    padding-left: 0.5rem !important;
  }
  .sd-p-md-0_75 {
    padding: 0.75rem !important;
  }
  .sd-pt-md-0_75,
  .sd-py-md-0_75 {
    padding-top: 0.75rem !important;
  }
  .sd-pr-md-0_75,
  .sd-px-md-0_75 {
    padding-right: 0.75rem !important;
  }
  .sd-pb-md-0_75,
  .sd-py-md-0_75 {
    padding-bottom: 0.75rem !important;
  }
  .sd-pl-md-0_75,
  .sd-px-md-0_75 {
    padding-left: 0.75rem !important;
  }
  .sd-p-md-1 {
    padding: 1rem !important;
  }
  .sd-pt-md-1,
  .sd-py-md-1 {
    padding-top: 1rem !important;
  }
  .sd-pr-md-1,
  .sd-px-md-1 {
    padding-right: 1rem !important;
  }
  .sd-pb-md-1,
  .sd-py-md-1 {
    padding-bottom: 1rem !important;
  }
  .sd-pl-md-1,
  .sd-px-md-1 {
    padding-left: 1rem !important;
  }
  .sd-p-md-1_125 {
    padding: 1.125rem !important;
  }
  .sd-pt-md-1_125,
  .sd-py-md-1_125 {
    padding-top: 1.125rem !important;
  }
  .sd-pr-md-1_125,
  .sd-px-md-1_125 {
    padding-right: 1.125rem !important;
  }
  .sd-pb-md-1_125,
  .sd-py-md-1_125 {
    padding-bottom: 1.125rem !important;
  }
  .sd-pl-md-1_125,
  .sd-px-md-1_125 {
    padding-left: 1.125rem !important;
  }
  .sd-p-md-1_25 {
    padding: 1.25rem !important;
  }
  .sd-pt-md-1_25,
  .sd-py-md-1_25 {
    padding-top: 1.25rem !important;
  }
  .sd-pr-md-1_25,
  .sd-px-md-1_25 {
    padding-right: 1.25rem !important;
  }
  .sd-pb-md-1_25,
  .sd-py-md-1_25 {
    padding-bottom: 1.25rem !important;
  }
  .sd-pl-md-1_25,
  .sd-px-md-1_25 {
    padding-left: 1.25rem !important;
  }
  .sd-p-md-1_5 {
    padding: 1.5rem !important;
  }
  .sd-pt-md-1_5,
  .sd-py-md-1_5 {
    padding-top: 1.5rem !important;
  }
  .sd-pr-md-1_5,
  .sd-px-md-1_5 {
    padding-right: 1.5rem !important;
  }
  .sd-pb-md-1_5,
  .sd-py-md-1_5 {
    padding-bottom: 1.5rem !important;
  }
  .sd-pl-md-1_5,
  .sd-px-md-1_5 {
    padding-left: 1.5rem !important;
  }
  .sd-p-md-1_75 {
    padding: 1.75rem !important;
  }
  .sd-pt-md-1_75,
  .sd-py-md-1_75 {
    padding-top: 1.75rem !important;
  }
  .sd-pr-md-1_75,
  .sd-px-md-1_75 {
    padding-right: 1.75rem !important;
  }
  .sd-pb-md-1_75,
  .sd-py-md-1_75 {
    padding-bottom: 1.75rem !important;
  }
  .sd-pl-md-1_75,
  .sd-px-md-1_75 {
    padding-left: 1.75rem !important;
  }
  .sd-p-md-2 {
    padding: 2rem !important;
  }
  .sd-pt-md-2,
  .sd-py-md-2 {
    padding-top: 2rem !important;
  }
  .sd-pr-md-2,
  .sd-px-md-2 {
    padding-right: 2rem !important;
  }
  .sd-pb-md-2,
  .sd-py-md-2 {
    padding-bottom: 2rem !important;
  }
  .sd-pl-md-2,
  .sd-px-md-2 {
    padding-left: 2rem !important;
  }
  .sd-p-md-2_5 {
    padding: 2.5rem !important;
  }
  .sd-pt-md-2_5,
  .sd-py-md-2_5 {
    padding-top: 2.5rem !important;
  }
  .sd-pr-md-2_5,
  .sd-px-md-2_5 {
    padding-right: 2.5rem !important;
  }
  .sd-pb-md-2_5,
  .sd-py-md-2_5 {
    padding-bottom: 2.5rem !important;
  }
  .sd-pl-md-2_5,
  .sd-px-md-2_5 {
    padding-left: 2.5rem !important;
  }
  .sd-p-md-2_75 {
    padding: 2.75rem !important;
  }
  .sd-pt-md-2_75,
  .sd-py-md-2_75 {
    padding-top: 2.75rem !important;
  }
  .sd-pr-md-2_75,
  .sd-px-md-2_75 {
    padding-right: 2.75rem !important;
  }
  .sd-pb-md-2_75,
  .sd-py-md-2_75 {
    padding-bottom: 2.75rem !important;
  }
  .sd-pl-md-2_75,
  .sd-px-md-2_75 {
    padding-left: 2.75rem !important;
  }
  .sd-p-md-3 {
    padding: 3rem !important;
  }
  .sd-pt-md-3,
  .sd-py-md-3 {
    padding-top: 3rem !important;
  }
  .sd-pr-md-3,
  .sd-px-md-3 {
    padding-right: 3rem !important;
  }
  .sd-pb-md-3,
  .sd-py-md-3 {
    padding-bottom: 3rem !important;
  }
  .sd-pl-md-3,
  .sd-px-md-3 {
    padding-left: 3rem !important;
  }
  .sd-p-md-3_25 {
    padding: 3.25rem !important;
  }
  .sd-pt-md-3_25,
  .sd-py-md-3_25 {
    padding-top: 3.25rem !important;
  }
  .sd-pr-md-3_25,
  .sd-px-md-3_25 {
    padding-right: 3.25rem !important;
  }
  .sd-pb-md-3_25,
  .sd-py-md-3_25 {
    padding-bottom: 3.25rem !important;
  }
  .sd-pl-md-3_25,
  .sd-px-md-3_25 {
    padding-left: 3.25rem !important;
  }
  .sd-p-md-3_5 {
    padding: 3.5rem !important;
  }
  .sd-pt-md-3_5,
  .sd-py-md-3_5 {
    padding-top: 3.5rem !important;
  }
  .sd-pr-md-3_5,
  .sd-px-md-3_5 {
    padding-right: 3.5rem !important;
  }
  .sd-pb-md-3_5,
  .sd-py-md-3_5 {
    padding-bottom: 3.5rem !important;
  }
  .sd-pl-md-3_5,
  .sd-px-md-3_5 {
    padding-left: 3.5rem !important;
  }
  .sd-p-md-4 {
    padding: 4rem !important;
  }
  .sd-pt-md-4,
  .sd-py-md-4 {
    padding-top: 4rem !important;
  }
  .sd-pr-md-4,
  .sd-px-md-4 {
    padding-right: 4rem !important;
  }
  .sd-pb-md-4,
  .sd-py-md-4 {
    padding-bottom: 4rem !important;
  }
  .sd-pl-md-4,
  .sd-px-md-4 {
    padding-left: 4rem !important;
  }
  .sd-p-md-5 {
    padding: 5rem !important;
  }
  .sd-pt-md-5,
  .sd-py-md-5 {
    padding-top: 5rem !important;
  }
  .sd-pr-md-5,
  .sd-px-md-5 {
    padding-right: 5rem !important;
  }
  .sd-pb-md-5,
  .sd-py-md-5 {
    padding-bottom: 5rem !important;
  }
  .sd-pl-md-5,
  .sd-px-md-5 {
    padding-left: 5rem !important;
  }
  .sd-p-md-5_25 {
    padding: 5.25rem !important;
  }
  .sd-pt-md-5_25,
  .sd-py-md-5_25 {
    padding-top: 5.25rem !important;
  }
  .sd-pr-md-5_25,
  .sd-px-md-5_25 {
    padding-right: 5.25rem !important;
  }
  .sd-pb-md-5_25,
  .sd-py-md-5_25 {
    padding-bottom: 5.25rem !important;
  }
  .sd-pl-md-5_25,
  .sd-px-md-5_25 {
    padding-left: 5.25rem !important;
  }
  .sd-p-md-5_5 {
    padding: 5.5rem !important;
  }
  .sd-pt-md-5_5,
  .sd-py-md-5_5 {
    padding-top: 5.5rem !important;
  }
  .sd-pr-md-5_5,
  .sd-px-md-5_5 {
    padding-right: 5.5rem !important;
  }
  .sd-pb-md-5_5,
  .sd-py-md-5_5 {
    padding-bottom: 5.5rem !important;
  }
  .sd-pl-md-5_5,
  .sd-px-md-5_5 {
    padding-left: 5.5rem !important;
  }
  .sd-p-md-6 {
    padding: 6rem !important;
  }
  .sd-pt-md-6,
  .sd-py-md-6 {
    padding-top: 6rem !important;
  }
  .sd-pr-md-6,
  .sd-px-md-6 {
    padding-right: 6rem !important;
  }
  .sd-pb-md-6,
  .sd-py-md-6 {
    padding-bottom: 6rem !important;
  }
  .sd-pl-md-6,
  .sd-px-md-6 {
    padding-left: 6rem !important;
  }
  .sd-p-md-8 {
    padding: 8rem !important;
  }
  .sd-pt-md-8,
  .sd-py-md-8 {
    padding-top: 8rem !important;
  }
  .sd-pr-md-8,
  .sd-px-md-8 {
    padding-right: 8rem !important;
  }
  .sd-pb-md-8,
  .sd-py-md-8 {
    padding-bottom: 8rem !important;
  }
  .sd-pl-md-8,
  .sd-px-md-8 {
    padding-left: 8rem !important;
  }
  .sd-p-md-9 {
    padding: 9rem !important;
  }
  .sd-pt-md-9,
  .sd-py-md-9 {
    padding-top: 9rem !important;
  }
  .sd-pr-md-9,
  .sd-px-md-9 {
    padding-right: 9rem !important;
  }
  .sd-pb-md-9,
  .sd-py-md-9 {
    padding-bottom: 9rem !important;
  }
  .sd-pl-md-9,
  .sd-px-md-9 {
    padding-left: 9rem !important;
  }
  .sd-m-md-auto {
    margin: auto !important;
  }
  .sd-mt-md-auto,
  .sd-my-md-auto {
    margin-top: auto !important;
  }
  .sd-mr-md-auto,
  .sd-mx-md-auto {
    margin-right: auto !important;
  }
  .sd-mb-md-auto,
  .sd-my-md-auto {
    margin-bottom: auto !important;
  }
  .sd-ml-md-auto,
  .sd-mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .sd-m-lg-0 {
    margin: 0 !important;
  }
  .sd-mt-lg-0,
  .sd-my-lg-0 {
    margin-top: 0 !important;
  }
  .sd-mr-lg-0,
  .sd-mx-lg-0 {
    margin-right: 0 !important;
  }
  .sd-mb-lg-0,
  .sd-my-lg-0 {
    margin-bottom: 0 !important;
  }
  .sd-ml-lg-0,
  .sd-mx-lg-0 {
    margin-left: 0 !important;
  }
  .sd-m-lg-0_25 {
    margin: 0.25rem !important;
  }
  .sd-mt-lg-0_25,
  .sd-my-lg-0_25 {
    margin-top: 0.25rem !important;
  }
  .sd-mr-lg-0_25,
  .sd-mx-lg-0_25 {
    margin-right: 0.25rem !important;
  }
  .sd-mb-lg-0_25,
  .sd-my-lg-0_25 {
    margin-bottom: 0.25rem !important;
  }
  .sd-ml-lg-0_25,
  .sd-mx-lg-0_25 {
    margin-left: 0.25rem !important;
  }
  .sd-m-lg-0_5 {
    margin: 0.5rem !important;
  }
  .sd-mt-lg-0_5,
  .sd-my-lg-0_5 {
    margin-top: 0.5rem !important;
  }
  .sd-mr-lg-0_5,
  .sd-mx-lg-0_5 {
    margin-right: 0.5rem !important;
  }
  .sd-mb-lg-0_5,
  .sd-my-lg-0_5 {
    margin-bottom: 0.5rem !important;
  }
  .sd-ml-lg-0_5,
  .sd-mx-lg-0_5 {
    margin-left: 0.5rem !important;
  }
  .sd-m-lg-0_75 {
    margin: 0.75rem !important;
  }
  .sd-mt-lg-0_75,
  .sd-my-lg-0_75 {
    margin-top: 0.75rem !important;
  }
  .sd-mr-lg-0_75,
  .sd-mx-lg-0_75 {
    margin-right: 0.75rem !important;
  }
  .sd-mb-lg-0_75,
  .sd-my-lg-0_75 {
    margin-bottom: 0.75rem !important;
  }
  .sd-ml-lg-0_75,
  .sd-mx-lg-0_75 {
    margin-left: 0.75rem !important;
  }
  .sd-m-lg-1 {
    margin: 1rem !important;
  }
  .sd-mt-lg-1,
  .sd-my-lg-1 {
    margin-top: 1rem !important;
  }
  .sd-mr-lg-1,
  .sd-mx-lg-1 {
    margin-right: 1rem !important;
  }
  .sd-mb-lg-1,
  .sd-my-lg-1 {
    margin-bottom: 1rem !important;
  }
  .sd-ml-lg-1,
  .sd-mx-lg-1 {
    margin-left: 1rem !important;
  }
  .sd-m-lg-1_125 {
    margin: 1.125rem !important;
  }
  .sd-mt-lg-1_125,
  .sd-my-lg-1_125 {
    margin-top: 1.125rem !important;
  }
  .sd-mr-lg-1_125,
  .sd-mx-lg-1_125 {
    margin-right: 1.125rem !important;
  }
  .sd-mb-lg-1_125,
  .sd-my-lg-1_125 {
    margin-bottom: 1.125rem !important;
  }
  .sd-ml-lg-1_125,
  .sd-mx-lg-1_125 {
    margin-left: 1.125rem !important;
  }
  .sd-m-lg-1_25 {
    margin: 1.25rem !important;
  }
  .sd-mt-lg-1_25,
  .sd-my-lg-1_25 {
    margin-top: 1.25rem !important;
  }
  .sd-mr-lg-1_25,
  .sd-mx-lg-1_25 {
    margin-right: 1.25rem !important;
  }
  .sd-mb-lg-1_25,
  .sd-my-lg-1_25 {
    margin-bottom: 1.25rem !important;
  }
  .sd-ml-lg-1_25,
  .sd-mx-lg-1_25 {
    margin-left: 1.25rem !important;
  }
  .sd-m-lg-1_5 {
    margin: 1.5rem !important;
  }
  .sd-mt-lg-1_5,
  .sd-my-lg-1_5 {
    margin-top: 1.5rem !important;
  }
  .sd-mr-lg-1_5,
  .sd-mx-lg-1_5 {
    margin-right: 1.5rem !important;
  }
  .sd-mb-lg-1_5,
  .sd-my-lg-1_5 {
    margin-bottom: 1.5rem !important;
  }
  .sd-ml-lg-1_5,
  .sd-mx-lg-1_5 {
    margin-left: 1.5rem !important;
  }
  .sd-m-lg-1_75 {
    margin: 1.75rem !important;
  }
  .sd-mt-lg-1_75,
  .sd-my-lg-1_75 {
    margin-top: 1.75rem !important;
  }
  .sd-mr-lg-1_75,
  .sd-mx-lg-1_75 {
    margin-right: 1.75rem !important;
  }
  .sd-mb-lg-1_75,
  .sd-my-lg-1_75 {
    margin-bottom: 1.75rem !important;
  }
  .sd-ml-lg-1_75,
  .sd-mx-lg-1_75 {
    margin-left: 1.75rem !important;
  }
  .sd-m-lg-2 {
    margin: 2rem !important;
  }
  .sd-mt-lg-2,
  .sd-my-lg-2 {
    margin-top: 2rem !important;
  }
  .sd-mr-lg-2,
  .sd-mx-lg-2 {
    margin-right: 2rem !important;
  }
  .sd-mb-lg-2,
  .sd-my-lg-2 {
    margin-bottom: 2rem !important;
  }
  .sd-ml-lg-2,
  .sd-mx-lg-2 {
    margin-left: 2rem !important;
  }
  .sd-m-lg-2_5 {
    margin: 2.5rem !important;
  }
  .sd-mt-lg-2_5,
  .sd-my-lg-2_5 {
    margin-top: 2.5rem !important;
  }
  .sd-mr-lg-2_5,
  .sd-mx-lg-2_5 {
    margin-right: 2.5rem !important;
  }
  .sd-mb-lg-2_5,
  .sd-my-lg-2_5 {
    margin-bottom: 2.5rem !important;
  }
  .sd-ml-lg-2_5,
  .sd-mx-lg-2_5 {
    margin-left: 2.5rem !important;
  }
  .sd-m-lg-2_75 {
    margin: 2.75rem !important;
  }
  .sd-mt-lg-2_75,
  .sd-my-lg-2_75 {
    margin-top: 2.75rem !important;
  }
  .sd-mr-lg-2_75,
  .sd-mx-lg-2_75 {
    margin-right: 2.75rem !important;
  }
  .sd-mb-lg-2_75,
  .sd-my-lg-2_75 {
    margin-bottom: 2.75rem !important;
  }
  .sd-ml-lg-2_75,
  .sd-mx-lg-2_75 {
    margin-left: 2.75rem !important;
  }
  .sd-m-lg-3 {
    margin: 3rem !important;
  }
  .sd-mt-lg-3,
  .sd-my-lg-3 {
    margin-top: 3rem !important;
  }
  .sd-mr-lg-3,
  .sd-mx-lg-3 {
    margin-right: 3rem !important;
  }
  .sd-mb-lg-3,
  .sd-my-lg-3 {
    margin-bottom: 3rem !important;
  }
  .sd-ml-lg-3,
  .sd-mx-lg-3 {
    margin-left: 3rem !important;
  }
  .sd-m-lg-3_25 {
    margin: 3.25rem !important;
  }
  .sd-mt-lg-3_25,
  .sd-my-lg-3_25 {
    margin-top: 3.25rem !important;
  }
  .sd-mr-lg-3_25,
  .sd-mx-lg-3_25 {
    margin-right: 3.25rem !important;
  }
  .sd-mb-lg-3_25,
  .sd-my-lg-3_25 {
    margin-bottom: 3.25rem !important;
  }
  .sd-ml-lg-3_25,
  .sd-mx-lg-3_25 {
    margin-left: 3.25rem !important;
  }
  .sd-m-lg-3_5 {
    margin: 3.5rem !important;
  }
  .sd-mt-lg-3_5,
  .sd-my-lg-3_5 {
    margin-top: 3.5rem !important;
  }
  .sd-mr-lg-3_5,
  .sd-mx-lg-3_5 {
    margin-right: 3.5rem !important;
  }
  .sd-mb-lg-3_5,
  .sd-my-lg-3_5 {
    margin-bottom: 3.5rem !important;
  }
  .sd-ml-lg-3_5,
  .sd-mx-lg-3_5 {
    margin-left: 3.5rem !important;
  }
  .sd-m-lg-4 {
    margin: 4rem !important;
  }
  .sd-mt-lg-4,
  .sd-my-lg-4 {
    margin-top: 4rem !important;
  }
  .sd-mr-lg-4,
  .sd-mx-lg-4 {
    margin-right: 4rem !important;
  }
  .sd-mb-lg-4,
  .sd-my-lg-4 {
    margin-bottom: 4rem !important;
  }
  .sd-ml-lg-4,
  .sd-mx-lg-4 {
    margin-left: 4rem !important;
  }
  .sd-m-lg-5 {
    margin: 5rem !important;
  }
  .sd-mt-lg-5,
  .sd-my-lg-5 {
    margin-top: 5rem !important;
  }
  .sd-mr-lg-5,
  .sd-mx-lg-5 {
    margin-right: 5rem !important;
  }
  .sd-mb-lg-5,
  .sd-my-lg-5 {
    margin-bottom: 5rem !important;
  }
  .sd-ml-lg-5,
  .sd-mx-lg-5 {
    margin-left: 5rem !important;
  }
  .sd-m-lg-5_25 {
    margin: 5.25rem !important;
  }
  .sd-mt-lg-5_25,
  .sd-my-lg-5_25 {
    margin-top: 5.25rem !important;
  }
  .sd-mr-lg-5_25,
  .sd-mx-lg-5_25 {
    margin-right: 5.25rem !important;
  }
  .sd-mb-lg-5_25,
  .sd-my-lg-5_25 {
    margin-bottom: 5.25rem !important;
  }
  .sd-ml-lg-5_25,
  .sd-mx-lg-5_25 {
    margin-left: 5.25rem !important;
  }
  .sd-m-lg-5_5 {
    margin: 5.5rem !important;
  }
  .sd-mt-lg-5_5,
  .sd-my-lg-5_5 {
    margin-top: 5.5rem !important;
  }
  .sd-mr-lg-5_5,
  .sd-mx-lg-5_5 {
    margin-right: 5.5rem !important;
  }
  .sd-mb-lg-5_5,
  .sd-my-lg-5_5 {
    margin-bottom: 5.5rem !important;
  }
  .sd-ml-lg-5_5,
  .sd-mx-lg-5_5 {
    margin-left: 5.5rem !important;
  }
  .sd-m-lg-6 {
    margin: 6rem !important;
  }
  .sd-mt-lg-6,
  .sd-my-lg-6 {
    margin-top: 6rem !important;
  }
  .sd-mr-lg-6,
  .sd-mx-lg-6 {
    margin-right: 6rem !important;
  }
  .sd-mb-lg-6,
  .sd-my-lg-6 {
    margin-bottom: 6rem !important;
  }
  .sd-ml-lg-6,
  .sd-mx-lg-6 {
    margin-left: 6rem !important;
  }
  .sd-m-lg-8 {
    margin: 8rem !important;
  }
  .sd-mt-lg-8,
  .sd-my-lg-8 {
    margin-top: 8rem !important;
  }
  .sd-mr-lg-8,
  .sd-mx-lg-8 {
    margin-right: 8rem !important;
  }
  .sd-mb-lg-8,
  .sd-my-lg-8 {
    margin-bottom: 8rem !important;
  }
  .sd-ml-lg-8,
  .sd-mx-lg-8 {
    margin-left: 8rem !important;
  }
  .sd-m-lg-9 {
    margin: 9rem !important;
  }
  .sd-mt-lg-9,
  .sd-my-lg-9 {
    margin-top: 9rem !important;
  }
  .sd-mr-lg-9,
  .sd-mx-lg-9 {
    margin-right: 9rem !important;
  }
  .sd-mb-lg-9,
  .sd-my-lg-9 {
    margin-bottom: 9rem !important;
  }
  .sd-ml-lg-9,
  .sd-mx-lg-9 {
    margin-left: 9rem !important;
  }
  .sd-p-lg-0 {
    padding: 0 !important;
  }
  .sd-pt-lg-0,
  .sd-py-lg-0 {
    padding-top: 0 !important;
  }
  .sd-pr-lg-0,
  .sd-px-lg-0 {
    padding-right: 0 !important;
  }
  .sd-pb-lg-0,
  .sd-py-lg-0 {
    padding-bottom: 0 !important;
  }
  .sd-pl-lg-0,
  .sd-px-lg-0 {
    padding-left: 0 !important;
  }
  .sd-p-lg-0_25 {
    padding: 0.25rem !important;
  }
  .sd-pt-lg-0_25,
  .sd-py-lg-0_25 {
    padding-top: 0.25rem !important;
  }
  .sd-pr-lg-0_25,
  .sd-px-lg-0_25 {
    padding-right: 0.25rem !important;
  }
  .sd-pb-lg-0_25,
  .sd-py-lg-0_25 {
    padding-bottom: 0.25rem !important;
  }
  .sd-pl-lg-0_25,
  .sd-px-lg-0_25 {
    padding-left: 0.25rem !important;
  }
  .sd-p-lg-0_5 {
    padding: 0.5rem !important;
  }
  .sd-pt-lg-0_5,
  .sd-py-lg-0_5 {
    padding-top: 0.5rem !important;
  }
  .sd-pr-lg-0_5,
  .sd-px-lg-0_5 {
    padding-right: 0.5rem !important;
  }
  .sd-pb-lg-0_5,
  .sd-py-lg-0_5 {
    padding-bottom: 0.5rem !important;
  }
  .sd-pl-lg-0_5,
  .sd-px-lg-0_5 {
    padding-left: 0.5rem !important;
  }
  .sd-p-lg-0_75 {
    padding: 0.75rem !important;
  }
  .sd-pt-lg-0_75,
  .sd-py-lg-0_75 {
    padding-top: 0.75rem !important;
  }
  .sd-pr-lg-0_75,
  .sd-px-lg-0_75 {
    padding-right: 0.75rem !important;
  }
  .sd-pb-lg-0_75,
  .sd-py-lg-0_75 {
    padding-bottom: 0.75rem !important;
  }
  .sd-pl-lg-0_75,
  .sd-px-lg-0_75 {
    padding-left: 0.75rem !important;
  }
  .sd-p-lg-1 {
    padding: 1rem !important;
  }
  .sd-pt-lg-1,
  .sd-py-lg-1 {
    padding-top: 1rem !important;
  }
  .sd-pr-lg-1,
  .sd-px-lg-1 {
    padding-right: 1rem !important;
  }
  .sd-pb-lg-1,
  .sd-py-lg-1 {
    padding-bottom: 1rem !important;
  }
  .sd-pl-lg-1,
  .sd-px-lg-1 {
    padding-left: 1rem !important;
  }
  .sd-p-lg-1_125 {
    padding: 1.125rem !important;
  }
  .sd-pt-lg-1_125,
  .sd-py-lg-1_125 {
    padding-top: 1.125rem !important;
  }
  .sd-pr-lg-1_125,
  .sd-px-lg-1_125 {
    padding-right: 1.125rem !important;
  }
  .sd-pb-lg-1_125,
  .sd-py-lg-1_125 {
    padding-bottom: 1.125rem !important;
  }
  .sd-pl-lg-1_125,
  .sd-px-lg-1_125 {
    padding-left: 1.125rem !important;
  }
  .sd-p-lg-1_25 {
    padding: 1.25rem !important;
  }
  .sd-pt-lg-1_25,
  .sd-py-lg-1_25 {
    padding-top: 1.25rem !important;
  }
  .sd-pr-lg-1_25,
  .sd-px-lg-1_25 {
    padding-right: 1.25rem !important;
  }
  .sd-pb-lg-1_25,
  .sd-py-lg-1_25 {
    padding-bottom: 1.25rem !important;
  }
  .sd-pl-lg-1_25,
  .sd-px-lg-1_25 {
    padding-left: 1.25rem !important;
  }
  .sd-p-lg-1_5 {
    padding: 1.5rem !important;
  }
  .sd-pt-lg-1_5,
  .sd-py-lg-1_5 {
    padding-top: 1.5rem !important;
  }
  .sd-pr-lg-1_5,
  .sd-px-lg-1_5 {
    padding-right: 1.5rem !important;
  }
  .sd-pb-lg-1_5,
  .sd-py-lg-1_5 {
    padding-bottom: 1.5rem !important;
  }
  .sd-pl-lg-1_5,
  .sd-px-lg-1_5 {
    padding-left: 1.5rem !important;
  }
  .sd-p-lg-1_75 {
    padding: 1.75rem !important;
  }
  .sd-pt-lg-1_75,
  .sd-py-lg-1_75 {
    padding-top: 1.75rem !important;
  }
  .sd-pr-lg-1_75,
  .sd-px-lg-1_75 {
    padding-right: 1.75rem !important;
  }
  .sd-pb-lg-1_75,
  .sd-py-lg-1_75 {
    padding-bottom: 1.75rem !important;
  }
  .sd-pl-lg-1_75,
  .sd-px-lg-1_75 {
    padding-left: 1.75rem !important;
  }
  .sd-p-lg-2 {
    padding: 2rem !important;
  }
  .sd-pt-lg-2,
  .sd-py-lg-2 {
    padding-top: 2rem !important;
  }
  .sd-pr-lg-2,
  .sd-px-lg-2 {
    padding-right: 2rem !important;
  }
  .sd-pb-lg-2,
  .sd-py-lg-2 {
    padding-bottom: 2rem !important;
  }
  .sd-pl-lg-2,
  .sd-px-lg-2 {
    padding-left: 2rem !important;
  }
  .sd-p-lg-2_5 {
    padding: 2.5rem !important;
  }
  .sd-pt-lg-2_5,
  .sd-py-lg-2_5 {
    padding-top: 2.5rem !important;
  }
  .sd-pr-lg-2_5,
  .sd-px-lg-2_5 {
    padding-right: 2.5rem !important;
  }
  .sd-pb-lg-2_5,
  .sd-py-lg-2_5 {
    padding-bottom: 2.5rem !important;
  }
  .sd-pl-lg-2_5,
  .sd-px-lg-2_5 {
    padding-left: 2.5rem !important;
  }
  .sd-p-lg-2_75 {
    padding: 2.75rem !important;
  }
  .sd-pt-lg-2_75,
  .sd-py-lg-2_75 {
    padding-top: 2.75rem !important;
  }
  .sd-pr-lg-2_75,
  .sd-px-lg-2_75 {
    padding-right: 2.75rem !important;
  }
  .sd-pb-lg-2_75,
  .sd-py-lg-2_75 {
    padding-bottom: 2.75rem !important;
  }
  .sd-pl-lg-2_75,
  .sd-px-lg-2_75 {
    padding-left: 2.75rem !important;
  }
  .sd-p-lg-3 {
    padding: 3rem !important;
  }
  .sd-pt-lg-3,
  .sd-py-lg-3 {
    padding-top: 3rem !important;
  }
  .sd-pr-lg-3,
  .sd-px-lg-3 {
    padding-right: 3rem !important;
  }
  .sd-pb-lg-3,
  .sd-py-lg-3 {
    padding-bottom: 3rem !important;
  }
  .sd-pl-lg-3,
  .sd-px-lg-3 {
    padding-left: 3rem !important;
  }
  .sd-p-lg-3_25 {
    padding: 3.25rem !important;
  }
  .sd-pt-lg-3_25,
  .sd-py-lg-3_25 {
    padding-top: 3.25rem !important;
  }
  .sd-pr-lg-3_25,
  .sd-px-lg-3_25 {
    padding-right: 3.25rem !important;
  }
  .sd-pb-lg-3_25,
  .sd-py-lg-3_25 {
    padding-bottom: 3.25rem !important;
  }
  .sd-pl-lg-3_25,
  .sd-px-lg-3_25 {
    padding-left: 3.25rem !important;
  }
  .sd-p-lg-3_5 {
    padding: 3.5rem !important;
  }
  .sd-pt-lg-3_5,
  .sd-py-lg-3_5 {
    padding-top: 3.5rem !important;
  }
  .sd-pr-lg-3_5,
  .sd-px-lg-3_5 {
    padding-right: 3.5rem !important;
  }
  .sd-pb-lg-3_5,
  .sd-py-lg-3_5 {
    padding-bottom: 3.5rem !important;
  }
  .sd-pl-lg-3_5,
  .sd-px-lg-3_5 {
    padding-left: 3.5rem !important;
  }
  .sd-p-lg-4 {
    padding: 4rem !important;
  }
  .sd-pt-lg-4,
  .sd-py-lg-4 {
    padding-top: 4rem !important;
  }
  .sd-pr-lg-4,
  .sd-px-lg-4 {
    padding-right: 4rem !important;
  }
  .sd-pb-lg-4,
  .sd-py-lg-4 {
    padding-bottom: 4rem !important;
  }
  .sd-pl-lg-4,
  .sd-px-lg-4 {
    padding-left: 4rem !important;
  }
  .sd-p-lg-5 {
    padding: 5rem !important;
  }
  .sd-pt-lg-5,
  .sd-py-lg-5 {
    padding-top: 5rem !important;
  }
  .sd-pr-lg-5,
  .sd-px-lg-5 {
    padding-right: 5rem !important;
  }
  .sd-pb-lg-5,
  .sd-py-lg-5 {
    padding-bottom: 5rem !important;
  }
  .sd-pl-lg-5,
  .sd-px-lg-5 {
    padding-left: 5rem !important;
  }
  .sd-p-lg-5_25 {
    padding: 5.25rem !important;
  }
  .sd-pt-lg-5_25,
  .sd-py-lg-5_25 {
    padding-top: 5.25rem !important;
  }
  .sd-pr-lg-5_25,
  .sd-px-lg-5_25 {
    padding-right: 5.25rem !important;
  }
  .sd-pb-lg-5_25,
  .sd-py-lg-5_25 {
    padding-bottom: 5.25rem !important;
  }
  .sd-pl-lg-5_25,
  .sd-px-lg-5_25 {
    padding-left: 5.25rem !important;
  }
  .sd-p-lg-5_5 {
    padding: 5.5rem !important;
  }
  .sd-pt-lg-5_5,
  .sd-py-lg-5_5 {
    padding-top: 5.5rem !important;
  }
  .sd-pr-lg-5_5,
  .sd-px-lg-5_5 {
    padding-right: 5.5rem !important;
  }
  .sd-pb-lg-5_5,
  .sd-py-lg-5_5 {
    padding-bottom: 5.5rem !important;
  }
  .sd-pl-lg-5_5,
  .sd-px-lg-5_5 {
    padding-left: 5.5rem !important;
  }
  .sd-p-lg-6 {
    padding: 6rem !important;
  }
  .sd-pt-lg-6,
  .sd-py-lg-6 {
    padding-top: 6rem !important;
  }
  .sd-pr-lg-6,
  .sd-px-lg-6 {
    padding-right: 6rem !important;
  }
  .sd-pb-lg-6,
  .sd-py-lg-6 {
    padding-bottom: 6rem !important;
  }
  .sd-pl-lg-6,
  .sd-px-lg-6 {
    padding-left: 6rem !important;
  }
  .sd-p-lg-8 {
    padding: 8rem !important;
  }
  .sd-pt-lg-8,
  .sd-py-lg-8 {
    padding-top: 8rem !important;
  }
  .sd-pr-lg-8,
  .sd-px-lg-8 {
    padding-right: 8rem !important;
  }
  .sd-pb-lg-8,
  .sd-py-lg-8 {
    padding-bottom: 8rem !important;
  }
  .sd-pl-lg-8,
  .sd-px-lg-8 {
    padding-left: 8rem !important;
  }
  .sd-p-lg-9 {
    padding: 9rem !important;
  }
  .sd-pt-lg-9,
  .sd-py-lg-9 {
    padding-top: 9rem !important;
  }
  .sd-pr-lg-9,
  .sd-px-lg-9 {
    padding-right: 9rem !important;
  }
  .sd-pb-lg-9,
  .sd-py-lg-9 {
    padding-bottom: 9rem !important;
  }
  .sd-pl-lg-9,
  .sd-px-lg-9 {
    padding-left: 9rem !important;
  }
  .sd-m-lg-auto {
    margin: auto !important;
  }
  .sd-mt-lg-auto,
  .sd-my-lg-auto {
    margin-top: auto !important;
  }
  .sd-mr-lg-auto,
  .sd-mx-lg-auto {
    margin-right: auto !important;
  }
  .sd-mb-lg-auto,
  .sd-my-lg-auto {
    margin-bottom: auto !important;
  }
  .sd-ml-lg-auto,
  .sd-mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .sd-m-xl-0 {
    margin: 0 !important;
  }
  .sd-mt-xl-0,
  .sd-my-xl-0 {
    margin-top: 0 !important;
  }
  .sd-mr-xl-0,
  .sd-mx-xl-0 {
    margin-right: 0 !important;
  }
  .sd-mb-xl-0,
  .sd-my-xl-0 {
    margin-bottom: 0 !important;
  }
  .sd-ml-xl-0,
  .sd-mx-xl-0 {
    margin-left: 0 !important;
  }
  .sd-m-xl-0_25 {
    margin: 0.25rem !important;
  }
  .sd-mt-xl-0_25,
  .sd-my-xl-0_25 {
    margin-top: 0.25rem !important;
  }
  .sd-mr-xl-0_25,
  .sd-mx-xl-0_25 {
    margin-right: 0.25rem !important;
  }
  .sd-mb-xl-0_25,
  .sd-my-xl-0_25 {
    margin-bottom: 0.25rem !important;
  }
  .sd-ml-xl-0_25,
  .sd-mx-xl-0_25 {
    margin-left: 0.25rem !important;
  }
  .sd-m-xl-0_5 {
    margin: 0.5rem !important;
  }
  .sd-mt-xl-0_5,
  .sd-my-xl-0_5 {
    margin-top: 0.5rem !important;
  }
  .sd-mr-xl-0_5,
  .sd-mx-xl-0_5 {
    margin-right: 0.5rem !important;
  }
  .sd-mb-xl-0_5,
  .sd-my-xl-0_5 {
    margin-bottom: 0.5rem !important;
  }
  .sd-ml-xl-0_5,
  .sd-mx-xl-0_5 {
    margin-left: 0.5rem !important;
  }
  .sd-m-xl-0_75 {
    margin: 0.75rem !important;
  }
  .sd-mt-xl-0_75,
  .sd-my-xl-0_75 {
    margin-top: 0.75rem !important;
  }
  .sd-mr-xl-0_75,
  .sd-mx-xl-0_75 {
    margin-right: 0.75rem !important;
  }
  .sd-mb-xl-0_75,
  .sd-my-xl-0_75 {
    margin-bottom: 0.75rem !important;
  }
  .sd-ml-xl-0_75,
  .sd-mx-xl-0_75 {
    margin-left: 0.75rem !important;
  }
  .sd-m-xl-1 {
    margin: 1rem !important;
  }
  .sd-mt-xl-1,
  .sd-my-xl-1 {
    margin-top: 1rem !important;
  }
  .sd-mr-xl-1,
  .sd-mx-xl-1 {
    margin-right: 1rem !important;
  }
  .sd-mb-xl-1,
  .sd-my-xl-1 {
    margin-bottom: 1rem !important;
  }
  .sd-ml-xl-1,
  .sd-mx-xl-1 {
    margin-left: 1rem !important;
  }
  .sd-m-xl-1_125 {
    margin: 1.125rem !important;
  }
  .sd-mt-xl-1_125,
  .sd-my-xl-1_125 {
    margin-top: 1.125rem !important;
  }
  .sd-mr-xl-1_125,
  .sd-mx-xl-1_125 {
    margin-right: 1.125rem !important;
  }
  .sd-mb-xl-1_125,
  .sd-my-xl-1_125 {
    margin-bottom: 1.125rem !important;
  }
  .sd-ml-xl-1_125,
  .sd-mx-xl-1_125 {
    margin-left: 1.125rem !important;
  }
  .sd-m-xl-1_25 {
    margin: 1.25rem !important;
  }
  .sd-mt-xl-1_25,
  .sd-my-xl-1_25 {
    margin-top: 1.25rem !important;
  }
  .sd-mr-xl-1_25,
  .sd-mx-xl-1_25 {
    margin-right: 1.25rem !important;
  }
  .sd-mb-xl-1_25,
  .sd-my-xl-1_25 {
    margin-bottom: 1.25rem !important;
  }
  .sd-ml-xl-1_25,
  .sd-mx-xl-1_25 {
    margin-left: 1.25rem !important;
  }
  .sd-m-xl-1_5 {
    margin: 1.5rem !important;
  }
  .sd-mt-xl-1_5,
  .sd-my-xl-1_5 {
    margin-top: 1.5rem !important;
  }
  .sd-mr-xl-1_5,
  .sd-mx-xl-1_5 {
    margin-right: 1.5rem !important;
  }
  .sd-mb-xl-1_5,
  .sd-my-xl-1_5 {
    margin-bottom: 1.5rem !important;
  }
  .sd-ml-xl-1_5,
  .sd-mx-xl-1_5 {
    margin-left: 1.5rem !important;
  }
  .sd-m-xl-1_75 {
    margin: 1.75rem !important;
  }
  .sd-mt-xl-1_75,
  .sd-my-xl-1_75 {
    margin-top: 1.75rem !important;
  }
  .sd-mr-xl-1_75,
  .sd-mx-xl-1_75 {
    margin-right: 1.75rem !important;
  }
  .sd-mb-xl-1_75,
  .sd-my-xl-1_75 {
    margin-bottom: 1.75rem !important;
  }
  .sd-ml-xl-1_75,
  .sd-mx-xl-1_75 {
    margin-left: 1.75rem !important;
  }
  .sd-m-xl-2 {
    margin: 2rem !important;
  }
  .sd-mt-xl-2,
  .sd-my-xl-2 {
    margin-top: 2rem !important;
  }
  .sd-mr-xl-2,
  .sd-mx-xl-2 {
    margin-right: 2rem !important;
  }
  .sd-mb-xl-2,
  .sd-my-xl-2 {
    margin-bottom: 2rem !important;
  }
  .sd-ml-xl-2,
  .sd-mx-xl-2 {
    margin-left: 2rem !important;
  }
  .sd-m-xl-2_5 {
    margin: 2.5rem !important;
  }
  .sd-mt-xl-2_5,
  .sd-my-xl-2_5 {
    margin-top: 2.5rem !important;
  }
  .sd-mr-xl-2_5,
  .sd-mx-xl-2_5 {
    margin-right: 2.5rem !important;
  }
  .sd-mb-xl-2_5,
  .sd-my-xl-2_5 {
    margin-bottom: 2.5rem !important;
  }
  .sd-ml-xl-2_5,
  .sd-mx-xl-2_5 {
    margin-left: 2.5rem !important;
  }
  .sd-m-xl-2_75 {
    margin: 2.75rem !important;
  }
  .sd-mt-xl-2_75,
  .sd-my-xl-2_75 {
    margin-top: 2.75rem !important;
  }
  .sd-mr-xl-2_75,
  .sd-mx-xl-2_75 {
    margin-right: 2.75rem !important;
  }
  .sd-mb-xl-2_75,
  .sd-my-xl-2_75 {
    margin-bottom: 2.75rem !important;
  }
  .sd-ml-xl-2_75,
  .sd-mx-xl-2_75 {
    margin-left: 2.75rem !important;
  }
  .sd-m-xl-3 {
    margin: 3rem !important;
  }
  .sd-mt-xl-3,
  .sd-my-xl-3 {
    margin-top: 3rem !important;
  }
  .sd-mr-xl-3,
  .sd-mx-xl-3 {
    margin-right: 3rem !important;
  }
  .sd-mb-xl-3,
  .sd-my-xl-3 {
    margin-bottom: 3rem !important;
  }
  .sd-ml-xl-3,
  .sd-mx-xl-3 {
    margin-left: 3rem !important;
  }
  .sd-m-xl-3_25 {
    margin: 3.25rem !important;
  }
  .sd-mt-xl-3_25,
  .sd-my-xl-3_25 {
    margin-top: 3.25rem !important;
  }
  .sd-mr-xl-3_25,
  .sd-mx-xl-3_25 {
    margin-right: 3.25rem !important;
  }
  .sd-mb-xl-3_25,
  .sd-my-xl-3_25 {
    margin-bottom: 3.25rem !important;
  }
  .sd-ml-xl-3_25,
  .sd-mx-xl-3_25 {
    margin-left: 3.25rem !important;
  }
  .sd-m-xl-3_5 {
    margin: 3.5rem !important;
  }
  .sd-mt-xl-3_5,
  .sd-my-xl-3_5 {
    margin-top: 3.5rem !important;
  }
  .sd-mr-xl-3_5,
  .sd-mx-xl-3_5 {
    margin-right: 3.5rem !important;
  }
  .sd-mb-xl-3_5,
  .sd-my-xl-3_5 {
    margin-bottom: 3.5rem !important;
  }
  .sd-ml-xl-3_5,
  .sd-mx-xl-3_5 {
    margin-left: 3.5rem !important;
  }
  .sd-m-xl-4 {
    margin: 4rem !important;
  }
  .sd-mt-xl-4,
  .sd-my-xl-4 {
    margin-top: 4rem !important;
  }
  .sd-mr-xl-4,
  .sd-mx-xl-4 {
    margin-right: 4rem !important;
  }
  .sd-mb-xl-4,
  .sd-my-xl-4 {
    margin-bottom: 4rem !important;
  }
  .sd-ml-xl-4,
  .sd-mx-xl-4 {
    margin-left: 4rem !important;
  }
  .sd-m-xl-5 {
    margin: 5rem !important;
  }
  .sd-mt-xl-5,
  .sd-my-xl-5 {
    margin-top: 5rem !important;
  }
  .sd-mr-xl-5,
  .sd-mx-xl-5 {
    margin-right: 5rem !important;
  }
  .sd-mb-xl-5,
  .sd-my-xl-5 {
    margin-bottom: 5rem !important;
  }
  .sd-ml-xl-5,
  .sd-mx-xl-5 {
    margin-left: 5rem !important;
  }
  .sd-m-xl-5_25 {
    margin: 5.25rem !important;
  }
  .sd-mt-xl-5_25,
  .sd-my-xl-5_25 {
    margin-top: 5.25rem !important;
  }
  .sd-mr-xl-5_25,
  .sd-mx-xl-5_25 {
    margin-right: 5.25rem !important;
  }
  .sd-mb-xl-5_25,
  .sd-my-xl-5_25 {
    margin-bottom: 5.25rem !important;
  }
  .sd-ml-xl-5_25,
  .sd-mx-xl-5_25 {
    margin-left: 5.25rem !important;
  }
  .sd-m-xl-5_5 {
    margin: 5.5rem !important;
  }
  .sd-mt-xl-5_5,
  .sd-my-xl-5_5 {
    margin-top: 5.5rem !important;
  }
  .sd-mr-xl-5_5,
  .sd-mx-xl-5_5 {
    margin-right: 5.5rem !important;
  }
  .sd-mb-xl-5_5,
  .sd-my-xl-5_5 {
    margin-bottom: 5.5rem !important;
  }
  .sd-ml-xl-5_5,
  .sd-mx-xl-5_5 {
    margin-left: 5.5rem !important;
  }
  .sd-m-xl-6 {
    margin: 6rem !important;
  }
  .sd-mt-xl-6,
  .sd-my-xl-6 {
    margin-top: 6rem !important;
  }
  .sd-mr-xl-6,
  .sd-mx-xl-6 {
    margin-right: 6rem !important;
  }
  .sd-mb-xl-6,
  .sd-my-xl-6 {
    margin-bottom: 6rem !important;
  }
  .sd-ml-xl-6,
  .sd-mx-xl-6 {
    margin-left: 6rem !important;
  }
  .sd-m-xl-8 {
    margin: 8rem !important;
  }
  .sd-mt-xl-8,
  .sd-my-xl-8 {
    margin-top: 8rem !important;
  }
  .sd-mr-xl-8,
  .sd-mx-xl-8 {
    margin-right: 8rem !important;
  }
  .sd-mb-xl-8,
  .sd-my-xl-8 {
    margin-bottom: 8rem !important;
  }
  .sd-ml-xl-8,
  .sd-mx-xl-8 {
    margin-left: 8rem !important;
  }
  .sd-m-xl-9 {
    margin: 9rem !important;
  }
  .sd-mt-xl-9,
  .sd-my-xl-9 {
    margin-top: 9rem !important;
  }
  .sd-mr-xl-9,
  .sd-mx-xl-9 {
    margin-right: 9rem !important;
  }
  .sd-mb-xl-9,
  .sd-my-xl-9 {
    margin-bottom: 9rem !important;
  }
  .sd-ml-xl-9,
  .sd-mx-xl-9 {
    margin-left: 9rem !important;
  }
  .sd-p-xl-0 {
    padding: 0 !important;
  }
  .sd-pt-xl-0,
  .sd-py-xl-0 {
    padding-top: 0 !important;
  }
  .sd-pr-xl-0,
  .sd-px-xl-0 {
    padding-right: 0 !important;
  }
  .sd-pb-xl-0,
  .sd-py-xl-0 {
    padding-bottom: 0 !important;
  }
  .sd-pl-xl-0,
  .sd-px-xl-0 {
    padding-left: 0 !important;
  }
  .sd-p-xl-0_25 {
    padding: 0.25rem !important;
  }
  .sd-pt-xl-0_25,
  .sd-py-xl-0_25 {
    padding-top: 0.25rem !important;
  }
  .sd-pr-xl-0_25,
  .sd-px-xl-0_25 {
    padding-right: 0.25rem !important;
  }
  .sd-pb-xl-0_25,
  .sd-py-xl-0_25 {
    padding-bottom: 0.25rem !important;
  }
  .sd-pl-xl-0_25,
  .sd-px-xl-0_25 {
    padding-left: 0.25rem !important;
  }
  .sd-p-xl-0_5 {
    padding: 0.5rem !important;
  }
  .sd-pt-xl-0_5,
  .sd-py-xl-0_5 {
    padding-top: 0.5rem !important;
  }
  .sd-pr-xl-0_5,
  .sd-px-xl-0_5 {
    padding-right: 0.5rem !important;
  }
  .sd-pb-xl-0_5,
  .sd-py-xl-0_5 {
    padding-bottom: 0.5rem !important;
  }
  .sd-pl-xl-0_5,
  .sd-px-xl-0_5 {
    padding-left: 0.5rem !important;
  }
  .sd-p-xl-0_75 {
    padding: 0.75rem !important;
  }
  .sd-pt-xl-0_75,
  .sd-py-xl-0_75 {
    padding-top: 0.75rem !important;
  }
  .sd-pr-xl-0_75,
  .sd-px-xl-0_75 {
    padding-right: 0.75rem !important;
  }
  .sd-pb-xl-0_75,
  .sd-py-xl-0_75 {
    padding-bottom: 0.75rem !important;
  }
  .sd-pl-xl-0_75,
  .sd-px-xl-0_75 {
    padding-left: 0.75rem !important;
  }
  .sd-p-xl-1 {
    padding: 1rem !important;
  }
  .sd-pt-xl-1,
  .sd-py-xl-1 {
    padding-top: 1rem !important;
  }
  .sd-pr-xl-1,
  .sd-px-xl-1 {
    padding-right: 1rem !important;
  }
  .sd-pb-xl-1,
  .sd-py-xl-1 {
    padding-bottom: 1rem !important;
  }
  .sd-pl-xl-1,
  .sd-px-xl-1 {
    padding-left: 1rem !important;
  }
  .sd-p-xl-1_125 {
    padding: 1.125rem !important;
  }
  .sd-pt-xl-1_125,
  .sd-py-xl-1_125 {
    padding-top: 1.125rem !important;
  }
  .sd-pr-xl-1_125,
  .sd-px-xl-1_125 {
    padding-right: 1.125rem !important;
  }
  .sd-pb-xl-1_125,
  .sd-py-xl-1_125 {
    padding-bottom: 1.125rem !important;
  }
  .sd-pl-xl-1_125,
  .sd-px-xl-1_125 {
    padding-left: 1.125rem !important;
  }
  .sd-p-xl-1_25 {
    padding: 1.25rem !important;
  }
  .sd-pt-xl-1_25,
  .sd-py-xl-1_25 {
    padding-top: 1.25rem !important;
  }
  .sd-pr-xl-1_25,
  .sd-px-xl-1_25 {
    padding-right: 1.25rem !important;
  }
  .sd-pb-xl-1_25,
  .sd-py-xl-1_25 {
    padding-bottom: 1.25rem !important;
  }
  .sd-pl-xl-1_25,
  .sd-px-xl-1_25 {
    padding-left: 1.25rem !important;
  }
  .sd-p-xl-1_5 {
    padding: 1.5rem !important;
  }
  .sd-pt-xl-1_5,
  .sd-py-xl-1_5 {
    padding-top: 1.5rem !important;
  }
  .sd-pr-xl-1_5,
  .sd-px-xl-1_5 {
    padding-right: 1.5rem !important;
  }
  .sd-pb-xl-1_5,
  .sd-py-xl-1_5 {
    padding-bottom: 1.5rem !important;
  }
  .sd-pl-xl-1_5,
  .sd-px-xl-1_5 {
    padding-left: 1.5rem !important;
  }
  .sd-p-xl-1_75 {
    padding: 1.75rem !important;
  }
  .sd-pt-xl-1_75,
  .sd-py-xl-1_75 {
    padding-top: 1.75rem !important;
  }
  .sd-pr-xl-1_75,
  .sd-px-xl-1_75 {
    padding-right: 1.75rem !important;
  }
  .sd-pb-xl-1_75,
  .sd-py-xl-1_75 {
    padding-bottom: 1.75rem !important;
  }
  .sd-pl-xl-1_75,
  .sd-px-xl-1_75 {
    padding-left: 1.75rem !important;
  }
  .sd-p-xl-2 {
    padding: 2rem !important;
  }
  .sd-pt-xl-2,
  .sd-py-xl-2 {
    padding-top: 2rem !important;
  }
  .sd-pr-xl-2,
  .sd-px-xl-2 {
    padding-right: 2rem !important;
  }
  .sd-pb-xl-2,
  .sd-py-xl-2 {
    padding-bottom: 2rem !important;
  }
  .sd-pl-xl-2,
  .sd-px-xl-2 {
    padding-left: 2rem !important;
  }
  .sd-p-xl-2_5 {
    padding: 2.5rem !important;
  }
  .sd-pt-xl-2_5,
  .sd-py-xl-2_5 {
    padding-top: 2.5rem !important;
  }
  .sd-pr-xl-2_5,
  .sd-px-xl-2_5 {
    padding-right: 2.5rem !important;
  }
  .sd-pb-xl-2_5,
  .sd-py-xl-2_5 {
    padding-bottom: 2.5rem !important;
  }
  .sd-pl-xl-2_5,
  .sd-px-xl-2_5 {
    padding-left: 2.5rem !important;
  }
  .sd-p-xl-2_75 {
    padding: 2.75rem !important;
  }
  .sd-pt-xl-2_75,
  .sd-py-xl-2_75 {
    padding-top: 2.75rem !important;
  }
  .sd-pr-xl-2_75,
  .sd-px-xl-2_75 {
    padding-right: 2.75rem !important;
  }
  .sd-pb-xl-2_75,
  .sd-py-xl-2_75 {
    padding-bottom: 2.75rem !important;
  }
  .sd-pl-xl-2_75,
  .sd-px-xl-2_75 {
    padding-left: 2.75rem !important;
  }
  .sd-p-xl-3 {
    padding: 3rem !important;
  }
  .sd-pt-xl-3,
  .sd-py-xl-3 {
    padding-top: 3rem !important;
  }
  .sd-pr-xl-3,
  .sd-px-xl-3 {
    padding-right: 3rem !important;
  }
  .sd-pb-xl-3,
  .sd-py-xl-3 {
    padding-bottom: 3rem !important;
  }
  .sd-pl-xl-3,
  .sd-px-xl-3 {
    padding-left: 3rem !important;
  }
  .sd-p-xl-3_25 {
    padding: 3.25rem !important;
  }
  .sd-pt-xl-3_25,
  .sd-py-xl-3_25 {
    padding-top: 3.25rem !important;
  }
  .sd-pr-xl-3_25,
  .sd-px-xl-3_25 {
    padding-right: 3.25rem !important;
  }
  .sd-pb-xl-3_25,
  .sd-py-xl-3_25 {
    padding-bottom: 3.25rem !important;
  }
  .sd-pl-xl-3_25,
  .sd-px-xl-3_25 {
    padding-left: 3.25rem !important;
  }
  .sd-p-xl-3_5 {
    padding: 3.5rem !important;
  }
  .sd-pt-xl-3_5,
  .sd-py-xl-3_5 {
    padding-top: 3.5rem !important;
  }
  .sd-pr-xl-3_5,
  .sd-px-xl-3_5 {
    padding-right: 3.5rem !important;
  }
  .sd-pb-xl-3_5,
  .sd-py-xl-3_5 {
    padding-bottom: 3.5rem !important;
  }
  .sd-pl-xl-3_5,
  .sd-px-xl-3_5 {
    padding-left: 3.5rem !important;
  }
  .sd-p-xl-4 {
    padding: 4rem !important;
  }
  .sd-pt-xl-4,
  .sd-py-xl-4 {
    padding-top: 4rem !important;
  }
  .sd-pr-xl-4,
  .sd-px-xl-4 {
    padding-right: 4rem !important;
  }
  .sd-pb-xl-4,
  .sd-py-xl-4 {
    padding-bottom: 4rem !important;
  }
  .sd-pl-xl-4,
  .sd-px-xl-4 {
    padding-left: 4rem !important;
  }
  .sd-p-xl-5 {
    padding: 5rem !important;
  }
  .sd-pt-xl-5,
  .sd-py-xl-5 {
    padding-top: 5rem !important;
  }
  .sd-pr-xl-5,
  .sd-px-xl-5 {
    padding-right: 5rem !important;
  }
  .sd-pb-xl-5,
  .sd-py-xl-5 {
    padding-bottom: 5rem !important;
  }
  .sd-pl-xl-5,
  .sd-px-xl-5 {
    padding-left: 5rem !important;
  }
  .sd-p-xl-5_25 {
    padding: 5.25rem !important;
  }
  .sd-pt-xl-5_25,
  .sd-py-xl-5_25 {
    padding-top: 5.25rem !important;
  }
  .sd-pr-xl-5_25,
  .sd-px-xl-5_25 {
    padding-right: 5.25rem !important;
  }
  .sd-pb-xl-5_25,
  .sd-py-xl-5_25 {
    padding-bottom: 5.25rem !important;
  }
  .sd-pl-xl-5_25,
  .sd-px-xl-5_25 {
    padding-left: 5.25rem !important;
  }
  .sd-p-xl-5_5 {
    padding: 5.5rem !important;
  }
  .sd-pt-xl-5_5,
  .sd-py-xl-5_5 {
    padding-top: 5.5rem !important;
  }
  .sd-pr-xl-5_5,
  .sd-px-xl-5_5 {
    padding-right: 5.5rem !important;
  }
  .sd-pb-xl-5_5,
  .sd-py-xl-5_5 {
    padding-bottom: 5.5rem !important;
  }
  .sd-pl-xl-5_5,
  .sd-px-xl-5_5 {
    padding-left: 5.5rem !important;
  }
  .sd-p-xl-6 {
    padding: 6rem !important;
  }
  .sd-pt-xl-6,
  .sd-py-xl-6 {
    padding-top: 6rem !important;
  }
  .sd-pr-xl-6,
  .sd-px-xl-6 {
    padding-right: 6rem !important;
  }
  .sd-pb-xl-6,
  .sd-py-xl-6 {
    padding-bottom: 6rem !important;
  }
  .sd-pl-xl-6,
  .sd-px-xl-6 {
    padding-left: 6rem !important;
  }
  .sd-p-xl-8 {
    padding: 8rem !important;
  }
  .sd-pt-xl-8,
  .sd-py-xl-8 {
    padding-top: 8rem !important;
  }
  .sd-pr-xl-8,
  .sd-px-xl-8 {
    padding-right: 8rem !important;
  }
  .sd-pb-xl-8,
  .sd-py-xl-8 {
    padding-bottom: 8rem !important;
  }
  .sd-pl-xl-8,
  .sd-px-xl-8 {
    padding-left: 8rem !important;
  }
  .sd-p-xl-9 {
    padding: 9rem !important;
  }
  .sd-pt-xl-9,
  .sd-py-xl-9 {
    padding-top: 9rem !important;
  }
  .sd-pr-xl-9,
  .sd-px-xl-9 {
    padding-right: 9rem !important;
  }
  .sd-pb-xl-9,
  .sd-py-xl-9 {
    padding-bottom: 9rem !important;
  }
  .sd-pl-xl-9,
  .sd-px-xl-9 {
    padding-left: 9rem !important;
  }
  .sd-m-xl-auto {
    margin: auto !important;
  }
  .sd-mt-xl-auto,
  .sd-my-xl-auto {
    margin-top: auto !important;
  }
  .sd-mr-xl-auto,
  .sd-mx-xl-auto {
    margin-right: auto !important;
  }
  .sd-mb-xl-auto,
  .sd-my-xl-auto {
    margin-bottom: auto !important;
  }
  .sd-ml-xl-auto,
  .sd-mx-xl-auto {
    margin-left: auto !important;
  }
}
.overflow-hidden {
  overflow: hidden;
}

.overflow-auto {
  overflow: auto;
}

.position-relative {
  position: relative;
}

.content-visibility {
  content-visibility: auto;
}

.overflow-wrapper {
  padding: 0 !important;
}

.overflow-wrapper > * {
  padding-right: 15px;
  padding-left: 15px;
}
.overflow-wrapper > * .overflow-auto {
  padding-left: 15px;
}

.overflow-element:last-of-type {
  margin-right: 15px;
}

.wp-block-quote {
  padding-left: 1em;
  margin: 0 0 1.75em;
  border-left: 0.25em solid;
}
.wp-block-quote.has-text-align-right {
  padding-right: 1em;
  padding-left: 0;
  border-right: 0.25em solid;
  border-left: none;
}
.wp-block-quote.has-text-align-center {
  padding-left: 0;
  border: 0;
}

.wp-block-table thead {
  text-align: center;
  border-bottom: 3px solid;
}
.wp-block-table td,
.wp-block-table th {
  padding: 0.5em;
  word-break: normal;
  border: 1px solid;
}
.wp-block-table figcaption {
  font-size: 13px;
  color: #555;
  text-align: center;
}
.wp-block-table tfoot {
  border-top: 3px solid;
}

/**
  Original Bootstrap SASS files
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #ffffff;
  --gray: #73706f;
  --gray-dark: #1a1817;
  --primary-light: #2ee674;
  --primary-lighter: rgb(231.4, 248.9, 239.6);
  --primary-dark: #009952;
  --accent-light: rgb(161, 211.8, 233);
  --accent-lighter: rgb(208, 233.4, 244);
  --warning-light: rgb(251.8, 228.6, 217.8);
  --danger-light: rgb(251, 220, 217.2);
  --primary: #13c265;
  --secondary: #2b65d9;
  --success: #13c265;
  --info: #17a2b8;
  --warning: #ef7b45;
  --danger: #eb5042;
  --light: #faf8f7;
  --dark: #1a1817;
  --accent: #1493c8;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: hkgrotesk;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.625;
  color: #1a1817;
  text-align: left;
  background-color: #ffffff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #2b65d9;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #009952;
  text-decoration: underline;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #73706f;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label, legend {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .wp-block-yoast-faq-block .schema-faq-question, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: questasans;
  font-weight: 700;
  line-height: 1.2;
  color: #1a1817;
}

h1, .h1 {
  font-size: 2rem;
}

h2, .h2 {
  font-size: 1.625rem;
}

h3, .h3 {
  font-size: 1.375rem;
}

h4, .h4, .wp-block-yoast-faq-block .schema-faq-question {
  font-size: 1.125rem;
}

h5, .h5 {
  font-size: 1.0625rem;
}

h6, .h6 {
  font-size: 0.875rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #73706f;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #ffffff;
  border: 1px solid #e6e4e3;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #73706f;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #ffffff;
  background-color: #212529;
  border-radius: 0.25rem;
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container, .hero-container, .b2c-page-container,
.container-fluid,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container, .hero-container, .b2c-page-container {
    max-width: 1200px;
  }
}
.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  -ms-flex: 0 0 33.3333333333%;
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  -ms-flex: 0 0 16.6666666667%;
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  -ms-flex: 0 0 8.3333333333%;
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.col-2 {
  -ms-flex: 0 0 16.6666666667%;
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-3 {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  -ms-flex: 0 0 33.3333333333%;
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.col-5 {
  -ms-flex: 0 0 41.6666666667%;
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.col-6 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  -ms-flex: 0 0 58.3333333333%;
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.col-8 {
  -ms-flex: 0 0 66.6666666667%;
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.col-9 {
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  -ms-flex: 0 0 83.3333333333%;
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.col-11 {
  -ms-flex: 0 0 91.6666666667%;
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.col-12 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  -ms-flex-order: -1;
  order: -1;
}

.order-last {
  -ms-flex-order: 13;
  order: 13;
}

.order-0 {
  -ms-flex-order: 0;
  order: 0;
}

.order-1 {
  -ms-flex-order: 1;
  order: 1;
}

.order-2 {
  -ms-flex-order: 2;
  order: 2;
}

.order-3 {
  -ms-flex-order: 3;
  order: 3;
}

.order-4 {
  -ms-flex-order: 4;
  order: 4;
}

.order-5 {
  -ms-flex-order: 5;
  order: 5;
}

.order-6 {
  -ms-flex-order: 6;
  order: 6;
}

.order-7 {
  -ms-flex-order: 7;
  order: 7;
}

.order-8 {
  -ms-flex-order: 8;
  order: 8;
}

.order-9 {
  -ms-flex-order: 9;
  order: 9;
}

.order-10 {
  -ms-flex-order: 10;
  order: 10;
}

.order-11 {
  -ms-flex-order: 11;
  order: 11;
}

.order-12 {
  -ms-flex-order: 12;
  order: 12;
}

.offset-1 {
  margin-left: 8.3333333333%;
}

.offset-2 {
  margin-left: 16.6666666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333333333%;
}

.offset-5 {
  margin-left: 41.6666666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333333333%;
}

.offset-8 {
  margin-left: 66.6666666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333333333%;
}

.offset-11 {
  margin-left: 91.6666666667%;
}

@media (min-width: 576px) {
  .col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    -ms-flex: 0 0 33.3333333333%;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    -ms-flex: 0 0 16.6666666667%;
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-sm-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    -ms-flex: 0 0 8.3333333333%;
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-sm-2 {
    -ms-flex: 0 0 16.6666666667%;
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-sm-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    -ms-flex: 0 0 33.3333333333%;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-sm-5 {
    -ms-flex: 0 0 41.6666666667%;
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-sm-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    -ms-flex: 0 0 58.3333333333%;
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-sm-8 {
    -ms-flex: 0 0 66.6666666667%;
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-sm-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    -ms-flex: 0 0 83.3333333333%;
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-sm-11 {
    -ms-flex: 0 0 91.6666666667%;
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-sm-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    -ms-flex-order: -1;
    order: -1;
  }
  .order-sm-last {
    -ms-flex-order: 13;
    order: 13;
  }
  .order-sm-0 {
    -ms-flex-order: 0;
    order: 0;
  }
  .order-sm-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .order-sm-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .order-sm-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  .order-sm-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  .order-sm-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  .order-sm-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  .order-sm-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  .order-sm-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  .order-sm-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  .order-sm-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  .order-sm-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  .order-sm-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.3333333333%;
  }
  .offset-sm-2 {
    margin-left: 16.6666666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.3333333333%;
  }
  .offset-sm-5 {
    margin-left: 41.6666666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.3333333333%;
  }
  .offset-sm-8 {
    margin-left: 66.6666666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.3333333333%;
  }
  .offset-sm-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    -ms-flex: 0 0 33.3333333333%;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    -ms-flex: 0 0 16.6666666667%;
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-md-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    -ms-flex: 0 0 8.3333333333%;
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-md-2 {
    -ms-flex: 0 0 16.6666666667%;
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-md-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    -ms-flex: 0 0 33.3333333333%;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-md-5 {
    -ms-flex: 0 0 41.6666666667%;
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-md-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    -ms-flex: 0 0 58.3333333333%;
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-md-8 {
    -ms-flex: 0 0 66.6666666667%;
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-md-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    -ms-flex: 0 0 83.3333333333%;
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-md-11 {
    -ms-flex: 0 0 91.6666666667%;
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-md-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    -ms-flex-order: -1;
    order: -1;
  }
  .order-md-last {
    -ms-flex-order: 13;
    order: 13;
  }
  .order-md-0 {
    -ms-flex-order: 0;
    order: 0;
  }
  .order-md-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .order-md-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .order-md-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  .order-md-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  .order-md-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  .order-md-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  .order-md-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  .order-md-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  .order-md-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  .order-md-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  .order-md-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  .order-md-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.3333333333%;
  }
  .offset-md-2 {
    margin-left: 16.6666666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.3333333333%;
  }
  .offset-md-5 {
    margin-left: 41.6666666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.3333333333%;
  }
  .offset-md-8 {
    margin-left: 66.6666666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.3333333333%;
  }
  .offset-md-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    -ms-flex: 0 0 33.3333333333%;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    -ms-flex: 0 0 16.6666666667%;
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lg-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    -ms-flex: 0 0 8.3333333333%;
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-lg-2 {
    -ms-flex: 0 0 16.6666666667%;
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lg-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    -ms-flex: 0 0 33.3333333333%;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-lg-5 {
    -ms-flex: 0 0 41.6666666667%;
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-lg-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    -ms-flex: 0 0 58.3333333333%;
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-lg-8 {
    -ms-flex: 0 0 66.6666666667%;
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-lg-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    -ms-flex: 0 0 83.3333333333%;
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-lg-11 {
    -ms-flex: 0 0 91.6666666667%;
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-lg-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    -ms-flex-order: -1;
    order: -1;
  }
  .order-lg-last {
    -ms-flex-order: 13;
    order: 13;
  }
  .order-lg-0 {
    -ms-flex-order: 0;
    order: 0;
  }
  .order-lg-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .order-lg-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .order-lg-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  .order-lg-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  .order-lg-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  .order-lg-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  .order-lg-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  .order-lg-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  .order-lg-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  .order-lg-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  .order-lg-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  .order-lg-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.3333333333%;
  }
  .offset-lg-2 {
    margin-left: 16.6666666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }
  .offset-lg-5 {
    margin-left: 41.6666666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.3333333333%;
  }
  .offset-lg-8 {
    margin-left: 66.6666666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.3333333333%;
  }
  .offset-lg-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    -ms-flex: 0 0 33.3333333333%;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    -ms-flex: 0 0 16.6666666667%;
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xl-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    -ms-flex: 0 0 8.3333333333%;
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-xl-2 {
    -ms-flex: 0 0 16.6666666667%;
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xl-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    -ms-flex: 0 0 33.3333333333%;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-xl-5 {
    -ms-flex: 0 0 41.6666666667%;
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-xl-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    -ms-flex: 0 0 58.3333333333%;
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-xl-8 {
    -ms-flex: 0 0 66.6666666667%;
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-xl-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    -ms-flex: 0 0 83.3333333333%;
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-xl-11 {
    -ms-flex: 0 0 91.6666666667%;
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-xl-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    -ms-flex-order: -1;
    order: -1;
  }
  .order-xl-last {
    -ms-flex-order: 13;
    order: 13;
  }
  .order-xl-0 {
    -ms-flex-order: 0;
    order: 0;
  }
  .order-xl-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .order-xl-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .order-xl-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  .order-xl-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  .order-xl-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  .order-xl-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  .order-xl-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  .order-xl-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  .order-xl-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  .order-xl-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  .order-xl-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  .order-xl-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.3333333333%;
  }
  .offset-xl-2 {
    margin-left: 16.6666666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.3333333333%;
  }
  .offset-xl-5 {
    margin-left: 41.6666666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.3333333333%;
  }
  .offset-xl-8 {
    margin-left: 66.6666666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.3333333333%;
  }
  .offset-xl-11 {
    margin-left: 91.6666666667%;
  }
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #1a1817;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #e6e4e3;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #e6e4e3;
}
.table tbody + tbody {
  border-top: 2px solid #e6e4e3;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #e6e4e3;
}
.table-bordered th,
.table-bordered td {
  border: 1px solid #e6e4e3;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #ffffff;
}

.table-hover tbody tr:hover {
  color: #1a1817;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: rgb(188.92, 237.92, 211.88);
}
.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: rgb(132.28, 223.28, 174.92);
}

.table-hover .table-primary:hover {
  background-color: rgb(168.6573737374, 232.6826262626, 198.6577777778);
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: rgb(168.6573737374, 232.6826262626, 198.6577777778);
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: rgb(195.64, 211.88, 244.36);
}
.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: rgb(144.76, 174.92, 235.24);
}

.table-hover .table-secondary:hover {
  background-color: rgb(174.016, 196.172, 240.484);
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: rgb(174.016, 196.172, 240.484);
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: rgb(188.92, 237.92, 211.88);
}
.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: rgb(132.28, 223.28, 174.92);
}

.table-hover .table-success:hover {
  background-color: rgb(168.6573737374, 232.6826262626, 198.6577777778);
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: rgb(168.6573737374, 232.6826262626, 198.6577777778);
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: rgb(190.04, 228.96, 235.12);
}
.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: rgb(134.36, 206.64, 218.08);
}

.table-hover .table-info:hover {
  background-color: rgb(170.5152475248, 221.1332673267, 229.1447524752);
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: rgb(170.5152475248, 221.1332673267, 229.1447524752);
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: rgb(250.52, 218.04, 202.92);
}
.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: rgb(246.68, 186.36, 158.28);
}

.table-hover .table-warning:hover {
  background-color: rgb(248.5001980198, 201.3766336634, 179.4398019802);
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: rgb(248.5001980198, 201.3766336634, 179.4398019802);
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: rgb(249.4, 206, 202.08);
}
.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: rgb(244.6, 164, 156.72);
}

.table-hover .table-danger:hover {
  background-color: rgb(246.9598086124, 184.6483253589, 179.0201913876);
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: rgb(246.9598086124, 184.6483253589, 179.0201913876);
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: rgb(253.6, 253.04, 252.76);
}
.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: rgb(252.4, 251.36, 250.84);
}

.table-hover .table-light:hover {
  background-color: rgb(243.7923076923, 239.3092307692, 237.0676923077);
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: rgb(243.7923076923, 239.3092307692, 237.0676923077);
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: rgb(190.88, 190.32, 190.04);
}
.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: rgb(135.92, 134.88, 134.36);
}

.table-hover .table-dark:hover {
  background-color: rgb(178.2129718004, 177.5423427332, 177.2070281996);
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: rgb(178.2129718004, 177.5423427332, 177.2070281996);
}

.table-accent,
.table-accent > th,
.table-accent > td {
  background-color: rgb(189.2, 224.76, 239.6);
}
.table-accent th,
.table-accent td,
.table-accent thead th,
.table-accent tbody + tbody {
  border-color: rgb(132.8, 198.84, 226.4);
}

.table-hover .table-accent:hover {
  background-color: rgb(168.5362068966, 215.2634482759, 234.7637931034);
}
.table-hover .table-accent:hover > td,
.table-hover .table-accent:hover > th {
  background-color: rgb(168.5362068966, 215.2634482759, 234.7637931034);
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #ffffff;
  background-color: #1a1817;
  border-color: rgb(46.2959183673, 42.7346938776, 40.9540816327);
}
.table .thead-light th {
  color: #403e3d;
  background-color: #e6e4e3;
  border-color: #e6e4e3;
}

.table-dark {
  color: #ffffff;
  background-color: #1a1817;
}
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: rgb(46.2959183673, 42.7346938776, 40.9540816327);
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  color: #ffffff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 1rem + 4px);
  padding: 0.5rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #1a1817;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 2px solid #e6e4e3;
  border-radius: 4px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #1a1817;
}
.form-control:focus {
  color: #1a1817;
  background-color: #ffffff;
  border-color: #2b65d9;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(43, 101, 217, 0.25);
}
.form-control::-webkit-input-placeholder {
  color: #a6a2a1;
  opacity: 1;
}
.form-control::-moz-placeholder {
  color: #a6a2a1;
  opacity: 1;
}
.form-control:-ms-input-placeholder {
  color: #a6a2a1;
  opacity: 1;
}
.form-control::-ms-input-placeholder {
  color: #a6a2a1;
  opacity: 1;
}
.form-control::placeholder {
  color: #a6a2a1;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e6e4e3;
  opacity: 1;
}

input[type=date].form-control,
input[type=time].form-control,
input[type=datetime-local].form-control,
input[type=month].form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

select.form-control:focus::-ms-value {
  color: #1a1817;
  background-color: #ffffff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.5rem + 2px);
  padding-bottom: calc(0.5rem + 2px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(1rem + 2px);
  padding-bottom: calc(1rem + 2px);
  font-size: 1.125rem;
  line-height: 1.35;
}

.col-form-label-sm {
  padding-top: calc(0.125rem + 2px);
  padding-bottom: calc(0.125rem + 2px);
  font-size: 0.875rem;
  line-height: 1.72;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.5rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #1a1817;
  background-color: transparent;
  border: solid transparent;
  border-width: 2px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.72em + 0.25rem + 4px);
  padding: 0.125rem 1rem;
  font-size: 0.875rem;
  line-height: 1.72;
  border-radius: 4px;
}

.form-control-lg {
  height: calc(1.35em + 2rem);
  padding: 1rem 1rem;
  font-size: 1.125rem;
  line-height: 1.35;
  border-radius: 4px;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  color: #73706f;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #13c265;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.625;
  color: #ffffff;
  background-color: rgba(19, 194, 101, 0.9);
  border-radius: 0.25rem;
}
.form-row > .col > .valid-tooltip, .form-row > [class*=col-] > .valid-tooltip {
  left: 5px;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #13c265;
  padding-right: calc(1.5em + 1rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2313c265' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.25rem) center;
  background-size: calc(0.75em + 0.5rem) calc(0.75em + 0.5rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #13c265;
  box-shadow: 0 0 0 0.25rem rgba(19, 194, 101, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 1rem);
  background-position: top calc(0.375em + 0.25rem) right calc(0.375em + 0.25rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #13c265;
  padding-right: calc(0.75em + 2.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%231a1817' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 1rem center/8px 10px no-repeat, #ffffff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2313c265' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 2rem/calc(0.75em + 0.5rem) calc(0.75em + 0.5rem) no-repeat;
}
.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #13c265;
  box-shadow: 0 0 0 0.25rem rgba(19, 194, 101, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #13c265;
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #13c265;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #13c265;
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: rgb(30.9436619718, 233.0563380282, 125.6478873239);
  background-color: rgb(30.9436619718, 233.0563380282, 125.6478873239);
}
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.25rem rgba(19, 194, 101, 0.25);
}
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #13c265;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #13c265;
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #13c265;
  box-shadow: 0 0 0 0.25rem rgba(19, 194, 101, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #eb5042;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.625;
  color: #ffffff;
  background-color: rgba(235, 80, 66, 0.9);
  border-radius: 0.25rem;
}
.form-row > .col > .invalid-tooltip, .form-row > [class*=col-] > .invalid-tooltip {
  left: 5px;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #eb5042;
  padding-right: calc(1.5em + 1rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23eb5042' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23eb5042' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.25rem) center;
  background-size: calc(0.75em + 0.5rem) calc(0.75em + 0.5rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #eb5042;
  box-shadow: 0 0 0 0.25rem rgba(235, 80, 66, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 1rem);
  background-position: top calc(0.375em + 0.25rem) right calc(0.375em + 0.25rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #eb5042;
  padding-right: calc(0.75em + 2.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%231a1817' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 1rem center/8px 10px no-repeat, #ffffff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23eb5042' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23eb5042' stroke='none'/%3e%3c/svg%3e") center right 2rem/calc(0.75em + 0.5rem) calc(0.75em + 0.5rem) no-repeat;
}
.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #eb5042;
  box-shadow: 0 0 0 0.25rem rgba(235, 80, 66, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #eb5042;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #eb5042;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #eb5042;
}
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: rgb(239.8803827751, 122.7033492823, 112.1196172249);
  background-color: rgb(239.8803827751, 122.7033492823, 112.1196172249);
}
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.25rem rgba(235, 80, 66, 0.25);
}
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #eb5042;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #eb5042;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #eb5042;
  box-shadow: 0 0 0 0.25rem rgba(235, 80, 66, 0.25);
}

.form-inline {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -ms-flex-align: center;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label, .form-inline legend {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-family: hkgrotesk;
  font-weight: 700;
  color: #1a1817;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.625rem 1rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 4px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #1a1817;
  text-decoration: none;
}
.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(43, 101, 217, 0.25);
}
.btn.disabled, .btn:disabled {
  opacity: 0.5;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #ffffff;
  background-color: #13c265;
  border-color: #13c265;
}
.btn-primary:hover {
  color: #ffffff;
  background-color: rgb(15.588028169, 159.161971831, 82.8626760563);
  border-color: rgb(14.4507042254, 147.5492957746, 76.8169014085);
}
.btn-primary:focus, .btn-primary.focus {
  color: #ffffff;
  background-color: rgb(15.588028169, 159.161971831, 82.8626760563);
  border-color: rgb(14.4507042254, 147.5492957746, 76.8169014085);
  box-shadow: 0 0 0 0.25rem rgba(54.4, 203.15, 124.1, 0.5);
}
.btn-primary.disabled, .btn-primary:disabled {
  color: #ffffff;
  background-color: #13c265;
  border-color: #13c265;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #ffffff;
  background-color: rgb(14.4507042254, 147.5492957746, 76.8169014085);
  border-color: rgb(13.3133802817, 135.9366197183, 70.7711267606);
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(54.4, 203.15, 124.1, 0.5);
}

.btn-secondary {
  color: #ffffff;
  background-color: #2b65d9;
  border-color: #2b65d9;
}
.btn-secondary:hover {
  color: #ffffff;
  background-color: rgb(33.706, 85.152, 188.044);
  border-color: rgb(31.768, 80.256, 177.232);
}
.btn-secondary:focus, .btn-secondary.focus {
  color: #ffffff;
  background-color: rgb(33.706, 85.152, 188.044);
  border-color: rgb(31.768, 80.256, 177.232);
  box-shadow: 0 0 0 0.25rem rgba(74.8, 124.1, 222.7, 0.5);
}
.btn-secondary.disabled, .btn-secondary:disabled {
  color: #ffffff;
  background-color: #2b65d9;
  border-color: #2b65d9;
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  color: #ffffff;
  background-color: rgb(31.768, 80.256, 177.232);
  border-color: rgb(29.83, 75.36, 166.42);
}
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(74.8, 124.1, 222.7, 0.5);
}

.btn-success {
  color: #ffffff;
  background-color: #13c265;
  border-color: #13c265;
}
.btn-success:hover {
  color: #ffffff;
  background-color: rgb(15.588028169, 159.161971831, 82.8626760563);
  border-color: rgb(14.4507042254, 147.5492957746, 76.8169014085);
}
.btn-success:focus, .btn-success.focus {
  color: #ffffff;
  background-color: rgb(15.588028169, 159.161971831, 82.8626760563);
  border-color: rgb(14.4507042254, 147.5492957746, 76.8169014085);
  box-shadow: 0 0 0 0.25rem rgba(54.4, 203.15, 124.1, 0.5);
}
.btn-success.disabled, .btn-success:disabled {
  color: #ffffff;
  background-color: #13c265;
  border-color: #13c265;
}
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
  color: #ffffff;
  background-color: rgb(14.4507042254, 147.5492957746, 76.8169014085);
  border-color: rgb(13.3133802817, 135.9366197183, 70.7711267606);
}
.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(54.4, 203.15, 124.1, 0.5);
}

.btn-info {
  color: #ffffff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:hover {
  color: #ffffff;
  background-color: rgb(18.75, 132.0652173913, 150);
  border-color: rgb(17.3333333333, 122.0869565217, 138.6666666667);
}
.btn-info:focus, .btn-info.focus {
  color: #ffffff;
  background-color: rgb(18.75, 132.0652173913, 150);
  border-color: rgb(17.3333333333, 122.0869565217, 138.6666666667);
  box-shadow: 0 0 0 0.25rem rgba(57.8, 175.95, 194.65, 0.5);
}
.btn-info.disabled, .btn-info:disabled {
  color: #ffffff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
  color: #ffffff;
  background-color: rgb(17.3333333333, 122.0869565217, 138.6666666667);
  border-color: rgb(15.9166666667, 112.1086956522, 127.3333333333);
}
.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(57.8, 175.95, 194.65, 0.5);
}

.btn-warning {
  color: #ffffff;
  background-color: #ef7b45;
  border-color: #ef7b45;
}
.btn-warning:hover {
  color: #ffffff;
  background-color: rgb(235.9702970297, 98.004950495, 33.7797029703);
  border-color: rgb(234.9603960396, 89.6732673267, 22.0396039604);
}
.btn-warning:focus, .btn-warning.focus {
  color: #ffffff;
  background-color: rgb(235.9702970297, 98.004950495, 33.7797029703);
  border-color: rgb(234.9603960396, 89.6732673267, 22.0396039604);
  box-shadow: 0 0 0 0.25rem rgba(241.4, 142.8, 96.9, 0.5);
}
.btn-warning.disabled, .btn-warning:disabled {
  color: #ffffff;
  background-color: #ef7b45;
  border-color: #ef7b45;
}
.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
  color: #ffffff;
  background-color: rgb(234.9603960396, 89.6732673267, 22.0396039604);
  border-color: rgb(224.9034653465, 84.6410891089, 19.3465346535);
}
.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(241.4, 142.8, 96.9, 0.5);
}

.btn-danger {
  color: #ffffff;
  background-color: #eb5042;
  border-color: #eb5042;
}
.btn-danger:hover {
  color: #ffffff;
  background-color: rgb(231.3397129187, 47.9724880383, 31.4102870813);
  border-color: rgb(226.0765550239, 40.6698564593, 23.9234449761);
}
.btn-danger:focus, .btn-danger.focus {
  color: #ffffff;
  background-color: rgb(231.3397129187, 47.9724880383, 31.4102870813);
  border-color: rgb(226.0765550239, 40.6698564593, 23.9234449761);
  box-shadow: 0 0 0 0.25rem rgba(238, 106.25, 94.35, 0.5);
}
.btn-danger.disabled, .btn-danger:disabled {
  color: #ffffff;
  background-color: #eb5042;
  border-color: #eb5042;
}
.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
  color: #ffffff;
  background-color: rgb(226.0765550239, 40.6698564593, 23.9234449761);
  border-color: rgb(214.5466507177, 38.5956937799, 22.7033492823);
}
.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(238, 106.25, 94.35, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #faf8f7;
  border-color: #faf8f7;
}
.btn-light:hover {
  color: #212529;
  background-color: rgb(235.2884615385, 227.4038461538, 223.4615384615);
  border-color: rgb(230.3846153846, 220.5384615385, 215.6153846154);
}
.btn-light:focus, .btn-light.focus {
  color: #212529;
  background-color: rgb(235.2884615385, 227.4038461538, 223.4615384615);
  border-color: rgb(230.3846153846, 220.5384615385, 215.6153846154);
  box-shadow: 0 0 0 0.25rem rgba(217.45, 216.35, 216.1, 0.5);
}
.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #faf8f7;
  border-color: #faf8f7;
}
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: rgb(230.3846153846, 220.5384615385, 215.6153846154);
  border-color: rgb(225.4807692308, 213.6730769231, 207.7692307692);
}
.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(217.45, 216.35, 216.1, 0.5);
}

.btn-dark {
  color: #ffffff;
  background-color: #1a1817;
  border-color: #1a1817;
}
.btn-dark:hover {
  color: #ffffff;
  background-color: rgb(5.7040816327, 5.2653061224, 5.0459183673);
  border-color: black;
}
.btn-dark:focus, .btn-dark.focus {
  color: #ffffff;
  background-color: rgb(5.7040816327, 5.2653061224, 5.0459183673);
  border-color: black;
  box-shadow: 0 0 0 0.25rem rgba(60.35, 58.65, 57.8, 0.5);
}
.btn-dark.disabled, .btn-dark:disabled {
  color: #ffffff;
  background-color: #1a1817;
  border-color: #1a1817;
}
.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle {
  color: #ffffff;
  background-color: black;
  border-color: black;
}
.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(60.35, 58.65, 57.8, 0.5);
}

.btn-accent {
  color: #ffffff;
  background-color: #1493c8;
  border-color: #1493c8;
}
.btn-accent:hover {
  color: #ffffff;
  background-color: rgb(16.5227272727, 121.4420454545, 165.2272727273);
  border-color: rgb(15.3636363636, 112.9227272727, 153.6363636364);
}
.btn-accent:focus, .btn-accent.focus {
  color: #ffffff;
  background-color: rgb(16.5227272727, 121.4420454545, 165.2272727273);
  border-color: rgb(15.3636363636, 112.9227272727, 153.6363636364);
  box-shadow: 0 0 0 0.25rem rgba(55.25, 163.2, 208.25, 0.5);
}
.btn-accent.disabled, .btn-accent:disabled {
  color: #ffffff;
  background-color: #1493c8;
  border-color: #1493c8;
}
.btn-accent:not(:disabled):not(.disabled):active, .btn-accent:not(:disabled):not(.disabled).active, .show > .btn-accent.dropdown-toggle {
  color: #ffffff;
  background-color: rgb(15.3636363636, 112.9227272727, 153.6363636364);
  border-color: rgb(14.2045454545, 104.4034090909, 142.0454545455);
}
.btn-accent:not(:disabled):not(.disabled):active:focus, .btn-accent:not(:disabled):not(.disabled).active:focus, .show > .btn-accent.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(55.25, 163.2, 208.25, 0.5);
}

.btn-outline-primary {
  color: #13c265;
  border-color: #13c265;
}
.btn-outline-primary:hover {
  color: #ffffff;
  background-color: #13c265;
  border-color: #13c265;
}
.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.25rem rgba(19, 194, 101, 0.5);
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #13c265;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
  color: #ffffff;
  background-color: #13c265;
  border-color: #13c265;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(19, 194, 101, 0.5);
}

.btn-outline-secondary {
  color: #2b65d9;
  border-color: #2b65d9;
}
.btn-outline-secondary:hover {
  color: #ffffff;
  background-color: #2b65d9;
  border-color: #2b65d9;
}
.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.25rem rgba(43, 101, 217, 0.5);
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #2b65d9;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #ffffff;
  background-color: #2b65d9;
  border-color: #2b65d9;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(43, 101, 217, 0.5);
}

.btn-outline-success {
  color: #13c265;
  border-color: #13c265;
}
.btn-outline-success:hover {
  color: #ffffff;
  background-color: #13c265;
  border-color: #13c265;
}
.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.25rem rgba(19, 194, 101, 0.5);
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #13c265;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
  color: #ffffff;
  background-color: #13c265;
  border-color: #13c265;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(19, 194, 101, 0.5);
}

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:hover {
  color: #ffffff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.25rem rgba(23, 162, 184, 0.5);
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
  color: #ffffff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
  color: #ef7b45;
  border-color: #ef7b45;
}
.btn-outline-warning:hover {
  color: #ffffff;
  background-color: #ef7b45;
  border-color: #ef7b45;
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.25rem rgba(239, 123, 69, 0.5);
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ef7b45;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
  color: #ffffff;
  background-color: #ef7b45;
  border-color: #ef7b45;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(239, 123, 69, 0.5);
}

.btn-outline-danger {
  color: #eb5042;
  border-color: #eb5042;
}
.btn-outline-danger:hover {
  color: #ffffff;
  background-color: #eb5042;
  border-color: #eb5042;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.25rem rgba(235, 80, 66, 0.5);
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #eb5042;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
  color: #ffffff;
  background-color: #eb5042;
  border-color: #eb5042;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(235, 80, 66, 0.5);
}

.btn-outline-light {
  color: #faf8f7;
  border-color: #faf8f7;
}
.btn-outline-light:hover {
  color: #212529;
  background-color: #faf8f7;
  border-color: #faf8f7;
}
.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.25rem rgba(250, 248, 247, 0.5);
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #faf8f7;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #faf8f7;
  border-color: #faf8f7;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(250, 248, 247, 0.5);
}

.btn-outline-dark {
  color: #1a1817;
  border-color: #1a1817;
}
.btn-outline-dark:hover {
  color: #ffffff;
  background-color: #1a1817;
  border-color: #1a1817;
}
.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.25rem rgba(26, 24, 23, 0.5);
}
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #1a1817;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle {
  color: #ffffff;
  background-color: #1a1817;
  border-color: #1a1817;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(26, 24, 23, 0.5);
}

.btn-outline-accent {
  color: #1493c8;
  border-color: #1493c8;
}
.btn-outline-accent:hover {
  color: #ffffff;
  background-color: #1493c8;
  border-color: #1493c8;
}
.btn-outline-accent:focus, .btn-outline-accent.focus {
  box-shadow: 0 0 0 0.25rem rgba(20, 147, 200, 0.5);
}
.btn-outline-accent.disabled, .btn-outline-accent:disabled {
  color: #1493c8;
  background-color: transparent;
}
.btn-outline-accent:not(:disabled):not(.disabled):active, .btn-outline-accent:not(:disabled):not(.disabled).active, .show > .btn-outline-accent.dropdown-toggle {
  color: #ffffff;
  background-color: #1493c8;
  border-color: #1493c8;
}
.btn-outline-accent:not(:disabled):not(.disabled):active:focus, .btn-outline-accent:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-accent.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(20, 147, 200, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #2b65d9;
  text-decoration: none;
}
.btn-link:hover {
  color: #009952;
  text-decoration: underline;
}
.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
}
.btn-link:disabled, .btn-link.disabled {
  color: #73706f;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 1rem 1rem;
  font-size: 1.125rem;
  line-height: 1.35;
  border-radius: 0.25rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 1rem;
  font-size: 0.875rem;
  line-height: 1.72;
  border-radius: 0.25rem;
}

.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type=submit].btn-block,
input[type=reset].btn-block,
input[type=button].btn-block {
  width: 100%;
}

@media (min-width: 768px) {
  .btn-wide-md {
    min-width: 280px;
  }
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #1a1817;
  text-align: left;
  list-style: none;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropleft .dropdown-toggle::after {
  display: none;
}
.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^=top], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #f5f3f2;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.5rem 1rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: rgb(21.6283783784, 24.25, 26.8716216216);
  text-decoration: none;
  background-color: #f5f3f2;
}
.dropdown-item.active, .dropdown-item:active {
  color: #2b65d9;
  text-decoration: none;
  background-color: #3e9cfa;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #a6a2a1;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #73706f;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.5rem 1rem;
  color: #212529;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}
.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}
.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropright .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: center;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type=radio],
.btn-group-toggle > .btn input[type=checkbox],
.btn-group-toggle > .btn-group > .btn input[type=radio],
.btn-group-toggle > .btn-group > .btn input[type=checkbox] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}
.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -2px;
}
.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-file {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label, .input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group:not(.has-validation) > .form-control:not(:last-child),
.input-group:not(.has-validation) > .custom-select:not(:last-child),
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > .form-control:nth-last-child(n+3),
.input-group.has-validation > .custom-select:nth-last-child(n+3),
.input-group.has-validation > .custom-file:nth-last-child(n+3) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: -ms-flexbox;
  display: flex;
}
.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}
.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -2px;
}

.input-group-prepend {
  margin-right: -2px;
}

.input-group-append {
  margin-left: -2px;
}

.input-group-text {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #1a1817;
  text-align: center;
  white-space: nowrap;
  background-color: #f5f3f2;
  border: 2px solid #e6e4e3;
  border-radius: 4px;
}
.input-group-text input[type=radio],
.input-group-text input[type=checkbox] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.35em + 2rem);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 1rem 1rem;
  font-size: 1.125rem;
  line-height: 1.35;
  border-radius: 4px;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.72em + 0.25rem + 4px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.125rem 1rem;
  font-size: 0.875rem;
  line-height: 1.72;
  border-radius: 4px;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 2rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .btn,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.625rem;
  padding-left: 1.625rem;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
}

.custom-control-inline {
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1.125rem;
  height: 1.375rem;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #13c265;
  border-color: #ccc9c8;
  background-color: #ffffff;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #e6e4e3;
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #ccc9c8;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #13c265;
  background-color: #ffffff;
  border-color: #ccc9c8;
}
.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
  color: #73706f;
}
.custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e6e4e3;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.625rem;
  display: block;
  width: 1.125rem;
  height: 1.125rem;
  pointer-events: none;
  content: "";
  background-color: #ffffff;
  border: #a6a2a1 solid 2px;
}
.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.625rem;
  display: block;
  width: 1.125rem;
  height: 1.125rem;
  content: "";
  background: 50%/100% no-repeat;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 4px;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='10' viewBox='0 -1 12 12'%3E%3Cpath fill='%2313c265' fill-rule='nonzero' d='M2.355 4.463a1.419 1.419 0 0 0-2.006.14 1.43 1.43 0 0 0 .14 2.013L3.968 9.65a1.42 1.42 0 0 0 2.045-.188l5.677-7.146a1.43 1.43 0 0 0-.226-2.005 1.419 1.419 0 0 0-1.998.227L4.714 6.52 2.355 4.463z'/%3E%3C/svg%3E%0A");
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #ffffff;
  background-color: #ffffff;
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-1 0 6 4'%3E%3Cpath stroke='%2313c265' d='M0 2h4'/%3E%3C/svg%3E");
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(19, 194, 101, 0.5);
}
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(19, 194, 101, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2313c265'/%3e%3c/svg%3e");
}
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(19, 194, 101, 0.5);
}

.custom-switch {
  padding-left: 2.46875rem;
}
.custom-switch .custom-control-label::before {
  left: -2.46875rem;
  width: 1.96875rem;
  pointer-events: all;
  border-radius: 0.5625rem;
}
.custom-switch .custom-control-label::after {
  top: calc(0.25rem + 4px);
  left: calc(-2.46875rem + 4px);
  width: calc(1.125rem - 8px);
  height: calc(1.125rem - 8px);
  background-color: #a6a2a1;
  border-radius: 0.5625rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #ffffff;
  -webkit-transform: translateX(0.84375rem);
  transform: translateX(0.84375rem);
}
.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(19, 194, 101, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 1rem + 4px);
  padding: 0.5rem 2rem 0.5rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #1a1817;
  vertical-align: middle;
  background: #ffffff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%231a1817' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 1rem center/8px 10px no-repeat;
  border: 2px solid #e6e4e3;
  border-radius: 0.25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.custom-select:focus {
  border-color: #2b65d9;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(43, 101, 217, 0.25);
}
.custom-select:focus::-ms-value {
  color: #1a1817;
  background-color: #ffffff;
}
.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 1rem;
  background-image: none;
}
.custom-select:disabled {
  color: #73706f;
  background-color: #f5f3f2;
}
.custom-select::-ms-expand {
  display: none;
}
.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #1a1817;
}

.custom-select-sm {
  height: calc(1.72em + 0.25rem + 4px);
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
  padding-left: 1rem;
  font-size: 0.875rem;
}

.custom-select-lg {
  height: calc(1.35em + 2rem);
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
  font-size: 1.125rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 1rem + 4px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 1rem + 4px);
  margin: 0;
  overflow: hidden;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: #2b65d9;
  box-shadow: 0 0 0 0.25rem rgba(43, 101, 217, 0.25);
}
.custom-file-input[disabled] ~ .custom-file-label, .custom-file-input:disabled ~ .custom-file-label {
  background-color: #e6e4e3;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 1rem + 4px);
  padding: 0.5rem 1rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #1a1817;
  background-color: #ffffff;
  border: 2px solid #e6e4e3;
  border-radius: 4px;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 1rem);
  padding: 0.5rem 1rem;
  line-height: 1.5;
  color: #1a1817;
  content: "Browse";
  background-color: #f5f3f2;
  border-left: inherit;
  border-radius: 0 4px 4px 0;
}

.custom-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.custom-range:focus {
  outline: 0;
}
.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #ffffff, 0 0 0 0.25rem rgba(43, 101, 217, 0.25);
}
.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #ffffff, 0 0 0 0.25rem rgba(43, 101, 217, 0.25);
}
.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #ffffff, 0 0 0 0.25rem rgba(43, 101, 217, 0.25);
}
.custom-range::-moz-focus-outer {
  border: 0;
}
.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #2b65d9;
  border: 0;
  border-radius: 1rem;
  -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    -webkit-transition: none;
    transition: none;
  }
}
.custom-range::-webkit-slider-thumb:active {
  background-color: rgb(194.368, 210.956, 244.132);
}
.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #e6e4e3;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #2b65d9;
  border: 0;
  border-radius: 1rem;
  -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -moz-appearance: none;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    -moz-transition: none;
    transition: none;
  }
}
.custom-range::-moz-range-thumb:active {
  background-color: rgb(194.368, 210.956, 244.132);
}
.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #e6e4e3;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.25rem;
  margin-left: 0.25rem;
  background-color: #2b65d9;
  border: 0;
  border-radius: 1rem;
  -ms-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    -ms-transition: none;
    transition: none;
  }
}
.custom-range::-ms-thumb:active {
  background-color: rgb(194.368, 210.956, 244.132);
}
.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}
.custom-range::-ms-fill-lower {
  background-color: #e6e4e3;
  border-radius: 1rem;
}
.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #e6e4e3;
  border-radius: 1rem;
}
.custom-range:disabled::-webkit-slider-thumb {
  background-color: #a6a2a1;
}
.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}
.custom-range:disabled::-moz-range-thumb {
  background-color: #a6a2a1;
}
.custom-range:disabled::-moz-range-track {
  cursor: default;
}
.custom-range:disabled::-ms-thumb {
  background-color: #a6a2a1;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}

.nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 1.5rem 0.75rem;
}
.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}
.nav-link.disabled {
  color: #73706f;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #ccc9c8;
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: transparent;
}
.nav-tabs .nav-link.disabled {
  color: #73706f;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #1a1817;
  background-color: #f5f3f2;
  border-color: #ccc9c8 #ccc9c8 #f5f3f2;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #13c265;
  background-color: #2b65d9;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0 0;
}
.navbar .container, .navbar .hero-container, .navbar .b2c-page-container,
.navbar .container-fluid,
.navbar .container-xl {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.navbar-brand {
  display: inline-block;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-right: 0;
  font-size: 1.125rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.navbar-collapse {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-align: center;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.125rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 4px;
}
.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50%/100% 100% no-repeat;
}

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container, .navbar-expand-sm > .hero-container, .navbar-expand-sm > .b2c-page-container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0;
  }
  .navbar-expand-sm > .container, .navbar-expand-sm > .hero-container, .navbar-expand-sm > .b2c-page-container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-xl {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md > .container, .navbar-expand-md > .hero-container, .navbar-expand-md > .b2c-page-container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0;
  }
  .navbar-expand-md > .container, .navbar-expand-md > .hero-container, .navbar-expand-md > .b2c-page-container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-xl {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg > .container, .navbar-expand-lg > .hero-container, .navbar-expand-lg > .b2c-page-container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0;
  }
  .navbar-expand-lg > .container, .navbar-expand-lg > .hero-container, .navbar-expand-lg > .b2c-page-container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-xl {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container, .navbar-expand-xl > .hero-container, .navbar-expand-xl > .b2c-page-container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0;
  }
  .navbar-expand-xl > .container, .navbar-expand-xl > .hero-container, .navbar-expand-xl > .b2c-page-container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-xl {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
.navbar-expand {
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.navbar-expand > .container, .navbar-expand > .hero-container, .navbar-expand > .b2c-page-container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-xl {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav {
  -ms-flex-direction: row;
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand > .container, .navbar-expand > .hero-container, .navbar-expand > .b2c-page-container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-xl {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: #1a1817;
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: #1a1817;
}
.navbar-light .navbar-nav .nav-link {
  color: #1a1817;
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: #1a1817;
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: #1a1817;
}
.navbar-light .navbar-toggler {
  color: #1a1817;
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath fill='%23364247' fill-rule='nonzero' d='M2.5 6a.5.5 0 1 1 0-1h19a.5.5 0 1 1 0 1h-19zm0 6.5a.5.5 0 0 1 0-1h19a.5.5 0 1 1 0 1h-19zm0 6.5a.5.5 0 1 1 0-1h19a.5.5 0 1 1 0 1h-19z'/%3E%3C/svg%3E%0A");
}
.navbar-light .navbar-text {
  color: #1a1817;
}
.navbar-light .navbar-text a {
  color: #1a1817;
}
.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: #1a1817;
}

.navbar-dark .navbar-brand {
  color: #ffffff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #ffffff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #ffffff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-text a {
  color: #ffffff;
}
.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #ffffff;
}

.card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 4px;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 1rem;
}

.card-subtitle {
  margin-top: -0.5rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 1rem 1.25rem;
  margin-bottom: 0;
  background-color: #ffffff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: 3px 3px 0 0;
}

.card-footer {
  padding: 1rem 1.25rem;
  background-color: #ffffff;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 3px 3px;
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -1rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: 3px;
}

.card-img,
.card-img-top,
.card-img-bottom {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.card-deck .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-deck {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group > .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-group {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
  }
  .card-group > .card {
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 1rem;
}
@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 1.25rem;
    -moz-column-gap: 1.25rem;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion {
  overflow-anchor: none;
}
.accordion > .card {
  overflow: hidden;
}
.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.breadcrumb {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #f5f3f2;
  border-radius: 0.25rem;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #73706f;
  content: url("ata:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNyIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE3IDE2Ij4KICAgIDxwYXRoIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlPSIjNDAzRTNEIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIGQ9Ik0wIDAuNUw2IDYuNSAxMiAwLjUiIHRyYW5zZm9ybT0icm90YXRlKC05MCA5LjUgNC41KSIvPgo8L3N2Zz4");
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}
.breadcrumb-item.active {
  color: #73706f;
}

.pagination {
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.375rem 0.6rem;
  margin-left: 0;
  line-height: 1.2rem;
  color: #1a1817;
  background-color: transparent;
  border: 0 solid transparent;
}
.page-link:hover {
  z-index: 2;
  color: #1a1817;
  text-decoration: none;
  background-color: #f5f3f2;
  border-color: #f5f3f2;
}
.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(43, 101, 217, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.page-item.active .page-link {
  z-index: 3;
  color: #13c265;
  background-color: #2ee674;
  border-color: transparent;
}
.page-item.disabled .page-link {
  color: #73706f;
  pointer-events: none;
  cursor: auto;
  background-color: transparent;
  border-color: #ccc9c8;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.125rem;
  line-height: 1.625;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.42857143;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.badge {
  display: inline-block;
  padding: 3px 10px;
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}
a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #ffffff;
  background-color: #13c265;
}
a.badge-primary:hover, a.badge-primary:focus {
  color: #ffffff;
  background-color: rgb(14.4507042254, 147.5492957746, 76.8169014085);
}
a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(19, 194, 101, 0.5);
}

.badge-secondary {
  color: #ffffff;
  background-color: #2b65d9;
}
a.badge-secondary:hover, a.badge-secondary:focus {
  color: #ffffff;
  background-color: rgb(31.768, 80.256, 177.232);
}
a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(43, 101, 217, 0.5);
}

.badge-success {
  color: #ffffff;
  background-color: #13c265;
}
a.badge-success:hover, a.badge-success:focus {
  color: #ffffff;
  background-color: rgb(14.4507042254, 147.5492957746, 76.8169014085);
}
a.badge-success:focus, a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(19, 194, 101, 0.5);
}

.badge-info {
  color: #ffffff;
  background-color: #17a2b8;
}
a.badge-info:hover, a.badge-info:focus {
  color: #ffffff;
  background-color: rgb(17.3333333333, 122.0869565217, 138.6666666667);
}
a.badge-info:focus, a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(23, 162, 184, 0.5);
}

.badge-warning {
  color: #ffffff;
  background-color: #ef7b45;
}
a.badge-warning:hover, a.badge-warning:focus {
  color: #ffffff;
  background-color: rgb(234.9603960396, 89.6732673267, 22.0396039604);
}
a.badge-warning:focus, a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(239, 123, 69, 0.5);
}

.badge-danger {
  color: #ffffff;
  background-color: #eb5042;
}
a.badge-danger:hover, a.badge-danger:focus {
  color: #ffffff;
  background-color: rgb(226.0765550239, 40.6698564593, 23.9234449761);
}
a.badge-danger:focus, a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(235, 80, 66, 0.5);
}

.badge-light {
  color: #212529;
  background-color: #faf8f7;
}
a.badge-light:hover, a.badge-light:focus {
  color: #212529;
  background-color: rgb(230.3846153846, 220.5384615385, 215.6153846154);
}
a.badge-light:focus, a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(250, 248, 247, 0.5);
}

.badge-dark {
  color: #ffffff;
  background-color: #1a1817;
}
a.badge-dark:hover, a.badge-dark:focus {
  color: #ffffff;
  background-color: black;
}
a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(26, 24, 23, 0.5);
}

.badge-accent {
  color: #ffffff;
  background-color: #1493c8;
}
a.badge-accent:hover, a.badge-accent:focus {
  color: #ffffff;
  background-color: rgb(15.3636363636, 112.9227272727, 153.6363636364);
}
a.badge-accent:focus, a.badge-accent.focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(20, 147, 200, 0.5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #2ee674;
  border-radius: 0.25rem;
}
@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: rgb(9.88, 100.88, 52.52);
  background-color: rgb(207.8, 242.8, 224.2);
  border-color: rgb(188.92, 237.92, 211.88);
}
.alert-primary hr {
  border-top-color: rgb(168.6573737374, 232.6826262626, 198.6577777778);
}
.alert-primary .alert-link {
  color: rgb(5.3307042254, 54.4292957746, 28.3369014085);
}

.alert-secondary {
  color: rgb(22.36, 52.52, 112.84);
  background-color: rgb(212.6, 224.2, 247.4);
  border-color: rgb(195.64, 211.88, 244.36);
}
.alert-secondary hr {
  border-top-color: rgb(174.016, 196.172, 240.484);
}
.alert-secondary .alert-link {
  color: rgb(13.9253846154, 32.7084615385, 70.2746153846);
}

.alert-success {
  color: rgb(9.88, 100.88, 52.52);
  background-color: rgb(207.8, 242.8, 224.2);
  border-color: rgb(188.92, 237.92, 211.88);
}
.alert-success hr {
  border-top-color: rgb(168.6573737374, 232.6826262626, 198.6577777778);
}
.alert-success .alert-link {
  color: rgb(5.3307042254, 54.4292957746, 28.3369014085);
}

.alert-info {
  color: rgb(11.96, 84.24, 95.68);
  background-color: rgb(208.6, 236.4, 240.8);
  border-color: rgb(190.04, 228.96, 235.12);
}
.alert-info hr {
  border-top-color: rgb(170.5152475248, 221.1332673267, 229.1447524752);
}
.alert-info .alert-link {
  color: rgb(6.2933333333, 44.3269565217, 50.3466666667);
}

.alert-warning {
  color: rgb(124.28, 63.96, 35.88);
  background-color: rgb(251.8, 228.6, 217.8);
  border-color: rgb(250.52, 218.04, 202.92);
}
.alert-warning hr {
  border-top-color: rgb(248.5001980198, 201.3766336634, 179.4398019802);
}
.alert-warning .alert-link {
  color: rgb(84.7053246753, 43.5931168831, 24.4546753247);
}

.alert-danger {
  color: rgb(122.2, 41.6, 34.32);
  background-color: rgb(251, 220, 217.2);
  border-color: rgb(249.4, 206, 202.08);
}
.alert-danger hr {
  border-top-color: rgb(246.9598086124, 184.6483253589, 179.0201913876);
}
.alert-danger .alert-link {
  color: rgb(82.3827242525, 28.0451827243, 23.1372757475);
}

.alert-light {
  color: rgb(130, 128.96, 128.44);
  background-color: rgb(254, 253.6, 253.4);
  border-color: rgb(253.6, 253.04, 252.76);
}
.alert-light hr {
  border-top-color: rgb(243.7923076923, 239.3092307692, 237.0676923077);
}
.alert-light .alert-link {
  color: rgb(104.3631992368, 103.5056002544, 103.0768007632);
}

.alert-dark {
  color: rgb(13.52, 12.48, 11.96);
  background-color: rgb(209.2, 208.8, 208.6);
  border-color: rgb(190.88, 190.32, 190.04);
}
.alert-dark hr {
  border-top-color: rgb(178.2129718004, 177.5423427332, 177.2070281996);
}
.alert-dark .alert-link {
  color: black;
}

.alert-accent {
  color: rgb(10.4, 76.44, 104);
  background-color: rgb(208, 233.4, 244);
  border-color: rgb(189.2, 224.76, 239.6);
}
.alert-accent hr {
  border-top-color: rgb(168.5362068966, 215.2634482759, 234.7637931034);
}
.alert-accent .alert-link {
  color: rgb(5.7636363636, 42.3627272727, 57.6363636364);
}

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 2px 0;
  }
  to {
    background-position: 0 0;
  }
}

@keyframes progress-bar-stripes {
  from {
    background-position: 2px 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  display: -ms-flexbox;
  display: flex;
  height: 2px;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #f5f3f2;
  border-radius: 0.25rem;
}

.progress-bar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  overflow: hidden;
  color: #ffffff;
  text-align: center;
  white-space: nowrap;
  background-color: #13c265;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 2px 2px;
}

.progress-bar-animated {
  -webkit-animation: 1s linear infinite progress-bar-stripes;
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    -webkit-animation: none;
    animation: none;
  }
}

.media {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
}

.media-body {
  -ms-flex: 1;
  flex: 1;
}

.list-group {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}

.list-group-item-action {
  width: 100%;
  color: #403e3d;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #403e3d;
  text-decoration: none;
  background-color: #faf8f7;
}
.list-group-item-action:active {
  color: #1a1817;
  background-color: #f5f3f2;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #73706f;
  pointer-events: none;
  background-color: #ffffff;
}
.list-group-item.active {
  z-index: 2;
  color: #13c265;
  background-color: #2b65d9;
  border-color: #2b65d9;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  -ms-flex-direction: row;
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: rgb(9.88, 100.88, 52.52);
  background-color: rgb(188.92, 237.92, 211.88);
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: rgb(9.88, 100.88, 52.52);
  background-color: rgb(168.6573737374, 232.6826262626, 198.6577777778);
}
.list-group-item-primary.list-group-item-action.active {
  color: #ffffff;
  background-color: rgb(9.88, 100.88, 52.52);
  border-color: rgb(9.88, 100.88, 52.52);
}

.list-group-item-secondary {
  color: rgb(22.36, 52.52, 112.84);
  background-color: rgb(195.64, 211.88, 244.36);
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: rgb(22.36, 52.52, 112.84);
  background-color: rgb(174.016, 196.172, 240.484);
}
.list-group-item-secondary.list-group-item-action.active {
  color: #ffffff;
  background-color: rgb(22.36, 52.52, 112.84);
  border-color: rgb(22.36, 52.52, 112.84);
}

.list-group-item-success {
  color: rgb(9.88, 100.88, 52.52);
  background-color: rgb(188.92, 237.92, 211.88);
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: rgb(9.88, 100.88, 52.52);
  background-color: rgb(168.6573737374, 232.6826262626, 198.6577777778);
}
.list-group-item-success.list-group-item-action.active {
  color: #ffffff;
  background-color: rgb(9.88, 100.88, 52.52);
  border-color: rgb(9.88, 100.88, 52.52);
}

.list-group-item-info {
  color: rgb(11.96, 84.24, 95.68);
  background-color: rgb(190.04, 228.96, 235.12);
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: rgb(11.96, 84.24, 95.68);
  background-color: rgb(170.5152475248, 221.1332673267, 229.1447524752);
}
.list-group-item-info.list-group-item-action.active {
  color: #ffffff;
  background-color: rgb(11.96, 84.24, 95.68);
  border-color: rgb(11.96, 84.24, 95.68);
}

.list-group-item-warning {
  color: rgb(124.28, 63.96, 35.88);
  background-color: rgb(250.52, 218.04, 202.92);
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: rgb(124.28, 63.96, 35.88);
  background-color: rgb(248.5001980198, 201.3766336634, 179.4398019802);
}
.list-group-item-warning.list-group-item-action.active {
  color: #ffffff;
  background-color: rgb(124.28, 63.96, 35.88);
  border-color: rgb(124.28, 63.96, 35.88);
}

.list-group-item-danger {
  color: rgb(122.2, 41.6, 34.32);
  background-color: rgb(249.4, 206, 202.08);
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: rgb(122.2, 41.6, 34.32);
  background-color: rgb(246.9598086124, 184.6483253589, 179.0201913876);
}
.list-group-item-danger.list-group-item-action.active {
  color: #ffffff;
  background-color: rgb(122.2, 41.6, 34.32);
  border-color: rgb(122.2, 41.6, 34.32);
}

.list-group-item-light {
  color: rgb(130, 128.96, 128.44);
  background-color: rgb(253.6, 253.04, 252.76);
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: rgb(130, 128.96, 128.44);
  background-color: rgb(243.7923076923, 239.3092307692, 237.0676923077);
}
.list-group-item-light.list-group-item-action.active {
  color: #ffffff;
  background-color: rgb(130, 128.96, 128.44);
  border-color: rgb(130, 128.96, 128.44);
}

.list-group-item-dark {
  color: rgb(13.52, 12.48, 11.96);
  background-color: rgb(190.88, 190.32, 190.04);
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: rgb(13.52, 12.48, 11.96);
  background-color: rgb(178.2129718004, 177.5423427332, 177.2070281996);
}
.list-group-item-dark.list-group-item-action.active {
  color: #ffffff;
  background-color: rgb(13.52, 12.48, 11.96);
  border-color: rgb(13.52, 12.48, 11.96);
}

.list-group-item-accent {
  color: rgb(10.4, 76.44, 104);
  background-color: rgb(189.2, 224.76, 239.6);
}
.list-group-item-accent.list-group-item-action:hover, .list-group-item-accent.list-group-item-action:focus {
  color: rgb(10.4, 76.44, 104);
  background-color: rgb(168.5362068966, 215.2634482759, 234.7637931034);
}
.list-group-item-accent.list-group-item-action.active {
  color: #ffffff;
  background-color: rgb(10.4, 76.44, 104);
  border-color: rgb(10.4, 76.44, 104);
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #1a1817;
  text-shadow: 0 1px 0 #ffffff;
  opacity: 0.5;
}
.close:hover {
  color: #1a1817;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(0, -50px);
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  -webkit-transform: none;
  transform: none;
}
.modal.modal-static .modal-dialog {
  -webkit-transform: scale(1.02);
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: -ms-flexbox;
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.25rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #403e3d;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #e6e4e3;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.625;
}

.modal-body {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1.25rem;
}

.modal-footer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #e6e4e3;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: -webkit-min-content;
    height: -moz-min-content;
    height: min-content;
  }
  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 1140px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: hkgrotesk;
  font-style: normal;
  font-weight: 400;
  line-height: 1.625;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^=top] .arrow {
  bottom: 0;
}
.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^=top] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000000;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^=right] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^=right] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^=bottom] .arrow {
  top: 0;
}
.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000000;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^=left] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^=left] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #ffffff;
  text-align: center;
  background-color: #000000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: hkgrotesk;
  font-style: normal;
  font-weight: 400;
  line-height: 1.625;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.25rem;
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.25rem;
}
.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^=top] {
  margin-bottom: 0.5rem;
}
.bs-popover-top > .arrow, .bs-popover-auto[x-placement^=top] > .arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^=top] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^=top] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #ffffff;
}

.bs-popover-right, .bs-popover-auto[x-placement^=right] {
  margin-left: 0.5rem;
}
.bs-popover-right > .arrow, .bs-popover-auto[x-placement^=right] > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.25rem 0;
}
.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^=right] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^=right] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #ffffff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^=bottom] {
  margin-top: 0.5rem;
}
.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^=bottom] > .arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^=bottom] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^=bottom] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #ffffff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid rgb(247.35, 247.35, 247.35);
}

.bs-popover-left, .bs-popover-auto[x-placement^=left] {
  margin-right: 0.5rem;
}
.bs-popover-left > .arrow, .bs-popover-auto[x-placement^=left] > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.25rem 0;
}
.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^=left] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^=left] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #ffffff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: #1a1817;
  background-color: rgb(247.35, 247.35, 247.35);
  border-bottom: 1px solid rgb(234.6, 234.6, 234.6);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #1a1817;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: -webkit-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  -webkit-transform: none;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 15%;
  color: #ffffff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #ffffff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50%/100% 100% no-repeat;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ffffff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ffffff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
  box-sizing: content-box;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #ffffff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #ffffff;
  text-align: center;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #13c265 !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: rgb(14.4507042254, 147.5492957746, 76.8169014085) !important;
}

.bg-secondary {
  background-color: #2b65d9 !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: rgb(31.768, 80.256, 177.232) !important;
}

.bg-success {
  background-color: #13c265 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: rgb(14.4507042254, 147.5492957746, 76.8169014085) !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: rgb(17.3333333333, 122.0869565217, 138.6666666667) !important;
}

.bg-warning {
  background-color: #ef7b45 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: rgb(234.9603960396, 89.6732673267, 22.0396039604) !important;
}

.bg-danger {
  background-color: #eb5042 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: rgb(226.0765550239, 40.6698564593, 23.9234449761) !important;
}

.bg-light {
  background-color: #faf8f7 !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: rgb(230.3846153846, 220.5384615385, 215.6153846154) !important;
}

.bg-dark {
  background-color: #1a1817 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: black !important;
}

.bg-accent {
  background-color: #1493c8 !important;
}

a.bg-accent:hover, a.bg-accent:focus,
button.bg-accent:hover,
button.bg-accent:focus {
  background-color: rgb(15.3636363636, 112.9227272727, 153.6363636364) !important;
}

.bg-white {
  background-color: #ffffff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #e6e4e3 !important;
}

.border-top {
  border-top: 1px solid #e6e4e3 !important;
}

.border-right {
  border-right: 1px solid #e6e4e3 !important;
}

.border-bottom {
  border-bottom: 1px solid #e6e4e3 !important;
}

.border-left {
  border-left: 1px solid #e6e4e3 !important;
}

.border-0, .postcode-hero-form-container .postcode-hero-panel {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #13c265 !important;
}

.border-secondary {
  border-color: #2b65d9 !important;
}

.border-success {
  border-color: #13c265 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ef7b45 !important;
}

.border-danger {
  border-color: #eb5042 !important;
}

.border-light {
  border-color: #faf8f7 !important;
}

.border-dark {
  border-color: #1a1817 !important;
}

.border-accent {
  border-color: #1493c8 !important;
}

.border-white {
  border-color: #ffffff !important;
}

.rounded-sm {
  border-radius: 0.25rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.25rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex, .company-logo-container-wrapper .company-logo-container {
  display: -ms-flexbox !important;
  display: flex !important;
}

.d-inline-flex {
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-md-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-print-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.8571428571%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  -ms-flex-direction: row !important;
  flex-direction: row !important;
}

.flex-column {
  -ms-flex-direction: column !important;
  flex-direction: column !important;
}

.flex-row-reverse {
  -ms-flex-direction: row-reverse !important;
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  -ms-flex-direction: column-reverse !important;
  flex-direction: column-reverse !important;
}

.flex-wrap {
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
}

.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  -ms-flex: 1 1 auto !important;
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  -ms-flex-positive: 0 !important;
  flex-grow: 0 !important;
}

.flex-grow-1 {
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  -ms-flex-negative: 0 !important;
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  -ms-flex-negative: 1 !important;
  flex-shrink: 1 !important;
}

.justify-content-start {
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
}

.justify-content-end {
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
}

.justify-content-center, .company-logo-container-wrapper .company-logo-container {
  -ms-flex-pack: center !important;
  justify-content: center !important;
}

.justify-content-between {
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}

.justify-content-around {
  -ms-flex-pack: distribute !important;
  justify-content: space-around !important;
}

.align-items-start {
  -ms-flex-align: start !important;
  align-items: flex-start !important;
}

.align-items-end {
  -ms-flex-align: end !important;
  align-items: flex-end !important;
}

.align-items-center, .company-logo-container-wrapper .company-logo-container {
  -ms-flex-align: center !important;
  align-items: center !important;
}

.align-items-baseline {
  -ms-flex-align: baseline !important;
  align-items: baseline !important;
}

.align-items-stretch {
  -ms-flex-align: stretch !important;
  align-items: stretch !important;
}

.align-content-start {
  -ms-flex-line-pack: start !important;
  align-content: flex-start !important;
}

.align-content-end {
  -ms-flex-line-pack: end !important;
  align-content: flex-end !important;
}

.align-content-center {
  -ms-flex-line-pack: center !important;
  align-content: center !important;
}

.align-content-between {
  -ms-flex-line-pack: justify !important;
  align-content: space-between !important;
}

.align-content-around {
  -ms-flex-line-pack: distribute !important;
  align-content: space-around !important;
}

.align-content-stretch {
  -ms-flex-line-pack: stretch !important;
  align-content: stretch !important;
}

.align-self-auto {
  -ms-flex-item-align: auto !important;
  align-self: auto !important;
}

.align-self-start {
  -ms-flex-item-align: start !important;
  align-self: flex-start !important;
}

.align-self-end {
  -ms-flex-item-align: end !important;
  align-self: flex-end !important;
}

.align-self-center {
  -ms-flex-item-align: center !important;
  align-self: center !important;
}

.align-self-baseline {
  -ms-flex-item-align: baseline !important;
  align-self: baseline !important;
}

.align-self-stretch {
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-sm-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-sm-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-sm-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-sm-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-sm-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-sm-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-sm-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-sm-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row, .static-field {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-md-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-md-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-md-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-md-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-md-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-md-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-md-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-md-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-md-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-md-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-md-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-md-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-md-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-md-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-md-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-md-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-lg-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-lg-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-lg-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-lg-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-lg-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-lg-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-lg-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-lg-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-xl-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-xl-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-xl-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-xl-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-xl-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-xl-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-xl-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-xl-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}
.user-select-all {
  -webkit-user-select: all !important;
  -moz-user-select: all !important;
  user-select: all !important;
}

.user-select-auto {
  -webkit-user-select: auto !important;
  -moz-user-select: auto !important;
  -ms-user-select: auto !important;
  user-select: auto !important;
}

.user-select-none {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0.5rem 0.5rem 0 0 rgba(51, 20, 5, 0.03) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100, .menu-links .menu-nav li a {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.w-33 {
  width: 33% !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.h-33 {
  height: 33% !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase, .upper {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #ffffff !important;
}

.text-primary {
  color: #13c265 !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: rgb(12.176056338, 124.323943662, 64.7253521127) !important;
}

.text-secondary {
  color: #2b65d9 !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: rgb(27.892, 70.464, 155.608) !important;
}

.text-success {
  color: #13c265 !important;
}

a.text-success:hover, a.text-success:focus {
  color: rgb(12.176056338, 124.323943662, 64.7253521127) !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: rgb(14.5, 102.1304347826, 116) !important;
}

.text-warning {
  color: #ef7b45 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: rgb(213.1633663366, 80.2227722772, 18.3366336634) !important;
}

.text-danger {
  color: #eb5042 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: rgb(203.0167464115, 36.5215311005, 21.4832535885) !important;
}

.text-light {
  color: #faf8f7 !important;
}

a.text-light:hover, a.text-light:focus {
  color: rgb(220.5769230769, 206.8076923077, 199.9230769231) !important;
}

.text-dark {
  color: #1a1817 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: black !important;
}

.text-accent {
  color: #1493c8 !important;
}

a.text-accent:hover, a.text-accent:focus {
  color: rgb(13.0454545455, 95.8840909091, 130.4545454545) !important;
}

.text-body {
  color: #1a1817 !important;
}

.text-muted {
  color: #73706f !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #a6a2a1;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container, .hero-container, .b2c-page-container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #ffffff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #e6e4e3 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #e6e4e3;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #e6e4e3;
  }
}
/**
  Custom SASS mixins
 */
/**
  Custom SASS files
 */
.border-light {
  border: 1px solid #e6e4e3 !important;
}

.border-bold {
  border-width: 2px !important;
}

.border-radius-bottom {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.border-radius, .form-control.form-control-hero {
  border-radius: 4px;
}

.border-radius-top {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.border-shadow {
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.08), 0 0 4px 0 rgba(0, 0, 0, 0.16);
}

.border-bottom-last:last-of-type {
  border-bottom: 1px solid #e6e4e3;
}

.border-bottom-md-right {
  border-bottom: 1px solid #e6e4e3;
}
@media (min-width: 768px) {
  .border-bottom-md-right {
    border-right: 1px solid #e6e4e3;
    border-bottom: none;
  }
}

@media (min-width: 768px) {
  .border-right-odd:nth-child(odd) {
    border-right: 1px solid #e6e4e3;
  }
}

.border-dashed-gray-100 {
  border: 2px dashed #faf8f7;
}

.border-dashed-gray-200 {
  border: 2px dashed #f5f3f2;
}

.border-dashed-gray-300 {
  border: 2px dashed #e6e4e3;
}

.border-dashed-gray-400 {
  border: 2px dashed #ccc9c8;
}

.border-dashed-gray-500 {
  border: 2px dashed #a6a2a1;
}

.border-dashed-gray-600 {
  border: 2px dashed #73706f;
}

.border-dashed-gray-700 {
  border: 2px dashed #403e3d;
}

.border-dashed-gray-800 {
  border: 2px dashed #1a1817;
}

.border-dashed-gray-900 {
  border: 2px dashed #212529;
}

.border-dashed-gray-150 {
  border: 2px dashed #f5f3f2;
}

.outline-primary-bold {
  outline: 2px solid #13c265 !important;
}

.border-none {
  border-style: none !important;
}

@media (min-width: 576px) {
  .border-sm-none {
    border-style: none !important;
  }
}
@media (min-width: 768px) {
  .border-md-none {
    border-style: none !important;
  }
}
@media (min-width: 992px) {
  .border-lg-none {
    border-style: none !important;
  }
}
@media (min-width: 1200px) {
  .border-xl-none {
    border-style: none !important;
  }
}
.bg-accent-light {
  background-color: rgb(161, 211.8, 233) !important;
}

.bg-accent-lighter {
  background-color: rgb(208, 233.4, 244) !important;
}

.color-accent {
  color: #1493c8 !important;
}

.color-accent-light {
  color: rgb(161, 211.8, 233) !important;
}

.color-accent-lighter {
  color: rgb(208, 233.4, 244) !important;
}

.bg-primary-light {
  background-color: #2ee674 !important;
}

.bg-primary-lighter {
  background-color: rgb(231.4, 248.9, 239.6) !important;
}

.color-white {
  color: #ffffff !important;
}

.btn-light:hover {
  background-color: #13c265;
}
.btn-light:hover.text-muted {
  color: #ffffff !important;
}

/**
The styles below are required to style the ui-bootstrap datepicker directive
This directive adds bootstrap 3 classes, so we add these custom styles to match the design with our styleguide
These styles will support both ui-bootstrap versions 0.x and 2.x
 */
.uib-datepicker-popup * {
  outline: none;
}

.uib-datepicker-popup,
div[datepicker] {
  overflow: hidden;
  border: 1px solid #e6e4e3;
  box-shadow: 0 0 8px 0 #e6e4e3;
}
.uib-datepicker-popup .uib-daypicker,
div[datepicker] .uib-daypicker {
  background-color: #faf8f7;
}
.uib-datepicker-popup.dropdown-menu,
div[datepicker].dropdown-menu {
  min-width: 0;
}
.uib-datepicker-popup table,
div[datepicker] table {
  width: 100%;
  font-size: 0.875rem;
}
.uib-datepicker-popup tbody::after,
div[datepicker] tbody::after {
  display: inline-block;
  width: 100%;
  height: 20px;
}
.uib-datepicker-popup .active,
div[datepicker] .active {
  background-color: #f5f3f2;
}
.uib-datepicker-popup tr > td:first-child,
div[datepicker] tr > td:first-child {
  font-size: 0.875rem;
  color: #403e3d;
  background-color: #faf8f7;
}
.uib-datepicker-popup tr small,
div[datepicker] tr small {
  font-size: 100%;
}
.uib-datepicker-popup tr em,
div[datepicker] tr em {
  font-style: normal;
}
.uib-datepicker-popup thead *,
div[datepicker] thead * {
  padding: 0;
  color: #1a1817;
  border: none;
}
.uib-datepicker-popup thead tr:first-child,
div[datepicker] thead tr:first-child {
  background-color: #ffffff;
  border-bottom: 1.5px solid #e6e4e3;
}
.uib-datepicker-popup thead tr:last-child th,
div[datepicker] thead tr:last-child th {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
}
.uib-datepicker-popup .btn,
div[datepicker] .btn {
  padding: 0.375rem 0.45rem;
  border: none;
  border-radius: 0;
  box-shadow: none;
  color: #1a1817;
}
.uib-datepicker-popup .btn:not(.active),
div[datepicker] .btn:not(.active) {
  background-color: #ffffff;
}
.uib-datepicker-popup .btn.uib-title, .uib-datepicker-popup .btn[role=heading],
div[datepicker] .btn.uib-title,
div[datepicker] .btn[role=heading] {
  padding: 0.75rem 0 0.75rem 0;
  border: none;
}
.uib-datepicker-popup .btn.uib-left, .uib-datepicker-popup .btn.pull-left,
div[datepicker] .btn.uib-left,
div[datepicker] .btn.pull-left {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.uib-datepicker-popup .btn .text-muted,
div[datepicker] .btn .text-muted {
  color: #a6a2a1;
}
.uib-datepicker-popup .btn:not(:disabled):not(.uib-title):hover,
div[datepicker] .btn:not(:disabled):not(.uib-title):hover {
  color: #ffffff;
  background-color: #13c265;
}
.uib-datepicker-popup .btn:not(:disabled):not(.uib-title):hover .text-muted,
div[datepicker] .btn:not(:disabled):not(.uib-title):hover .text-muted {
  color: #ffffff !important;
}
.uib-datepicker-popup .btn span,
div[datepicker] .btn span {
  background-color: transparent;
}
.uib-datepicker-popup .btn.uib-left, .uib-datepicker-popup .btn.pull-left, .uib-datepicker-popup .btn.uib-right, .uib-datepicker-popup .btn.pull-right,
div[datepicker] .btn.uib-left,
div[datepicker] .btn.pull-left,
div[datepicker] .btn.uib-right,
div[datepicker] .btn.pull-right {
  width: 30px;
  height: 23px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3E%3Cpath fill='%2313c265' fill-rule='nonzero' d='M3.645 1.284a.455.455 0 0 1 .71-.568l4 5a.455.455 0 0 1 0 .568l-4 5a.455.455 0 0 1-.71-.568L7.418 6 3.645 1.284z'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position-x: 0.8rem;
  background-position-y: 0.35rem;
  border: none;
}
.uib-datepicker-popup .btn.uib-left:not(:disabled):hover, .uib-datepicker-popup .btn.pull-left:not(:disabled):hover, .uib-datepicker-popup .btn.uib-right:not(:disabled):hover, .uib-datepicker-popup .btn.pull-right:not(:disabled):hover,
div[datepicker] .btn.uib-left:not(:disabled):hover,
div[datepicker] .btn.pull-left:not(:disabled):hover,
div[datepicker] .btn.uib-right:not(:disabled):hover,
div[datepicker] .btn.pull-right:not(:disabled):hover {
  color: #ffffff !important;
  background-color: transparent;
}
.uib-datepicker-popup .btn.uib-left:active, .uib-datepicker-popup .btn.pull-left:active, .uib-datepicker-popup .btn.uib-right:active, .uib-datepicker-popup .btn.pull-right:active,
div[datepicker] .btn.uib-left:active,
div[datepicker] .btn.pull-left:active,
div[datepicker] .btn.uib-right:active,
div[datepicker] .btn.pull-right:active {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3E%3Cpath fill='%2313c265' fill-rule='nonzero' d='M3.645 1.284a.455.455 0 0 1 .71-.568l4 5a.455.455 0 0 1 0 .568l-4 5a.455.455 0 0 1-.71-.568L7.418 6 3.645 1.284z'/%3E%3C/svg%3E%0A");
}
.uib-datepicker-popup .btn.uib-left, .uib-datepicker-popup .btn.pull-left,
div[datepicker] .btn.uib-left,
div[datepicker] .btn.pull-left {
  margin-left: 0.25rem;
}
.uib-datepicker-popup .btn.disabled,
.uib-datepicker-popup .btn:disabled,
div[datepicker] .btn.disabled,
div[datepicker] .btn:disabled {
  cursor: not-allowed;
  background-color: #e6e6e6;
}
.uib-datepicker-popup .btn-info:not(:disabled):not(.disabled).active,
div[datepicker] .btn-info:not(:disabled):not(.disabled).active {
  color: #ffffff;
  background-color: #13c265;
}

.flex-basis-0 {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
}

.flex-basis-100 {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
}

.justify-content-space-between {
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.horizontal-flex-scroll-container {
  display: block;
  width: calc(100% + 2rem);
  margin: 0 -1rem;
  overflow: hidden;
}
.horizontal-flex-scroll-container .horizontal-flex-scroll {
  display: -ms-flexbox;
  display: flex;
  gap: 0.5rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  max-width: 100%;
  padding: 0 1rem;
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  -webkit-scroll-snap-type: x proximity;
  -ms-scroll-snap-type: x proximity;
  scroll-snap-type: x proximity;
  -webkit-overflow-scrolling: touch;
}
.horizontal-flex-scroll-container .horizontal-flex-scroll > * {
  scroll-snap-align: center;
  scroll-snap-stop: always;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  margin: auto;
}
.horizontal-flex-scroll-container .horizontal-flex-scroll::-webkit-scrollbar {
  display: none;
}

/**
  Dropdown multiselect
 */
.dropdown-select .btn {
  position: relative;
  min-height: 2.125rem;
  padding: 0 1.5rem 0 0.5rem;
  line-height: 1;
}
.dropdown-select .btn.disabled {
  background-color: #f5f3f2;
  border-color: rgba(230, 228, 227, 0.5);
  opacity: 1;
}
.dropdown-select .btn .badge {
  top: 0;
}
.dropdown-select .btn::after {
  position: absolute;
  top: calc(50% - 0.21875rem);
  right: 0.375rem;
}
.dropdown-select .select-label {
  color: #a6a2a1;
}
.show .dropdown-select .select-label {
  color: #1a1817;
}
.dropdown-select .dropdown-menu {
  font-size: 0.875rem;
}
.dropdown-select .dropdown-item {
  padding: 0.4375rem 0.5rem;
  line-height: 1;
}

.custom-control-inline {
  padding-left: 0.8125rem;
}
.custom-control-inline.custom-radio .custom-control-label {
  padding: 0 1rem;
}
.custom-control-inline.custom-radio .custom-control-label::after {
  margin: 0 0.7rem;
}
.custom-control-inline.custom-radio .custom-control-label::before {
  margin: 0 0.7rem;
}

.custom-control-input + label, .custom-control-input + legend {
  font-weight: 400;
}

.custom-control-label {
  font-size: 0.875rem;
  line-height: 1.5rem;
}
.custom-control-label.checkbox {
  width: 100%;
  padding: 0.75rem;
  margin-bottom: 0;
}
.custom-control-label.checkbox::after, .custom-control-label.checkbox::before {
  margin: 0.75rem;
}
.custom-control-label .custom-control-sub-label {
  font-family: "ProximaNova";
  font-size: 14px;
  font-weight: 400;
  color: #73706f;
}
.custom-control-label .custom-control-sub-label.error {
  color: #eb5042;
}

.custom-control-input ~ .custom-control-label::before,
.custom-control-input:focus ~ .custom-control-label::before {
  border: 1px solid #ccc9c8;
  box-shadow: none;
}

.custom-radio .custom-control-label,
.custom-checkbox .custom-control-label {
  width: 100%;
  color: #1a1817;
}

.custom-radio .custom-control-label {
  width: 100%;
  padding: 0.7rem 1rem;
  font-size: 0.875rem;
}
.custom-radio .custom-control-label::after {
  margin: 0.7rem;
}
.custom-radio .custom-control-label::before {
  margin: 0.7rem;
}

.custom-radio .custom-control-input:active:not(:disabled) ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%2313c265'/%3E%3C/svg%3E");
}

.custom-radio .custom-control-input:checked:disabled ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%232ee674'/%3E%3C/svg%3E");
}

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: #ffffff;
}

.custom-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.ngb-dp-weekday.small {
  color: #1a1817;
}

.label-sm {
  margin-bottom: 0.25rem;
  font-size: 0.875rem;
  line-height: 1.42857143;
}

label, legend {
  font-family: "SofiaPro";
  font-size: 1rem;
  font-weight: 700;
  color: #403e3d;
}
label .help, legend .help {
  font-size: 12px;
  font-weight: 400;
}

.label-lg {
  font-size: 1.125rem;
}

.form-control:focus {
  color: #1a1817;
  box-shadow: 0 0 4px 0 #1493c8;
}

.custom-control-input:checked + label, .custom-control-input:checked + legend {
  font-weight: 700;
}

.sd-btn-checkbox {
  display: inline-block;
  width: 305px;
  max-width: 100%;
  padding: 0;
  text-align: left;
  background-color: #ffffff;
  border: 1px solid #e6e4e3;
  border-radius: 0.25rem;
  box-shadow: none;
}
.sd-btn-checkbox:not(.disabled):hover {
  border-color: #73706f;
}
.sd-btn-checkbox label:hover, .sd-btn-checkbox legend:hover {
  cursor: pointer;
}

.sd-radio-list,
.sd-checkbox-list {
  border-top: 1px solid #e6e4e3;
}

.sd-radio-row,
.sd-checkbox-row {
  font-size: 0.875rem;
  border-bottom: 1px solid #e6e4e3;
}
.sd-radio-row:hover,
.sd-checkbox-row:hover {
  background-color: #f5f3f2;
}
.sd-radio-row .custom-control-input:checked + label, .sd-radio-row .custom-control-input:checked + legend,
.sd-checkbox-row .custom-control-input:checked + label,
.sd-checkbox-row .custom-control-input:checked + legend {
  font-weight: 700;
}
.sd-radio-row label, .sd-radio-row legend,
.sd-checkbox-row label,
.sd-checkbox-row legend {
  font-weight: 400;
}
.sd-radio-row label:hover, .sd-radio-row legend:hover,
.sd-checkbox-row label:hover,
.sd-checkbox-row legend:hover {
  cursor: pointer;
}
.sd-radio-row.sd-radio-row-bordered,
.sd-checkbox-row.sd-radio-row-bordered {
  margin-bottom: 0.5rem;
  border: 1px solid #e6e4e3;
  border-radius: 0.25rem;
}
.sd-radio-row.sd-radio-row-bordered.active,
.sd-checkbox-row.sd-radio-row-bordered.active {
  border: 2px solid #13c265;
}

.is-invalid:not(input) {
  color: #eb5042;
}

.is-invalid {
  border-color: #eb5042;
}

.form-group.error label, .form-group.error legend {
  color: #eb5042;
}
.form-group.error input,
.form-group.error textarea {
  border-color: #eb5042;
}
.form-group.error .error-message {
  font-size: 0.875rem;
  color: #eb5042;
}

.form-control-plaintext .form-group {
  margin-bottom: 0;
  font-size: 0.875rem;
}
.form-control-plaintext .col-form-label {
  color: #1a1817;
  text-align: right;
  background-color: #e6e4e3;
  border-right: 1px solid #ccc9c8;
}

.static-field {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 12px 0;
  margin: 0 16px;
}
.static-field:not(:last-of-type) {
  border-bottom: 1px solid #e6e4e3;
}
@media (min-width: 768px) {
  .static-field {
    padding: 10px 0;
  }
}
.static-field label, .static-field legend {
  margin-bottom: 2px;
  font-size: 12px;
  line-height: 1.33;
}
@media (min-width: 768px) {
  .static-field label, .static-field legend {
    margin-bottom: 0;
    font-size: 14px;
    line-height: 1.43;
  }
}
@media (min-width: 768px) {
  .static-field:first-child .label-wrapper,
  .static-field:first-child .value-wrapper {
    padding-top: 1rem;
  }
  .static-field:last-child .label-wrapper,
  .static-field:last-child .value-wrapper {
    padding-bottom: 1rem;
  }
}

.sub-label {
  font-size: 12px;
  font-weight: 400;
}

.contact-details-container {
  border-top: solid 1px #e6e4e3;
}
@media (min-width: 768px) {
  .contact-details-container {
    border-top: none;
    border-left: solid 1px #e6e4e3;
  }
}

.row-items {
  font-size: 0.875rem;
  color: #1a1817;
}
.row-items .data-item {
  padding: 0.25rem 0;
}

/** Form PIN **/
.pin-inputs {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.pin-inputs input {
  min-width: 3rem;
  font-size: 1.67rem;
  text-align: center;
}

.datepicker ~ .dropdown-menu {
  min-width: auto;
  font-family: "SofiaPro";
}

[class*=icon-upload-] {
  width: 16px;
  height: 16px;
}

.icon-upload-clear {
  cursor: pointer;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath d='M2.854 2.146L8 7.293l5.146-5.147a.5.5 0 01.708.708L8.707 8l5.147 5.146a.5.5 0 01-.708.708L8 8.707l-5.146 5.147a.5.5 0 01-.708-.708L7.293 8 2.146 2.854a.5.5 0 01.708-.708z' fill='%23364247' fill-rule='nonzero'/%3E%3C/svg%3E");
}

.icon-upload-loading {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 40 40' %3E%3Cpath fill='%23D0E9F4' d='M20 35c-8.284 0-15-6.716-15-15 0-8.284 6.716-15 15-15 8.284 0 15 6.716 15 15 0 8.284-6.716 15-15 15zm0-2c7.18 0 13-5.82 13-13S27.18 7 20 7 7 12.82 7 20s5.82 13 13 13z'/%3E%3Cpath fill='%231493C8' d='M29.192 10.808A12.96 12.96 0 0 0 20 7V5c4.142 0 7.892 1.679 10.607 4.393l-1.415 1.415z'%3E%3CanimateTransform attributeType='xml' attributeName='transform' type='rotate' from='0 20 20' to='360 20 20' dur='0.7s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
}

.input-icon, .sd-form-row-invalid .custom-control::after {
  position: absolute;
  top: 0;
  right: -32px;
  width: 20px;
  height: 100%;
  margin: auto;
  background-repeat: no-repeat;
  background-position: center;
}
.input-icon.icon-invalid, .sd-form-row-invalid .icon-invalid.custom-control::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='%23D9534F' fill-rule='nonzero' d='M10 8.586l5.293-5.293a1 1 0 0 1 1.414 1.414L11.414 10l5.293 5.293a1 1 0 0 1-1.414 1.414L10 11.414l-5.293 5.293a1 1 0 1 1-1.414-1.414L8.586 10 3.293 4.707a1 1 0 1 1 1.414-1.414L10 8.586z'/%3E%3C/svg%3E%0A");
}
.input-icon.icon-loading, .sd-form-row-invalid .icon-loading.custom-control::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 40 40' %3E%3Cpath fill='%23D0E9F4' d='M20 35c-8.284 0-15-6.716-15-15 0-8.284 6.716-15 15-15 8.284 0 15 6.716 15 15 0 8.284-6.716 15-15 15zm0-2c7.18 0 13-5.82 13-13S27.18 7 20 7 7 12.82 7 20s5.82 13 13 13z'/%3E%3Cpath fill='%231493C8' d='M29.192 10.808A12.96 12.96 0 0 0 20 7V5c4.142 0 7.892 1.679 10.607 4.393l-1.415 1.415z'%3E%3CanimateTransform attributeType='xml' attributeName='transform' type='rotate' from='0 20 20' to='360 20 20' dur='0.7s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
}
.input-icon.icon-valid, .sd-form-row-invalid .icon-valid.custom-control::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='none' fill-rule='evenodd' stroke='%2313c265' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 9.5L7.5 15 18 4.5'/%3E%3C/svg%3E%0A");
}
.input-icon.icon-search, .sd-form-row-invalid .icon-search.custom-control::after {
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='24' height='24' transform='translate(0 0.365723)' fill='white'/%3E%3Cpath d='M10 17.3657C13.866 17.3657 17 14.2317 17 10.3657C17 6.49973 13.866 3.36572 10 3.36572C6.13401 3.36572 3 6.49973 3 10.3657C3 14.2317 6.13401 17.3657 10 17.3657Z' stroke='%232B65D9' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M15.5 15.8657L21 21.3657' stroke='%232B65D9' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
}
.input-icon.icon-clear, .sd-form-row-invalid .icon-clear.custom-control::after {
  right: 12px;
  cursor: pointer;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='%23364247' fill-rule='nonzero' d='M8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854a.5.5 0 0 1 .708-.708L8 7.293z'/%3E%3C/svg%3E%0A");
}

.sd-form-row-invalid .custom-control-label,
.sd-form-row-invalid label,
.sd-form-row-invalid legend {
  color: #eb5042;
}
.sd-form-row-invalid .form-control,
.sd-form-row-invalid button,
.sd-form-row-invalid select {
  border-color: #eb5042;
}
.sd-form-row-invalid .btn-input-group {
  border-left-color: #e6e4e3;
}
.sd-form-row-invalid .form-help-text {
  display: none;
}

.sd-form-row-valid .form-control,
.sd-form-row-valid label,
.sd-form-row-valid legend,
.sd-form-row-valid button,
.sd-form-row-valid select {
  border-color: #13c265;
}
.sd-form-row-valid .btn-input-group {
  border-left-color: #e6e4e3;
}

.form-help-text {
  font-size: 0.875rem;
  color: #73706f;
}

input[type=date] {
  height: calc(1.5em + 1rem + 4px);
}

.radio-block {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
  padding: 12px 16px;
  overflow: hidden;
  font-family: "SofiaPro";
  cursor: pointer;
  border: 2px solid #e6e4e3;
  border-radius: 0.25rem;
}
.radio-block .title {
  font-weight: 700;
}
.radio-block p {
  color: #403e3d;
}
.radio-block .footer {
  position: absolute;
  right: 4px;
  bottom: 0;
  left: 4px;
  padding-top: 0.125rem;
  padding-left: 12px;
  color: #403e3d;
  background-color: #e6e4e3;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
@media (min-width: 992px) {
  .radio-block .footer {
    right: 0;
    bottom: -7px;
    left: 0;
  }
}
.radio-block.selected, .radio-block:hover {
  border-color: #13c265;
}
.radio-block.selected .footer, .radio-block:hover .footer {
  color: #ffffff;
  background-color: #13c265;
}
.radio-block.selected .title {
  color: #1a1817;
}
.radio-block.selected p {
  font-weight: 700;
}

.modal-content {
  border: 0;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.08), 0 0 4px 0 rgba(0, 0, 0, 0.16);
}

.modal-body {
  box-shadow: inset 0 -1px 0 0 #e6e4e3;
}
.modal-body .top-description {
  margin-bottom: 17px;
  font-size: 0.875rem;
  line-height: 1.43;
  color: #403e3d;
}
.modal-body .top-label {
  font-weight: 700;
  color: #403e3d;
}
.modal-body .form-group:not(.row) {
  margin-bottom: 0;
}
.modal-body .form-row,
.modal-body .sd-form-row {
  padding-top: 1.4375rem;
}
.modal-body .form-row:first-child,
.modal-body .sd-form-row:first-child {
  padding-top: 0.625rem;
}
.modal-body .form-row:last-child,
.modal-body .sd-form-row:last-child {
  padding-bottom: 0.625rem;
}

.modal-title {
  font-weight: 700;
  line-height: 1.63;
}

.fade.in {
  opacity: 1;
}

.modal.in .modal-dialog {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}

.modal-backdrop.in {
  opacity: 0.7;
}

.modal-header {
  -ms-flex-align: center;
  align-items: center;
  padding: 17px 20px 19px;
  border: 0;
  box-shadow: inset 0 -1px 0 0 #e6e4e3;
}

.modal-footer {
  padding: 0.875rem 1.25rem;
  border: 0;
}
.modal-footer .btn {
  margin-right: 0;
  margin-left: 0.625rem;
}
@media (max-width: 991.98px) {
  .modal-footer .btn.btn-block {
    margin-left: 0;
  }
}
.modal-footer .btn-container-vertical-md-horizontal {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
}
.modal-footer .btn-container-vertical-md-horizontal .btn {
  width: 100%;
  margin-bottom: 0.5rem;
  margin-left: 0;
}
@media (min-width: 768px) {
  .modal-footer .btn-container-vertical-md-horizontal {
    -ms-flex-direction: row;
    flex-direction: row;
    float: right;
  }
  .modal-footer .btn-container-vertical-md-horizontal .btn {
    width: auto;
    margin-bottom: 0;
    margin-left: 0.625rem;
  }
}

.google-maps-container .angular-google-map-container {
  height: 400px;
}

.modal-content {
  padding: 20px;
}
@media (min-width: 768px) {
  .modal-content {
    padding: 50px;
  }
}
.modal-content .modal-close-button {
  position: absolute;
  top: 0;
  right: 0;
}
.modal-content .modal-close-button:hover {
  background-color: transparent;
}

.modal-header {
  padding: 0 28px 0 0;
  margin-bottom: 0.75rem;
  box-shadow: none;
}
@media (min-width: 768px) {
  .modal-header {
    padding-right: 0;
    margin-bottom: 1rem;
  }
}

.modal-body {
  padding: 0;
  box-shadow: none;
}

.modal-footer {
  padding: 0;
  margin-top: 0.5rem;
}
@media (min-width: 768px) {
  .modal-footer {
    margin-top: 1rem;
  }
}

.page-container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
}

.page-footer {
  z-index: 5;
  width: 100%;
  padding: 1rem 0;
  margin-top: 3rem;
  background-color: #ffffff;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.08), 0 0 4px 0 rgba(0, 0, 0, 0.16);
}

html,
body {
  height: 100%;
  font-size: 1rem;
  background-color: #faf8f7;
}

.loading-mask,
.login-mask {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3;
  width: 100%;
  height: 100%;
  background: #ffffff;
  opacity: 0.95;
}
.loading-mask .content,
.login-mask .content {
  position: relative;
  top: 50%;
  text-align: center;
}
.loading-mask .content h1,
.login-mask .content h1 {
  color: #1a1817;
}
.loading-mask .content img,
.login-mask .content img {
  margin-bottom: 8px;
}

.content-center {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.referral-header {
  height: 18rem;
  margin-top: -0.45rem;
  background-color: rgb(208, 233.4, 244);
}
@media (max-width: 767.98px) {
  .referral-header {
    height: 32rem;
  }
}

.referral-body {
  margin-top: -292px;
}
@media (max-width: 767.98px) {
  .referral-body {
    margin-top: -33rem;
  }
}

.indent-level-1 {
  margin-left: 0;
}

.indent-level-2 {
  margin-left: 0rem;
}

.indent-level-3 {
  margin-left: 1.25rem;
}

.indent-level-4 {
  margin-left: 2.5rem;
}

.indent-level-5 {
  margin-left: 3.75rem;
}

.indent-level-6 {
  margin-left: 5rem;
}

.angular-google-map-container {
  height: 400px;
}

.notice-container {
  position: fixed;
  top: 10%;
  right: 0;
  left: 0;
  z-index: 1200;
}
.notice-container .notice {
  position: relative;
  display: inline-block;
  max-width: 90%;
  padding: 1.25em 1.5em;
  margin: 0 auto;
  color: #13c265;
  background-color: #2ee674;
  border-color: #2ee674;
  border-radius: 0.25rem;
}
@media (min-width: 576px) {
  .notice-container .notice {
    padding: 1.25rem 3.5rem;
  }
}

.scrollable {
  max-height: 10rem;
  overflow-y: auto;
}

.box-shadow {
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.16);
}

.box-shadow-bottom {
  box-shadow: inset 0 -1px 0 0 #e6e4e3;
}

.box-shadow-bottom-right-lg {
  box-shadow: 8px 8px 0 0 rgba(20, 48, 102, 0.03);
}

.d-sm-width-auto {
  width: 100%;
}
@media (min-width: 576px) {
  .d-sm-width-auto {
    width: auto;
  }
}

.prevent-vertical-growth {
  margin-top: -0.5rem;
  margin-bottom: -0.5rem;
}

@media (min-width: 576px) {
  .prevent-vertical-growth-sm {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
  }
}
@media (min-width: 768px) {
  .prevent-vertical-growth-md {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
  }
}
@media (min-width: 992px) {
  .prevent-vertical-growth-lg {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
  }
}
@media (min-width: 1200px) {
  .prevent-vertical-growth-xl {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
  }
}
.col-grow {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.w-50 {
  width: 50%;
}

@media (min-width: 576px) {
  .w-50-sm {
    width: 50%;
  }
}
@media (min-width: 768px) {
  .w-50-md {
    width: 50%;
  }
}
@media (min-width: 992px) {
  .w-50-lg {
    width: 50%;
  }
}
@media (min-width: 1200px) {
  .w-50-xl {
    width: 50%;
  }
}
.power-link:not([href]):not([tabindex]) {
  color: #13c265;
  text-decoration: none;
}
.power-link:not([href]):not([tabindex]):hover, .power-link:not([href]):not([tabindex]):focus {
  color: #009952;
  text-decoration: underline;
}
.power-link:not([href]):not([tabindex]):focus {
  outline: 0;
}

.cursor-pointer {
  cursor: pointer;
}

.nav-link {
  font-family: hkgrotesk;
}

.pre-wrap {
  white-space: pre-wrap;
}

button:focus,
a:focus {
  outline: 0;
}

.min-h-0 {
  min-height: 0;
}

.bg-gray-light {
  background-color: #e6e4e3;
}
.bg-gray-light .rating {
  color: #1a1817;
}

.bg-primary-light {
  background-color: #2ee674;
}
.bg-primary-light .rating {
  color: #13c265;
}

.bg-warning-light {
  background-color: rgb(251.8, 228.6, 217.8);
}
.bg-warning-light .rating {
  color: #ef7b45;
}

.bg-danger-light {
  background-color: rgb(251, 220, 217.2);
}
.bg-danger-light .rating {
  color: #eb5042;
}

.rating-label {
  display: inline-block;
  padding: 2px 10px;
  font-family: "SofiaPro";
  font-size: 1rem;
  font-weight: 400;
  color: #403e3d;
  border-radius: 2px;
}

.rating-star {
  display: inline-block;
  color: #ccc9c8;
}
.rating-star.star-sm {
  width: 1.25rem;
  height: 1.25rem;
  margin: 2px;
}
.rating-star.star-sm:first-child {
  margin-left: 0;
}
.rating-star.star-sm:last-child {
  margin-right: 0;
}
.rating-star.star-md {
  width: 1.5rem;
  height: 1.5rem;
  margin: 4px 2px;
}
.rating-star.star-md:first-child {
  margin-left: 0;
}
.rating-star.star-md:last-child {
  margin-right: 0;
}

.company-rating-unknown.rating-box {
  background-color: #e6e4e3;
}
.company-rating-unknown .rating {
  color: #403e3d;
}

.company-rating-very-bad.rating-box {
  background-color: rgb(251, 220, 217.2);
}
.company-rating-very-bad .rating {
  font-weight: 700;
  color: #eb5042;
}

.company-rating-bad.rating-box {
  background-color: rgb(251.8, 228.6, 217.8);
}
.company-rating-bad .rating {
  font-weight: 700;
  color: #ef7b45;
}

.company-rating-medium.rating-box {
  background-color: #2ee674;
}
.company-rating-medium .rating {
  font-weight: 700;
  color: #13c265;
}

.company-rating-good.rating-box {
  background-color: #2ee674;
}
.company-rating-good .rating {
  font-weight: 700;
  color: #13c265;
}

.company-rating-very-good.rating-box {
  background-color: #2ee674;
}
.company-rating-very-good .rating {
  font-weight: 700;
  color: #13c265;
}

.rating-summary {
  padding: 0;
  border-top: 1px solid #e6e4e3;
  border-bottom: 1px solid #e6e4e3;
}

.review-summary {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-align: center;
  align-items: center;
}
.review-summary .review-summary-label {
  margin: 0 1rem 0 0;
}
.review-summary .review-count {
  padding-left: 0.625rem;
}

.review-reply {
  background-color: #f5f3f2;
  border-radius: 0.25rem;
}

.company-profile-carousel-wrap {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.company-profile-carousel-wrap::after {
  position: absolute;
  width: 100%;
  height: 100%;
  content: "";
  background: #1a1817;
  opacity: 0.85;
}

.company-profile-carousel {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 10;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.company-profile-carousel .carousel-caption {
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0;
  background: linear-gradient(to bottom, rgba(26, 24, 23, 0), rgba(26, 24, 23, 0.5));
  opacity: 0.85;
}

.company-profile-photos {
  margin-right: 0.5rem;
  margin-bottom: 1rem;
}
.company-profile-photos img {
  width: 15rem;
  height: 10rem;
  cursor: pointer;
}
@media (max-width: 767.98px) {
  .company-profile-photos img {
    width: 11.25rem;
    height: 7.5rem;
  }
}
.company-profile-photos:last-child {
  margin-right: 0;
}

.carousel-image {
  display: block;
  max-width: 90vw;
  max-height: 75vh;
}
@media screen and (max-device-height: 440px) {
  .carousel-image {
    max-height: 65vh;
  }
}

.carousel-controls-container {
  position: fixed;
  bottom: 0;
  left: 50%;
  z-index: 10000;
  margin-bottom: 2rem;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
@media screen and (max-device-height: 540px) {
  .carousel-controls-container {
    margin-bottom: 1rem;
  }
}

.carousel-controls-text {
  width: 5.5rem;
  font-size: 0.875rem;
  line-height: 1.25;
  color: #ffffff;
  text-align: center;
}

.carousel-close-container {
  position: fixed;
  top: 3%;
  right: 3%;
  z-index: 10000;
}

.carousel-action-btn {
  border: 1px solid #3e9cfa;
  padding: 0.4375rem 0.4375rem;
  font-size: 0.625rem;
  line-height: 1;
  border-radius: 4px;
}

.hide-scroll-bar {
  overflow: hidden;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.preview-loader-padding {
  padding: 50px;
}
@media (max-width: 767.98px) {
  .preview-loader-padding {
    padding: 40px;
  }
}

.alert {
  padding: 0.75rem 1.25rem;
  color: #403e3d;
  border: 0;
  border-radius: 0;
}
.alert.alert-danger {
  color: #d13426;
  background-color: #fcefed;
}
.alert.alert-warning {
  color: #db8727;
  background-color: #fcf5ed;
}
.alert.alert-success {
  color: #009952;
  background-color: #e1fae9;
}
.alert.alert-secondary {
  color: #2142a6;
  background-color: #f2f7fc;
}

.alert-container {
  position: fixed;
  top: 10%;
  right: 0;
  left: 0;
  z-index: 1200;
  text-align: center;
}
.alert-container:hover {
  cursor: pointer;
}
.alert-container .alert-text {
  position: relative;
  display: inline-block;
  max-width: 90%;
  padding: 0.75em 1.25em;
  margin: 0 auto;
  font-size: 1rem;
  line-height: 1.625;
  color: #ffffff;
  box-shadow: 0 4px 16px 0 rgba(22, 23, 26, 0.25);
}
.alert-container .alert-text.danger {
  background-color: #eb5042;
}
.alert-container .alert-text.warning {
  background-color: #ef7b45;
}
.alert-container .alert-text.success {
  background-color: #13c265;
}
.alert-container .alert-text.secondary {
  background-color: #2b65d9;
}

.form-header-alert {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  padding: 16px 20px;
  margin: -20px -20px 20px;
  color: #403e3d;
}
@media (min-width: 768px) {
  .form-header-alert {
    -ms-flex-align: center;
    align-items: center;
    padding: 24px;
    margin: -32px;
  }
}
@media (min-width: 992px) {
  .form-header-alert {
    margin: -50px -50px 48px;
  }
}

.background-dots {
  background-image: url("../images/move/home/how-it-works/background.svg");
  background-repeat: no-repeat;
}

@media (max-width: 991.98px) {
  .background-dots {
    background-image: url("../images/move/home/how-it-works/background-mobile.svg");
  }
}
.bg-primary {
  background-color: #e1fae9 !important;
}

.bg-secondary {
  background-color: #f2f7fc !important;
}

.bg-secondary-darker {
  background-color: #2b65d9 !important;
}

.bg-secondary-dark {
  background-color: #2142a6 !important;
}

.bg-white {
  background-color: #ffffff;
}

.bg-grey {
  background-color: #e6e4e3;
}

.bg-grey-light {
  background-color: #faf8f7;
}

/* stylelint-disable */
.yoast-breadcrumb {
  margin-top: 16px;
  margin-bottom: 32px;
  font-family: hkgrotesk;
  color: #403e3d;
}
@media (min-width: 768px) {
  .yoast-breadcrumb {
    margin-top: 24px;
    margin-bottom: 56px;
  }
}
.yoast-breadcrumb a {
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  font-family: hkgrotesk;
  color: #403e3d;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.yoast-breadcrumb a:visited,
.yoast-breadcrumb a:active {
  color: #403e3d;
}
.yoast-breadcrumb span a {
  margin-right: 16px;
}
.yoast-breadcrumb .breadcrumb-separator ~ .breadcrumb_last {
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.yoast-breadcrumb .breadcrumb-separator ~ span a::before,
.yoast-breadcrumb .breadcrumb-separator ~ .breadcrumb_last::before {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 16px;
  margin-bottom: 3px;
  vertical-align: middle;
  content: "";
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E%3Cpath fill='none' fill-rule='evenodd' stroke='%23403e3d' stroke-linecap='round' stroke-linejoin='round' d='M2 5.5l6 6 6-6'/%3E%3C/svg%3E");
  border: none;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}
@media (max-width: 991.98px) {
  .yoast-breadcrumb span a {
    margin-right: 8px;
  }
  .yoast-breadcrumb .breadcrumb-separator ~ span a::before,
  .yoast-breadcrumb .breadcrumb-separator ~ .breadcrumb_last::before {
    margin-right: 8px;
  }
}
.yoast-breadcrumb .breadcrumb_last > * {
  white-space: normal;
}

/* stylelint-enable */
.breadcrumb {
  padding: 1rem 0;
  margin-bottom: 0;
  line-height: 1.5rem;
  background-color: transparent;
}
.breadcrumb .breadcrumb-item {
  padding-left: 0;
}
.breadcrumb .breadcrumb-item a {
  color: #403e3d;
}
.breadcrumb .breadcrumb-item a:hover {
  color: #403e3d;
}
.breadcrumb .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0;
  padding-left: 0.5rem;
  line-height: 0.75rem;
  color: #73706f;
  content: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNyIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE3IDE2Ij4KICAgIDxwYXRoIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlPSIjNDAzRTNEIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIGQ9Ik0wIDAuNUw2IDYuNSAxMiAwLjUiIHRyYW5zZm9ybT0icm90YXRlKC05MCA5LjUgNC41KSIvPgo8L3N2Zz4K);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.breadcrumb .breadcrumb-item:first-child {
  padding-left: 0;
}
@media (min-width: 768px) {
  .breadcrumb {
    line-height: 1.625rem;
  }
  .breadcrumb .breadcrumb-item {
    padding-left: 1rem;
  }
  .breadcrumb .breadcrumb-item:first-child::before {
    display: none;
  }
  .breadcrumb .breadcrumb-item::before {
    padding-right: 1rem;
    padding-left: 0;
    -webkit-transform: none;
    transform: none;
  }
}

.btn {
  font-family: hkgrotesk;
  line-height: 1.5;
  white-space: normal;
  border: 0;
  padding: 0.625rem 1rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 4px;
}
.btn.btn-hero {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.6;
  border-radius: 4px;
}
.btn.btn-lg, .btn-group-lg > .btn {
  padding: 1rem 1rem;
  font-size: 1.125rem;
  line-height: 1.35;
  border-radius: 4px;
}
@media (min-width: 992px) {
  .btn.btn-lg-lg {
    padding: 1rem 1rem;
    font-size: 1.125rem;
    line-height: 1.35;
    border-radius: 4px;
  }
}
.btn.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 1rem;
  font-size: 0.875rem;
  line-height: 1.72;
  border-radius: 4px;
}

.btn-primary {
  color: #ffffff;
  background-color: #13c265;
  border-color: #13c265;
  color: #ffffff;
}
.btn-primary:hover {
  color: #ffffff;
  background-color: #009952;
  border-color: #009952;
}
.btn-primary:focus, .btn-primary.focus {
  color: #ffffff;
  background-color: #009952;
  border-color: #009952;
  box-shadow: 0 0 0 0.25rem rgba(54.4, 203.15, 124.1, 0.25);
}
.btn-primary.disabled, .btn-primary:disabled {
  color: #ffffff;
  background-color: #13c265;
  border-color: #13c265;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #ffffff;
  background-color: #009952;
  border-color: #009952;
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(54.4, 203.15, 124.1, 0.25);
}
.btn-primary:focus {
  background: #13c265;
}

.btn-secondary {
  color: #212529;
  background-color: #ffffff;
  border-color: #3e9cfa;
  color: #2b65d9;
  border-style: solid;
  border-width: 2px;
  padding: calc(0.625rem - 0.125rem) calc(1rem - 0.125rem);
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 4px;
}
.btn-secondary:hover {
  color: #ffffff;
  background-color: #2b65d9;
  border-color: #2b65d9;
}
.btn-secondary:focus, .btn-secondary.focus {
  color: #ffffff;
  background-color: #2b65d9;
  border-color: #2b65d9;
  box-shadow: 0 0 0 0.25rem rgba(57.65, 138.15, 218.65, 0.25);
}
.btn-secondary.disabled, .btn-secondary:disabled {
  color: #212529;
  background-color: #ffffff;
  border-color: #3e9cfa;
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  color: #ffffff;
  background-color: #2b65d9;
  border-color: #2b65d9;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(57.65, 138.15, 218.65, 0.25);
}
.btn-secondary:focus {
  color: #2b65d9;
  background: transparent;
}
.btn-secondary.btn-hero {
  padding: calc(0.75rem - 0.125rem) calc(1.5rem - 0.125rem);
  font-size: 1.25rem;
  line-height: 1.6;
  border-radius: 4px;
}
.btn-secondary.btn-lg, .btn-group-lg > .btn-secondary.btn {
  padding: calc(1rem - 0.125rem) calc(1rem - 0.125rem);
  font-size: 1.125rem;
  line-height: 1.35;
  border-radius: 4px;
}
.btn-secondary.btn-sm, .btn-group-sm > .btn-secondary.btn {
  padding: calc(0.25rem - 0.125rem) calc(1rem - 0.125rem);
  font-size: 0.875rem;
  line-height: 1.72;
  border-radius: 4px;
}
.btn-secondary.btn-arrow::after {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath fill='none' fill-rule='evenodd' stroke='%232b65d9' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M3 12.2c3.667-.267 7.5-.3 11.5-.1 1.694.085 3.86.051 6.5-.1m-7 7c3.5-3 7-7 7-7s-2-3-7-7'/%3E%3C/svg%3E%0A") no-repeat;
}
.btn-secondary.btn-arrow:hover::after {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath fill='none' fill-rule='evenodd' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M3 12.2c3.667-.267 7.5-.3 11.5-.1 1.694.085 3.86.051 6.5-.1m-7 7c3.5-3 7-7 7-7s-2-3-7-7'/%3E%3C/svg%3E%0A") no-repeat;
}
.btn-secondary.btn-arrow.btn-arrow-left::before {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath fill='none' fill-rule='evenodd' stroke='%232b65d9' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M3 12l18 .2a92.73 92.73 0 0 0-10.305-.153L9.5 12.1c-1.694.085-3.86.051-6.5-.1zm7 7c-3.5-3-7-7-7-7l.192-.27C3.8 10.91 5.882 8.295 10 5'/%3E%3C/svg%3E%0A") no-repeat;
}
.btn-secondary.btn-arrow.btn-arrow-left:hover::before {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath fill='none' fill-rule='evenodd' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M3 12l18 .2a92.73 92.73 0 0 0-10.305-.153L9.5 12.1c-1.694.085-3.86.051-6.5-.1zm7 7c-3.5-3-7-7-7-7l.192-.27C3.8 10.91 5.882 8.295 10 5'/%3E%3C/svg%3E%0A") no-repeat;
}
.btn-secondary.btn-arrow.btn-arrow-down::after {
  display: block;
  width: calc(1rem * 1);
  height: calc(1rem * 1);
  content: "";
  padding-right: 0;
  margin-left: 0.5rem;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E%3Cpath fill='none' fill-rule='evenodd' stroke='%232b65d9' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M7.867 2c.177 2.444.2 5 .066 7.667C7.877 10.796 7.9 12.24 8 14M3.333 9.333C5.333 11.667 8 14 8 14s2-1.333 4.667-4.667'/%3E%3C/svg%3E%0A") no-repeat;
}
.btn-secondary.btn-arrow.btn-arrow-down:hover::after, .btn-secondary.btn-arrow.btn-arrow-down:active::after, .btn-secondary.btn-arrow.btn-arrow-down:active:focus::after {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E%3Cpath fill='none' fill-rule='evenodd' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M7.867 2c.177 2.444.2 5 .066 7.667C7.877 10.796 7.9 12.24 8 14M3.333 9.333C5.333 11.667 8 14 8 14s2-1.333 4.667-4.667'/%3E%3C/svg%3E%0A") no-repeat;
}
.btn-secondary.btn-arrow.btn-arrow-down:focus::after {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E%3Cpath fill='none' fill-rule='evenodd' stroke='%232b65d9' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M7.867 2c.177 2.444.2 5 .066 7.667C7.877 10.796 7.9 12.24 8 14M3.333 9.333C5.333 11.667 8 14 8 14s2-1.333 4.667-4.667'/%3E%3C/svg%3E%0A") no-repeat;
}
.btn-secondary.btn-arrow.btn-arrow-down:disabled::after {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E%3Cpath fill='none' fill-rule='evenodd' stroke='%23212529' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M7.867 2c.177 2.444.2 5 .066 7.667C7.877 10.796 7.9 12.24 8 14M3.333 9.333C5.333 11.667 8 14 8 14s2-1.333 4.667-4.667'/%3E%3C/svg%3E%0A") no-repeat;
}
.btn-secondary.btn-loading {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.btn-secondary.btn-loading::after {
  width: calc(1rem * 1);
  height: calc(1rem * 1);
  margin-right: 0;
  margin-left: 0.5rem;
  content: "";
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' %3E%3Cpath fill='%23CCC9C8' d='M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zm0 1.067a6.933 6.933 0 1 0 0 13.866A6.933 6.933 0 0 0 8 1.067z'/%3E%3Cpath fill='%232B65D9' d='M8 0c2.21 0 4.21.896 5.657 2.343l-.754.755A6.912 6.912 0 0 0 8 1.067V0z'%3E%3CanimateTransform attributeType='xml' attributeName='transform' type='rotate' from='0 8 8' to='360 8 8' dur='0.7s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E") no-repeat;
}

.btn-secondary-thin {
  color: #212529;
  background-color: #ffffff;
  border-color: #3e9cfa;
  color: #2b65d9;
  border-style: solid;
  border-width: 1px;
}
.btn-secondary-thin:hover {
  color: #ffffff;
  background-color: #2b65d9;
  border-color: #2b65d9;
}
.btn-secondary-thin:focus, .btn-secondary-thin.focus {
  color: #ffffff;
  background-color: #2b65d9;
  border-color: #2b65d9;
  box-shadow: 0 0 0 0.25rem rgba(57.65, 138.15, 218.65, 0.25);
}
.btn-secondary-thin.disabled, .btn-secondary-thin:disabled {
  color: #212529;
  background-color: #ffffff;
  border-color: #3e9cfa;
}
.btn-secondary-thin:not(:disabled):not(.disabled):active, .btn-secondary-thin:not(:disabled):not(.disabled).active, .show > .btn-secondary-thin.dropdown-toggle {
  color: #ffffff;
  background-color: #2b65d9;
  border-color: #2b65d9;
}
.btn-secondary-thin:not(:disabled):not(.disabled):active:focus, .btn-secondary-thin:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary-thin.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(57.65, 138.15, 218.65, 0.25);
}
.btn-secondary-thin.btn-sm, .btn-group-sm > .btn-secondary-thin.btn {
  padding: calc(0.25rem - 0.0625rem) calc(1rem - 0.3125rem);
  font-size: 0.875rem;
  line-height: 1.72;
  border-radius: 4px;
}
.btn-secondary-thin.btn-icon:focus img {
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1);
}

.btn-secondary-borderless {
  font-weight: 400;
  color: #2b65d9;
  background-color: transparent;
}
.btn-secondary-borderless:hover {
  color: #2b65d9;
  text-decoration: none;
  background-color: #f2f7fc;
  border-color: transparent;
}
.btn-secondary-borderless:focus, .btn-secondary-borderless.focus {
  color: #2b65d9;
  background: transparent;
}
.btn-secondary-borderless:disabled, .btn-secondary-borderless.disabled {
  color: #73706f;
  pointer-events: none;
}

.btn-pro-char {
  padding: calc(0.25rem - 0.0625rem) 0;
  font-size: 0.875rem;
  line-height: 1.42857143;
  border-radius: 3px;
  width: 2rem;
  font-weight: 400;
}
@media (min-width: 992px) {
  .btn-pro-char {
    width: 1.75rem;
  }
}
.btn-pro-char:disabled, .btn-pro-char.disabled {
  border-color: #f5f3f2;
  border-style: solid;
  border-width: 1px;
  opacity: 1;
}

.btn-pro-char-wide {
  width: 2.75rem;
}
@media (min-width: 992px) {
  .btn-pro-char-wide {
    width: 2.5rem;
  }
}

.btn-link {
  font-weight: 400;
  color: #2b65d9;
  background-color: transparent;
}
.btn-link:hover {
  color: #2b65d9;
  text-decoration: none;
  background-color: #f2f7fc;
  border-color: transparent;
}
.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
  border-color: transparent;
  box-shadow: none;
}
.btn-link:disabled, .btn-link.disabled {
  color: #73706f;
  pointer-events: none;
}
.btn-link.edit-back-button:focus, .btn-link.edit-back-button:hover {
  text-decoration: none;
  background-color: transparent;
}
.btn-link.btn-arrow::after {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath fill='none' fill-rule='evenodd' stroke='%232b65d9' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M3 12.2c3.667-.267 7.5-.3 11.5-.1 1.694.085 3.86.051 6.5-.1m-7 7c3.5-3 7-7 7-7s-2-3-7-7'/%3E%3C/svg%3E%0A") no-repeat;
}
.btn-link.btn-arrow.btn-arrow-left::before {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath fill='none' fill-rule='evenodd' stroke='%232b65d9' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M3 12l18 .2a92.73 92.73 0 0 0-10.305-.153L9.5 12.1c-1.694.085-3.86.051-6.5-.1zm7 7c-3.5-3-7-7-7-7l.192-.27C3.8 10.91 5.882 8.295 10 5'/%3E%3C/svg%3E%0A") no-repeat;
}

.btn-input-group {
  color: #212529;
  background-color: #ffffff;
  border-color: #e6e4e3;
  display: -ms-flexbox;
  display: flex;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  box-shadow: none;
}
.btn-input-group:hover {
  color: #212529;
  background-color: #f5f3f2;
  border-color: #a6a2a1;
}
.btn-input-group:focus, .btn-input-group.focus {
  color: #212529;
  background-color: #f5f3f2;
  border-color: #a6a2a1;
  box-shadow: 0 0 0 0.25rem rgba(200.45, 199.35, 199.1, 0.5);
}
.btn-input-group.disabled, .btn-input-group:disabled {
  color: #212529;
  background-color: #ffffff;
  border-color: #e6e4e3;
}
.btn-input-group:not(:disabled):not(.disabled):active, .btn-input-group:not(:disabled):not(.disabled).active, .show > .btn-input-group.dropdown-toggle {
  color: #212529;
  background-color: #ffffff;
  border-color: #ccc9c8;
}
.btn-input-group:not(:disabled):not(.disabled):active:focus, .btn-input-group:not(:disabled):not(.disabled).active:focus, .show > .btn-input-group.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(200.45, 199.35, 199.1, 0.5);
}

.radio-tile {
  display: block;
  padding: 1rem 0.5rem;
  font-weight: 700;
  color: #2b65d9;
  border: 2px solid #e6e4e3;
  border-radius: 0.25rem;
  box-shadow: none;
  /* stylelint-disable scss/at-function-named-arguments */
}
.radio-tile:hover, .radio-tile:active {
  color: #2b65d9 !important;
  border-color: #3e9cfa;
}
.radio-tile:not(:disabled):not(.disabled):active:hover {
  border-color: #2b65d9;
}
.radio-tile .image-container {
  height: 64px;
}
@media (min-width: 992px) {
  .radio-tile .image-container {
    height: 76px;
  }
}
.radio-tile .image-container img {
  -o-object-fit: contain;
  object-fit: contain;
  max-width: 100%;
}
.radio-tile:focus {
  border: 2px solid #3e9cfa;
  box-shadow: 0 0 0 0.25rem rgb(1.9696969697, 39, 76.0303030303);
}
.radio-tile div {
  height: 3rem;
  white-space: pre-wrap;
}
.radio-tile .custom {
  height: 124px;
}
@media (min-width: 992px) {
  .radio-tile .custom {
    height: 136px;
  }
}

@media (hover) {
  .shift-up {
    margin-top: 4px;
  }
  .shift-up:hover {
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px);
  }
}
@media (hover) {
  .shift-up {
    margin-top: 4px;
  }
  .shift-up:hover {
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px);
  }
}
.input-group-append .btn {
  border: 2px solid #e6e4e3;
}

.review-form-btn-container {
  margin-top: 1.75rem;
}
@media (min-width: 992px) {
  .review-form-btn-container {
    margin-top: 2.25rem;
  }
}

.btn-arrow {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.btn-arrow::after {
  display: block;
  width: calc(1rem * 1.5);
  height: calc(1rem * 1.5);
  content: "";
  padding-right: 1.5rem;
  margin-left: 0.5rem;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath fill='none' fill-rule='evenodd' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M3 12.2c3.667-.267 7.5-.3 11.5-.1 1.694.085 3.86.051 6.5-.1m-7 7c3.5-3 7-7 7-7s-2-3-7-7'/%3E%3C/svg%3E%0A") no-repeat;
}
.btn-arrow.btn-arrow-left::before {
  display: block;
  width: calc(1rem * 1.5);
  height: calc(1rem * 1.5);
  content: "";
  padding-left: 1.5rem;
  margin-right: 0.5rem;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath fill='none' fill-rule='evenodd' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M3 12l18 .2a92.73 92.73 0 0 0-10.305-.153L9.5 12.1c-1.694.085-3.86.051-6.5-.1zm7 7c-3.5-3-7-7-7-7l.192-.27C3.8 10.91 5.882 8.295 10 5'/%3E%3C/svg%3E%0A") no-repeat;
}
.btn-arrow.btn-arrow-left.no-icon-margin::before {
  margin-right: 0;
}
.btn-arrow.btn-arrow-left::after {
  display: none;
}

.btn-icon {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.btn-icon:hover:not(:focus) img, .btn-icon:active img {
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1);
}
.btn-icon img {
  margin-right: 0.25rem;
}

.copy-button .disabled-state-translation {
  display: none;
}
.copy-button.copied .initial-state-translation {
  display: none;
}
.copy-button.copied .disabled-state-translation {
  display: inline-block;
}

.color-secondary {
  color: #2b65d9;
}

.color-secondary-dark {
  color: #2142a6;
}

.uib-datepicker-popup .btn {
  padding: 0.375rem 0.45rem;
  line-height: 1.42;
  border: 0;
  border-radius: 0;
}
.uib-datepicker-popup.dropdown-menu {
  position: absolute;
}

.datepicker-input {
  background-color: #ffffff !important;
}
.datepicker-input:disabled {
  background-color: #e6e4e3 !important;
}

.pac-container {
  margin-top: 4px;
  border-radius: 4px;
}
.pac-container .pac-icon {
  display: none;
}
.pac-container .pac-item,
.pac-container .pac-item-query,
.pac-container .pac-matched {
  font-family: "SofiaPro";
  font-size: 14px;
}
.pac-container .pac-item {
  padding: 8px 16px;
  color: #73706f;
  border-top: none;
  border-bottom: none;
}
.pac-container .pac-item:hover {
  cursor: pointer;
  background-color: #f5f3f2 !important;
}
.pac-container .pac-item-selected {
  background-color: #f5f3f2 !important;
}
.pac-container .pac-item-query {
  color: #1a1817;
}
.pac-container .pac-matched {
  font-weight: 700;
  color: #1a1817;
}
.pac-container::after {
  height: 0;
  background-image: none !important;
}

.dropdown-autocomplete .dropdown-menu {
  position: absolute;
  width: 100%;
  max-height: 500px;
  margin-top: 0;
  overflow-y: auto;
}
.dropdown-autocomplete .dropdown-menu::before {
  content: none;
}
.dropdown-autocomplete .dropdown-menu::after {
  content: none;
}
@media (min-width: 992px) {
  .dropdown-autocomplete .dropdown-menu {
    left: 1.5rem;
  }
}
.dropdown-autocomplete .dropdown-menu .dropdown-item {
  width: 100%;
  height: auto;
  padding: 0.5rem 1rem;
  font-size: 1.125rem;
  white-space: normal;
}
.dropdown-autocomplete .dropdown-menu .dropdown-item.active, .dropdown-autocomplete .dropdown-menu .dropdown-item:active {
  color: #2b65d9;
  text-decoration: none;
  background-color: rgba(62, 156, 250, 0.15);
}
.dropdown-autocomplete .dropdown-menu .dropdown-item:hover {
  cursor: pointer;
}
@media (min-width: 768px) {
  .dropdown-autocomplete.dropdown-autocomplete-hero-sea.dropdown-autocomplete-hero-sea-international .dropdown-menu {
    width: 337px !important;
  }
}
.dropdown-autocomplete.dropdown-autocomplete-hero-sea .dropdown-menu {
  max-height: 300px;
  margin-top: 5px;
  overflow-y: auto;
}
@media (min-width: 768px) {
  .dropdown-autocomplete.dropdown-autocomplete-hero-sea .dropdown-menu {
    width: calc(570px - 30px - 30px) !important;
  }
}
.dropdown-autocomplete.dropdown-autocomplete-b2b .dropdown-menu {
  max-height: 265px;
  margin-bottom: 1.5rem;
}
@media (min-width: 992px) {
  .dropdown-autocomplete.dropdown-autocomplete-b2b .dropdown-menu {
    left: 1.5rem !important;
  }
}

.areas-grid-list {
  -webkit-column-count: 2;
  -moz-column-count: 2;
  column-count: 2;
  -webkit-column-gap: 1rem;
  -moz-column-gap: 1rem;
  column-gap: 1rem;
}
@media (min-width: 992px) {
  .areas-grid-list {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
  }
}

@media (max-width: 575.98px) {
  .dynamic-directory-modal .modal-dialog {
    margin: 0;
  }
}
.dynamic-directory-modal .modal-content {
  padding: 0;
}
.dynamic-directory-modal .modal-content .modal-header {
  box-shadow: 0 0 8px 0 rgba(33, 31, 35, 0.1490196078), 0 0 0 1px rgba(33, 31, 35, 0.1019607843);
}

.region-see-all-cities-modal .modal-body {
  height: 36.25rem;
  padding: 1.5rem;
  overflow-y: auto;
}
@media (max-width: 575.98px) {
  .region-see-all-cities-modal .modal-body {
    height: calc(100vh - 3.75rem);
  }
}

.dynamic-directory-filter .dropdown-menu.align-menu-left {
  top: 3.5rem;
  left: 1.5rem;
}

.directory-companies-filter-modal .modal-body {
  overflow-x: hidden;
  overflow-y: scroll;
  max-height: 34.075rem;
}
.directory-companies-filter-modal .modal-body .popover {
  position: relative !important;
  margin-top: 4px;
  -webkit-transform: none !important;
  transform: none !important;
}
@media (max-width: 575.98px) {
  .directory-companies-filter-modal .modal-body {
    height: calc(100vh - 9.55rem);
    max-height: 100%;
  }
}
.directory-companies-filter-modal .category-group-label {
  font-family: questasans;
}
.directory-companies-filter-modal .custom-control-label {
  margin-left: 1.625rem;
}

.company-list .navbar {
  z-index: 10;
}
.company-list .sky-loading-indicator-loader {
  right: 15px;
  left: 15px;
}
.company-list .sticky-top {
  z-index: 1;
}

.call-to-action {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.call-to-action .call-to-action-title {
  margin: 0;
}
@media (min-width: 992px) {
  .call-to-action {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}
@media (min-width: 576px) {
  .call-to-action {
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-align: center;
    align-items: center;
  }
}
.call-to-action .call-to-action-button {
  margin-top: 1rem;
}
@media (min-width: 576px) {
  .call-to-action .call-to-action-button {
    margin-top: 0;
    margin-left: 2rem;
  }
}
@media (min-width: 992px) {
  .call-to-action .call-to-action-button {
    margin-left: 4rem;
  }
}
@media (min-width: 1200px) {
  .call-to-action .call-to-action-button {
    margin-left: 5rem;
  }
}

.card {
  border: 1px solid #e6e4e3;
  border-radius: 0;
  box-shadow: none;
}
.card.card-rounded {
  border-radius: 3px;
}
.card.hint {
  border-width: 0;
  border-radius: 5px;
}

.card-header {
  padding: 32px 32px 0;
  border: 0;
  box-shadow: none;
}
.card-header .title {
  font-family: questasans;
  color: #403e3d;
}

.card-body {
  padding: 0;
}
.card-body .container label, .card-body .hero-container label, .card-body .b2c-page-container label, .card-body .container legend, .card-body .hero-container legend, .card-body .b2c-page-container legend {
  font-family: questasans;
  color: #403e3d;
}

.card-footer {
  padding-top: 1rem;
}
@media (min-width: 992px) {
  .card-footer {
    padding-top: 1.5rem;
  }
}

.cp-nap-buttons-block .nap-item {
  margin-right: 0.5rem;
}
.cp-nap-buttons-block .nap-item:last-child {
  margin-right: 0;
}

.reviews-description {
  padding: 0.75rem 1rem;
  color: #2142a6;
  background-color: #f2f7fc;
}

.anonymous-review-question-icon {
  width: 16px;
  height: 16px;
  margin-left: 4px;
  font-size: 12px;
  line-height: 1.2;
  color: #ffffff;
  text-align: center;
  background-color: #2b65d9;
  border: 1px solid #2b65d9;
  border-radius: 50%;
}
.anonymous-review-question-icon.active, .anonymous-review-question-icon[aria-describedby] {
  color: #2b65d9;
  background-color: #ffffff;
}
.anonymous-review-question-icon:hover {
  cursor: pointer;
}

.company-profile-link {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
}

.company-profile-list-item:hover .company-profile-list-title {
  text-decoration: underline;
}

.cities-letters:first-child {
  margin-top: 0;
}
@media (max-width: 767.98px) {
  .cities-letters {
    margin-top: 2rem;
  }
}
.cities-letters .city-letter-scope {
  margin-top: 1.5rem;
}
.cities-letters .city-letter-scope:first-child {
  margin-top: 0;
}
.cities-letters .city-letter-scope .city-name-scope {
  line-height: 1.5rem;
}
.cities-letters .city-letter-scope .city-name-scope .city-companies-count {
  line-height: 1.25rem;
}

.nav-tabs .nav-link {
  padding: calc(1rem - 0.4375rem) 1rem;
  font-weight: 700;
  line-height: 1.5;
  color: #2b65d9;
  background-color: #f2f7fc;
  border-bottom: 0;
}
.nav-tabs .nav-link.active {
  background-color: #ffffff;
}
.nav-tabs .nav-link:not(:disabled):not(.disabled):hover {
  color: #403e3d;
}

@font-face {
  font-family: HKGrotesk;
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/hk-grotesk/hkgrotesk-regular.eot");
  src: url("../fonts/hk-grotesk/hkgrotesk-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/hk-grotesk/hkgrotesk-regular.woff2") format("woff2"), url("../fonts/hk-grotesk/hkgrotesk-regular.woff") format("woff");
  font-display: swap;
}
@font-face {
  font-family: HKGrotesk;
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/hk-grotesk/hkgrotesk-bold.eot");
  src: url("../fonts/hk-grotesk/hkgrotesk-bold.eot?#iefix") format("embedded-opentype"), url("../fonts/hk-grotesk/hkgrotesk-bold.woff2") format("woff2"), url("../fonts/hk-grotesk/hkgrotesk-bold.woff") format("woff"), url("../fonts/hk-grotesk/hkgrotesk-bold.ttf") format("truetype");
  font-display: swap;
}
/**
 * @license
 * MyFonts Webfont Build ID 3765930, 2019-05-24T08:32:43-0400
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: QuestaSans-Regular by The Questa Project
 * URL: https://www.myfonts.com/fonts/questa/questa-sans/regular/
 * Licensed pageviews: Unlimited
 *
 * Webfont: QuestaSans-Bold by The Questa Project
 * URL: https://www.myfonts.com/fonts/questa/questa-sans/bold/
 * Licensed pageviews: 1,000,000
 *
 *
 * License: https://www.myfonts.com/viewlicense?type=web&buildid=3765930
 * Webfonts copyright: &#x00A9;2013, Jos Buivenga &amp; Martin Majoor
 *
 * © 2019 MyFonts Inc
*/
@font-face {
  font-family: QuestaSans;
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/questa-sans/3AD317_0_0.eot");
  src: url("../fonts/questa-sans/3AD317_0_0.eot?#iefix") format("embedded-opentype"), url("../fonts/questa-sans/3AD317_0_0.woff2") format("woff2"), url("../fonts/questa-sans/3AD317_0_0.woff") format("woff"), url("../fonts/questa-sans/3AD317_0_0.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: QuestaSans;
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/questa-sans/3AD317_1_0.eot");
  src: url("../fonts/questa-sans/3AD317_1_0.eot?#iefix") format("embedded-opentype"), url("../fonts/questa-sans/3AD317_1_0.woff2") format("woff2"), url("../fonts/questa-sans/3AD317_1_0.woff") format("woff"), url("../fonts/questa-sans/3AD317_1_0.ttf") format("truetype");
  font-display: swap;
}
.footer-nav {
  margin-bottom: 0;
}

.footer {
  margin-top: 3rem;
  color: #ffffff;
  background-color: #2142a6;
}
@media (min-width: 992px) {
  .footer {
    margin-top: 6rem;
  }
}
.footer .footer-links {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}
.footer .footer-links h5 {
  margin-bottom: 1rem;
  color: #ffffff;
}
.footer .footer-links .nav {
  -ms-flex-direction: column;
  flex-direction: column;
}
.footer .footer-links .nav li {
  display: -ms-flexbox;
  display: flex;
  word-break: break-word;
}
.footer .footer-links .nav li:last-child {
  margin-bottom: 0;
}
.footer .footer-links .nav a[href*=mailto], .footer .footer-links .nav a[href*=tel] {
  word-break: break-all;
}
@media (min-width: 768px) {
  .footer .footer-links {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}
.footer a {
  width: 100%;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #e6e4e3;
}
.footer a:hover {
  color: #e6e4e3;
}
@media (min-width: 768px) {
  .footer a {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
}
.footer .footer-bottom-list {
  padding: 1.5rem 0 1rem;
  background-color: #1c2f8c;
}
@media (min-width: 768px) {
  .footer .footer-bottom-list {
    padding-bottom: 1.25rem;
  }
}
.footer .footer-bottom-list .nav {
  -ms-flex-pack: center;
  justify-content: center;
  float: none;
}
@media (min-width: 768px) {
  .footer .footer-bottom-list .nav {
    float: left;
  }
}
.footer .footer-bottom-list .nav li {
  display: -ms-flexbox;
  display: flex;
  padding-right: 1rem;
  padding-left: 1rem;
}
@media (min-width: 768px) {
  .footer .footer-bottom-list .nav li {
    width: auto;
  }
}
.footer .footer-bottom-list .nav li:last-child {
  margin-right: 0;
}
.footer .footer-bottom-list .copyright {
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 0.5rem;
  text-align: center;
}
@media (min-width: 768px) {
  .footer .footer-bottom-list .copyright {
    float: left;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    margin-right: 1rem;
    margin-bottom: 0;
  }
}
.footer [class*=col-] {
  margin-bottom: 1.5rem;
}
.footer [class*=col-]:last-child {
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .footer [class*=col-] {
    margin-bottom: 0;
  }
}
.footer .phone-number {
  font-family: questasans;
  font-size: 1.375rem;
  line-height: 1.75rem;
}
@media (min-width: 992px) {
  .footer .phone-number {
    font-size: 1.5rem;
    line-height: 2rem;
  }
}

sticky-form.visible ~ move-footer {
  margin-bottom: calc(3.75rem + 1px);
}
@media (min-width: 768px) {
  sticky-form.visible ~ move-footer {
    margin-bottom: calc(5.25rem + 1px);
  }
}

.radio-pills {
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 8px;
  overflow: auto;
  background: linear-gradient(to right, #ffffff 30%, rgba(255, 255, 255, 0)), linear-gradient(to right, rgba(255, 255, 255, 0), #ffffff 70%), linear-gradient(to right, rgba(0, 0, 0, 0.1), rgba(255, 255, 255, 0)), linear-gradient(to left, rgba(0, 0, 0, 0.1), rgba(255, 255, 255, 0));
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-attachment: local, local, scroll, scroll;
  background-position: left center, right center, left center, right center;
  background-size: 20px 100%, 20px 100%, 8px 100%, 8px 100%;
  box-shadow: 0 3px 0 0 rgba(51, 20, 5, 0.03);
}
.radio-pills input {
  display: none;
}
.radio-pills label, .radio-pills legend {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 8px 12px;
  margin: 0;
  font-size: 14px;
  line-height: 1.71;
  color: #2b65d9;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  border-top: 1px solid #e6e4e3;
  border-bottom: 1px solid #e6e4e3;
}
@media (min-width: 768px) {
  .radio-pills label, .radio-pills legend {
    padding: 3px 12px;
    font-size: 16px;
    line-height: 1.5;
  }
}
.radio-pills label::after, .radio-pills legend::after {
  float: right;
  height: 30px;
  margin-top: -5px;
  margin-right: -13px;
  margin-bottom: -5px;
  content: "";
  border-right: 1px solid #e6e4e3;
}
@media (min-width: 768px) {
  .radio-pills label::after, .radio-pills legend::after {
    height: 22px;
    margin-top: 0;
  }
}
.radio-pills label:first-of-type, .radio-pills legend:first-of-type {
  border-left: 1px solid #e6e4e3;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.radio-pills label:last-of-type, .radio-pills legend:last-of-type {
  border-right: 1px solid #e6e4e3;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.radio-pills input:checked + label, .radio-pills input:checked + legend {
  padding: 7px 10px;
  color: #1a1817;
  border: 2px solid #2b65d9;
  border-radius: 4px;
}
.radio-pills input:checked + label::after, .radio-pills input:checked + legend::after {
  content: none;
}
@media (min-width: 768px) {
  .radio-pills input:checked + label, .radio-pills input:checked + legend {
    padding: 2px 10px;
  }
}

.form-group {
  margin-bottom: 1rem;
}
@media (min-width: 992px) {
  .form-group {
    margin-bottom: 1.5rem;
  }
}
.form-group .error {
  font-size: 0.875rem;
}

.form-heading {
  margin-bottom: 0.5rem;
  font-family: questasans;
  font-size: 1.375rem;
  font-weight: 700;
  line-height: 1.33;
}
@media (min-width: 768px) {
  .form-heading {
    margin-bottom: 1rem;
    font-size: 1.5rem;
  }
}

label, legend {
  font-family: hkgrotesk;
  color: #403e3d;
}
label.col-form-label, legend.col-form-label {
  padding-top: 0;
}

.form-wizard .form-heading {
  margin-bottom: 1rem;
  line-height: 1.27;
  color: #1a1817;
}
@media (min-width: 992px) {
  .form-wizard .form-heading {
    margin-bottom: 1.5rem;
  }
}
.form-wizard .form-group {
  margin-bottom: 1rem;
}
@media (min-width: 992px) {
  .form-wizard .form-group {
    margin-bottom: 1.5rem;
  }
}
.form-wizard .form-group label:not(.custom-control-label):not(.form-heading), .form-wizard .form-group legend:not(.custom-control-label):not(.form-heading) {
  line-height: 1.5;
}
.form-wizard .form-group .form-text {
  font-size: 14px;
  line-height: 1.43;
}
.form-wizard .form-group .text-danger {
  margin-top: 0.5rem;
  line-height: 1.25;
}
.form-wizard .mvm-show-project-description-field .form-group {
  margin-bottom: 0.5rem;
}

.form-horizontal .form-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}
.form-horizontal .form-row.radio-row, .form-horizontal .form-row.checkbox-row {
  -ms-flex-align: start;
  align-items: flex-start;
}
.form-horizontal .form-row.radio-row .custom-control-label, .form-horizontal .form-row.checkbox-row .custom-control-label {
  width: 100%;
  cursor: pointer;
}
.form-horizontal .form-row.text-area-row {
  -ms-flex-align: start;
  align-items: flex-start;
}
.form-horizontal .form-row.text-area-row .control-label {
  margin-top: 11px;
}
.form-horizontal .control-label,
.form-horizontal .form-help-text {
  width: 100%;
}
@media (min-width: 768px) {
  .form-horizontal .control-label,
  .form-horizontal .form-help-text {
    float: right;
    margin-right: 1.25rem;
    margin-bottom: 0;
    text-align: right;
  }
}
.form-horizontal .control-label.checkbox,
.form-horizontal .form-help-text.checkbox {
  padding: 0;
}
.form-horizontal .control-label.checkbox::after, .form-horizontal .control-label.checkbox::before,
.form-horizontal .form-help-text.checkbox::after,
.form-horizontal .form-help-text.checkbox::before {
  margin: 0;
}
.form-horizontal .control-label + .form-help-text {
  margin-top: -8px;
}
@media (min-width: 768px) {
  .form-horizontal .control-label + .form-help-text {
    margin-top: 0;
  }
}

.sd-form-row-valid .form-control,
.sd-form-row-valid .btn,
.sd-form-row-valid label,
.sd-form-row-valid legend,
.sd-form-row-valid button,
.sd-form-row-valid select {
  border-color: #13c265;
}

.sd-form-row-invalid .btn {
  border-color: #eb5042;
}
.sd-form-row-invalid .form-help-text {
  display: block;
  color: #73706f;
}

.input-icon.wizard, .sd-form-row-invalid .wizard.custom-control::after {
  right: 12px;
}
.input-icon.icon-valid, .sd-form-row-invalid .icon-valid.custom-control::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath fill='none' fill-rule='evenodd' stroke='%2313c265' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M3.5 12.5l5 5 12-12'/%3E%3C/svg%3E%0A");
}
.input-icon.icon-loading, .sd-form-row-invalid .icon-loading.custom-control::after {
  width: 2rem;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 40 40' %3E%3Cpath fill='%23D0E9F4' d='M20 35c-8.284 0-15-6.716-15-15 0-8.284 6.716-15 15-15 8.284 0 15 6.716 15 15 0 8.284-6.716 15-15 15zm0-2c7.18 0 13-5.82 13-13S27.18 7 20 7 7 12.82 7 20s5.82 13 13 13z'/%3E%3Cpath fill='%231493C8' d='M29.192 10.808A12.96 12.96 0 0 0 20 7V5c4.142 0 7.892 1.679 10.607 4.393l-1.415 1.415z'%3E%3CanimateTransform attributeType='xml' attributeName='transform' type='rotate' from='0 20 20' to='360 20 20' dur='0.7s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
}

.sd-custom-control-label-group {
  width: 100%;
  line-height: 1.5rem;
}

.was-validated .sd-radio-button .custom-control-input:valid + .custom-control-label,
.was-validated .sd-checkbox-button .custom-control-input:valid + .custom-control-label {
  color: #403e3d;
}
.was-validated .sd-radio-button .custom-control-input:valid + .custom-control-label::before,
.was-validated .sd-checkbox-button .custom-control-input:valid + .custom-control-label::before {
  background-color: transparent;
  border-color: #ccc9c8;
}

.sd-radio-button,
.sd-checkbox-button {
  min-height: auto;
  border: 1px solid #e6e4e3;
  border-radius: 3px;
  box-shadow: 0 3px 0 0 rgba(51, 20, 5, 0.03);
}
.sd-radio-button:hover,
.sd-checkbox-button:hover {
  cursor: pointer;
  border-color: #3e9cfa;
  box-shadow: 0 3px 0 0 rgba(62, 156, 250, 0.25);
}
.sd-radio-button.selected,
.sd-checkbox-button.selected {
  line-height: 1.5;
  border: 2px solid #2b65d9;
  box-shadow: none;
}
.sd-radio-button.selected .custom-control-label,
.sd-checkbox-button.selected .custom-control-label {
  padding-left: 38px;
  line-height: 1.5;
  color: #1a1817;
}
.sd-radio-button.no-validation-icon .input-icon.icon-invalid, .sd-radio-button.no-validation-icon .sd-form-row-invalid .icon-invalid.custom-control::after, .sd-form-row-invalid .sd-radio-button.no-validation-icon .icon-invalid.custom-control::after,
.sd-checkbox-button.no-validation-icon .input-icon.icon-invalid,
.sd-checkbox-button.no-validation-icon .sd-form-row-invalid .icon-invalid.custom-control::after,
.sd-form-row-invalid .sd-checkbox-button.no-validation-icon .icon-invalid.custom-control::after {
  display: none;
}
.sd-radio-button.vertical,
.sd-checkbox-button.vertical {
  margin-bottom: 0.5rem;
}
.sd-radio-button.vertical:last-child,
.sd-checkbox-button.vertical:last-child {
  margin-bottom: 0;
}
.sd-radio-button.horizontal,
.sd-checkbox-button.horizontal {
  margin-left: 0.5rem;
}
.sd-radio-button.horizontal.stretched,
.sd-checkbox-button.horizontal.stretched {
  width: 100%;
}
.sd-radio-button.horizontal:first-child,
.sd-checkbox-button.horizontal:first-child {
  margin-left: 0;
}
.sd-radio-button.tiles .custom-control-input:checked ~ .custom-control-label::before,
.sd-radio-button.tiles .custom-control-input:active:not(:disabled) ~ .custom-control-label::before,
.sd-checkbox-button.tiles .custom-control-input:checked ~ .custom-control-label::before,
.sd-checkbox-button.tiles .custom-control-input:active:not(:disabled) ~ .custom-control-label::before {
  display: none;
  background: none;
}
.sd-radio-button.tiles .custom-control-input:checked ~ .custom-control-label::after,
.sd-radio-button.tiles .custom-control-input:active:not(:disabled) ~ .custom-control-label::after,
.sd-checkbox-button.tiles .custom-control-input:checked ~ .custom-control-label::after,
.sd-checkbox-button.tiles .custom-control-input:active:not(:disabled) ~ .custom-control-label::after {
  display: none;
  background-image: none;
}
.sd-radio-button.tiles .custom-control,
.sd-checkbox-button.tiles .custom-control {
  padding-left: 0;
}
.sd-radio-button.tiles .custom-checkbox .custom-control-label,
.sd-radio-button.tiles .custom-radio .custom-control-label,
.sd-checkbox-button.tiles .custom-checkbox .custom-control-label,
.sd-checkbox-button.tiles .custom-radio .custom-control-label {
  padding: 0.5rem 1rem;
}
.sd-radio-button.tiles .custom-checkbox .custom-control-label::before,
.sd-radio-button.tiles .custom-radio .custom-control-label::before,
.sd-checkbox-button.tiles .custom-checkbox .custom-control-label::before,
.sd-checkbox-button.tiles .custom-radio .custom-control-label::before {
  display: none;
}
.sd-radio-button.tiles .custom-checkbox .custom-control-label::after,
.sd-radio-button.tiles .custom-radio .custom-control-label::after,
.sd-checkbox-button.tiles .custom-checkbox .custom-control-label::after,
.sd-checkbox-button.tiles .custom-radio .custom-control-label::after {
  display: none;
}
.sd-radio-button .custom-checkbox,
.sd-radio-button .custom-radio,
.sd-checkbox-button .custom-checkbox,
.sd-checkbox-button .custom-radio {
  padding-left: 0;
}
.sd-radio-button .custom-checkbox .custom-control-label,
.sd-radio-button .custom-radio .custom-control-label,
.sd-checkbox-button .custom-checkbox .custom-control-label,
.sd-checkbox-button .custom-radio .custom-control-label {
  padding: 8px 0 8px 39px;
}
.sd-radio-button .custom-checkbox .custom-control-label::before,
.sd-radio-button .custom-radio .custom-control-label::before,
.sd-checkbox-button .custom-checkbox .custom-control-label::before,
.sd-checkbox-button .custom-radio .custom-control-label::before {
  top: 0.75rem;
  left: 14px;
}
.sd-radio-button .custom-checkbox .custom-control-label::after,
.sd-radio-button .custom-radio .custom-control-label::after,
.sd-checkbox-button .custom-checkbox .custom-control-label::after,
.sd-checkbox-button .custom-radio .custom-control-label::after {
  top: 0.75rem;
  left: 14px;
}
.sd-radio-button .custom-control-label,
.sd-checkbox-button .custom-control-label {
  width: 100%;
  line-height: 1.625;
}
.sd-radio-button .custom-control-label:hover,
.sd-checkbox-button .custom-control-label:hover {
  cursor: inherit;
}
.sd-radio-button .custom-control,
.sd-checkbox-button .custom-control {
  min-height: auto;
}

.custom-control-input.is-invalid ~ .custom-control-label {
  color: inherit;
}
.custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #ced4da;
}
.custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  border-color: #ced4da;
  box-shadow: none;
}
.custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #ced4da;
}
.custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  background-color: transparent;
  border-color: #ced4da;
}

.custom-textarea {
  min-height: 164px;
  max-height: 404px;
}

/* Selector needs to be more strict than bootstrap to override the hero */
/* stylelint-disable selector-no-qualifying-type */
select.form-control.form-control-hero:not([size]):not([multiple]) {
  height: 3.5rem;
}

/* stylelint-enable selector-no-qualifying-type */
.form-control {
  color: #403e3d;
  border: 2px solid #e6e4e3;
}
.form-control:focus {
  color: #1a1817;
  background-color: #ffffff;
  border-color: #2b65d9;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(43, 101, 217, 0.25);
}
.form-control.form-control-lg {
  padding: calc(1rem - 0.125rem) 1rem calc(1rem - 0.125rem) 1rem;
  line-height: 1.35;
}
@media (min-width: 992px) {
  .form-control.form-control-lg-lg {
    height: calc(1.35em + 2rem);
    padding: calc(1rem - 0.125rem) 1rem calc(1rem - 0.125rem) 1rem;
    font-size: 1.125rem;
    line-height: 1.35;
  }
}
.form-control.form-control-hero {
  height: auto;
  padding: calc(0.75rem - 0.125rem) 1rem;
  font-size: 1.25rem;
  line-height: 1.6;
}
.form-control.form-control-hero.custom-select {
  padding-right: calc(1rem + 0.75rem);
}

.form-control-edit-phone-number {
  cursor: pointer;
}
.form-control-edit-phone-number:-moz-read-only {
  background-color: transparent;
}
.form-control-edit-phone-number:read-only {
  background-color: transparent;
}
.form-control-edit-phone-number:read-only::-webkit-input-placeholder {
  color: #403e3d;
}
.form-control-edit-phone-number:read-only::-moz-placeholder {
  color: #403e3d;
}
.form-control-edit-phone-number:read-only:-ms-input-placeholder {
  color: #403e3d;
}
.form-control-edit-phone-number:read-only::-ms-input-placeholder {
  color: #403e3d;
}
.form-control-edit-phone-number:-moz-read-only::placeholder {
  color: #403e3d;
}
.form-control-edit-phone-number:read-only::placeholder {
  color: #403e3d;
}
.form-control-edit-phone-number:active ~ .icon-modify-verification-phone {
  -webkit-filter: brightness(0) saturate(100%) invert(55%) sepia(41%) saturate(3159%) hue-rotate(109deg) brightness(100%) contrast(85%);
  filter: brightness(0) saturate(100%) invert(55%) sepia(41%) saturate(3159%) hue-rotate(109deg) brightness(100%) contrast(85%);
}
.form-control-edit-phone-number:hover ~ .icon-modify-verification-phone {
  -webkit-filter: brightness(0) saturate(100%) invert(38%) sepia(70%) saturate(1062%) hue-rotate(116deg) brightness(92%) contrast(101%);
  filter: brightness(0) saturate(100%) invert(38%) sepia(70%) saturate(1062%) hue-rotate(116deg) brightness(92%) contrast(101%);
}
.form-control-edit-phone-number.is-valid, .form-control-edit-phone-number.is-invalid {
  background-image: unset;
}
.form-control-edit-phone-number.is-valid ~ .icon-modify-verification-phone {
  -webkit-filter: brightness(0) saturate(100%) invert(70%) sepia(41%) saturate(5205%) hue-rotate(103deg) brightness(95%) contrast(85%);
  filter: brightness(0) saturate(100%) invert(70%) sepia(41%) saturate(5205%) hue-rotate(103deg) brightness(95%) contrast(85%);
}
.form-control-edit-phone-number.is-invalid ~ .icon-modify-verification-phone {
  -webkit-filter: brightness(0) saturate(100%) invert(52%) sepia(29%) saturate(4634%) hue-rotate(329deg) brightness(89%) contrast(109%);
  filter: brightness(0) saturate(100%) invert(52%) sepia(29%) saturate(4634%) hue-rotate(329deg) brightness(89%) contrast(109%);
}

.custom-radio .custom-control-label,
.custom-checkbox .custom-control-label {
  font-size: 1rem;
  color: #403e3d;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 2px;
}

.custom-radio .custom-control-label {
  padding: 0;
  font-size: 1rem;
}
.custom-radio .custom-control-label:hover {
  cursor: pointer;
}
.custom-radio .custom-control-label::before {
  margin: 0;
}
.custom-radio .custom-control-label::after {
  margin: 0;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  display: inline-block;
  width: 1.125rem;
  height: 1.125rem;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='2.6' fill='%2313c265'/%3E%3C/svg%3E");
}
.custom-radio .custom-control-input:active:not(:disabled) ~ .custom-control-label::after {
  display: inline-block;
  width: 1.125rem;
  height: 1.125rem;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='2.6' fill='%2313c265'/%3E%3C/svg%3E");
}

.custom-control-inline .custom-control-label {
  margin-left: 0.625rem;
}

.input-wrapper {
  display: -ms-flexbox;
  display: flex;
}
.input-wrapper .input-group:first-child .form-control {
  border-radius: 0.25rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-wrapper .input-group:last-child .form-control {
  border-radius: 0.25rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control .custom-control-label:hover {
  cursor: pointer;
}

/* The text-transform needs to be important, otherwise it is not applied on IE10 */
/* stylelint-disable declaration-no-important */
::-webkit-input-placeholder { /* Recent browsers */
  text-transform: none !important;
}
::-moz-placeholder { /* Recent browsers */
  text-transform: none !important;
}
:-ms-input-placeholder { /* Recent browsers */
  text-transform: none !important;
}
::-ms-input-placeholder { /* Recent browsers */
  text-transform: none !important;
}
::placeholder { /* Recent browsers */
  text-transform: none !important;
}

@media (min-width: 768px) {
  .form-control.hide-placeholder::-webkit-input-placeholder {
    color: #fff;
  }
  .form-control.hide-placeholder::-moz-placeholder {
    color: #fff;
  }
  .form-control.hide-placeholder:-ms-input-placeholder {
    color: #fff;
  }
  .form-control.hide-placeholder::-ms-input-placeholder {
    color: #fff;
  }
  .form-control.hide-placeholder::placeholder {
    color: #fff;
  }
}
/* stylelint-enable declaration-no-important */
.btn-input-group {
  border-width: 2px;
}

/* stylelint-disable declaration-no-important */
.uib-day.ng-leave-prepare {
  display: none !important;
}

.sd-image-radio-button .custom-control-label {
  padding: 10px 15px !important;
}

/* stylelint-enable declaration-no-important */
.datepicker-input-icon {
  padding-right: 0;
  padding-left: 29px;
  font-size: 13px;
  line-height: 1.5rem;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Cpath fill='%23999' fill-rule='nonzero' d='M17 8H3v9h14V8zM3 7h14V4h-1V3h1a1 1 0 0 1 1 1v13a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h1v1H3v3zm4-3V3h6v1H7zM5 1.5a.5.5 0 0 1 1 0v4a.5.5 0 0 1-1 0v-4zm9 0a.5.5 0 1 1 1 0v4a.5.5 0 1 1-1 0v-4z'/%3E%3C/svg%3E%0A") no-repeat left 6px center;
  border-radius: 0;
}

@media (min-width: 768px) {
  .contact-details-container {
    border-left: none;
  }
  .contact-details-container .row-items {
    border-left: solid 1px #e6e4e3;
  }
}

.notification-message-box {
  position: absolute;
  z-index: 1;
  padding: 1rem;
  margin-top: 4px;
  font-size: 0.875rem;
  color: #73706f;
  background: #ffffff;
  border: 1px solid #e6e4e3;
  border-radius: 4px;
  box-shadow: 0.5rem 0.5rem 0 0 rgba(51, 20, 5, 0.03);
}

.move-phone-verification-container {
  /* stylelint-disable */
  /* Chrome, Safari, Edge, Opera */
  /* Firefox */
  /* stylelint-enable */
}
.move-phone-verification-container input::-webkit-outer-spin-button,
.move-phone-verification-container input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.move-phone-verification-container input[type=number] {
  -moz-appearance: textfield;
}

html,
body {
  background-color: #ffffff;
}

body {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
}

.sd-outer-container {
  z-index: 1;
  max-width: 1440px;
  margin: 0 auto;
  overflow-x: hidden;
}

.b2c-page-container {
  margin-top: 2rem;
}
@media (min-width: 992px) {
  .b2c-page-container {
    margin-top: 3.5rem;
  }
}

.main {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.navbar,
.footer {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.row.no-wrap {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  overflow-x: auto;
}
.row.no-wrap .col,
.row.no-wrap > [class^=col-] {
  min-width: 270px;
}

.static-page-container {
  margin-top: 2rem;
  overflow-x: hidden;
}
@media (min-width: 768px) {
  .static-page-container {
    margin-top: 3.5rem;
  }
}

.component-container {
  margin: 2rem 0;
}
@media (min-width: 992px) {
  .component-container {
    margin: 2rem 0;
  }
}

.component-container-large {
  margin: 3rem 0;
}
@media (min-width: 992px) {
  .component-container-large {
    margin: 6rem 0;
  }
}

.panel-container {
  margin: 1rem 0;
}
@media (min-width: 992px) {
  .panel-container {
    margin: 2rem 0;
  }
}

.call-to-action-container {
  margin: 1.5rem 0;
}
@media (min-width: 992px) {
  .call-to-action-container {
    margin: 2.5rem 0;
  }
}

.frontpage-component-container {
  margin: 3rem 0;
}
@media (min-width: 992px) {
  .frontpage-component-container {
    margin: 6rem 0;
  }
}

.container-company-logos {
  margin-top: 2.5rem;
}
@media (min-width: 992px) {
  .container-company-logos {
    margin-top: 5rem;
  }
}

.component-container:last-child,
.component-container-large:last-child,
.panel-container:last-child,
.frontpage-component-container:last-child {
  margin-bottom: 0;
}

@media (max-width: 991.98px) {
  .row.row-narrow {
    margin-right: -8px;
    margin-left: -8px;
  }
  .row.row-narrow .col,
  .row.row-narrow > [class^=col-] {
    padding-right: 8px;
    padding-left: 8px;
  }
}

.hero-container {
  position: relative;
  height: 552px;
  padding-top: 96px;
  padding-bottom: 4rem;
}
@media (min-width: 992px) {
  .hero-container {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    height: 666px;
    padding-top: 11rem;
  }
}

.hero-text {
  text-shadow: 0 0 16px rgba(0, 0, 0, 0.6);
}
@media (min-width: 992px) {
  .hero-text {
    text-shadow: none;
  }
}
.hero-text.hero-title {
  font-size: 38px;
  line-height: 44px;
  color: #ffffff;
}
@media (min-width: 992px) {
  .hero-text.hero-title {
    font-size: 48px;
    line-height: 56px;
    color: #1a1817;
  }
}
.hero-text.hero-description {
  color: #ffffff;
}
@media (min-width: 992px) {
  .hero-text.hero-description {
    color: #73706f;
  }
}

@media (min-width: 768px) {
  .postcode-hero-form-container .postcode-hero-panel {
    /* stylelint-disable declaration-no-important */
    padding: 30px !important;
    /* stylelint-enable declaration-no-important */
  }
}

@media (min-width: 768px) {
  .homepage-hero {
    min-height: 470px;
  }
}
.homepage-hero .hero-image-homepage-container {
  margin-right: -15px;
  margin-left: -15px;
}
@media (min-width: 992px) {
  .homepage-hero .hero-image-homepage-container {
    position: absolute;
    top: -8px;
    right: 0;
    width: 470px;
    margin-top: 0;
    margin-right: 0;
    margin-left: 0;
  }
}
.homepage-hero .hero-image-homepage-container .hero-image-homepage {
  position: relative;
  height: 478px;
  margin-top: -72px;
  margin-left: -96px;
  background-image: url("../images/move/hero-move-redesign.jpg");
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
}
.homepage-hero .hero-image-homepage-container .hero-image-homepage::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: "";
  background-image: linear-gradient(to bottom, rgba(46, 74, 153, 0), rgba(46, 74, 153, 0.3));
}
@media (min-width: 992px) {
  .homepage-hero .hero-image-homepage-container .hero-image-homepage {
    margin-top: 0;
    margin-left: 0;
  }
  .homepage-hero .hero-image-homepage-container .hero-image-homepage::before {
    background-image: none;
    box-shadow: 8px 8px 0 0 rgba(20, 48, 102, 0.03);
  }
}
.homepage-hero .postcode-hero-panel form {
  /* stylelint-disable selector-no-qualifying-type */
  /* stylelint-enable selector-no-qualifying-type */
}
.homepage-hero .postcode-hero-panel form .hero-postcode-input {
  text-transform: uppercase;
}
.homepage-hero .postcode-hero-panel form.au-moving24-com .hero-postcode-input, .homepage-hero .postcode-hero-panel form.za-moving24-com .hero-postcode-input {
  text-transform: none;
}
@media (min-width: 768px) {
  .homepage-hero .postcode-hero-panel {
    width: 570px;
  }
}
.homepage-hero .custom-margin-hero {
  margin-top: -282px;
}
@media (min-width: 992px) {
  .homepage-hero .custom-margin-hero {
    margin-top: 0;
  }
}

.hero-category-and-sea {
  margin-bottom: 0.5rem;
}
@media (min-width: 992px) {
  .hero-category-and-sea {
    height: 509px;
  }
}
.hero-category-and-sea .hero-content {
  margin-top: 24px;
}
@media (min-width: 992px) {
  .hero-category-and-sea .hero-content {
    margin-top: 56px;
  }
}
.hero-category-and-sea .hero-content h1 {
  font-size: 38px;
  line-height: 1.16;
}
@media (min-width: 992px) {
  .hero-category-and-sea .hero-content h1 {
    font-size: 48px;
    line-height: 1.17;
  }
}
.hero-category-and-sea .hero-content h1 + p {
  font-size: 18px;
}
@media (min-width: 992px) {
  .hero-category-and-sea .hero-content h1 + p {
    font-size: 20px;
  }
}
@media (min-width: 768px) {
  .hero-category-and-sea .postcode-hero-form-container:not(.full-width-hero-form) > .postcode-hero-panel {
    width: 570px;
  }
}
@media (min-width: 768px) {
  .hero-category-and-sea .full-width-hero-form .panel {
    max-width: 610px;
  }
  .hero-category-and-sea .full-width-hero-form .panel div {
    min-width: 260px;
  }
}
@media (min-width: 992px) {
  .hero-category-and-sea .form-wrapper {
    position: absolute;
    top: 16px;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
.hero-category-and-sea .illustration-wrapper {
  text-align: center;
}
.hero-category-and-sea .illustration-wrapper img {
  width: 420px;
}
@media (min-width: 992px) {
  .hero-category-and-sea .illustration-wrapper {
    position: absolute;
    top: 16px;
    right: -135px;
    bottom: 0;
    left: 0;
    text-align: right;
  }
  .hero-category-and-sea .illustration-wrapper img {
    width: 741px;
  }
}

.blog-overview-hero-seo {
  margin-bottom: 0.5rem;
}
@media (min-width: 992px) {
  .blog-overview-hero-seo {
    height: 470px;
  }
}
.blog-overview-hero-seo .hero-content {
  margin-top: 16px;
}
@media (min-width: 992px) {
  .blog-overview-hero-seo .hero-content {
    margin-top: 76px;
  }
}
.blog-overview-hero-seo .hero-content .hero-title {
  font-size: 38px;
  line-height: 44px;
}
@media (min-width: 992px) {
  .blog-overview-hero-seo .hero-content .hero-title {
    font-size: 48px;
    line-height: 56px;
  }
}
.blog-overview-hero-seo .illustration-wrapper {
  text-align: center;
}
@media (min-width: 992px) {
  .blog-overview-hero-seo .illustration-wrapper {
    position: absolute;
    top: 0;
    right: -135px;
  }
}

.blog-category-hero-seo .hero-text {
  text-shadow: none;
}
.blog-category-hero-seo .hero-text.hero-title {
  color: #1a1817;
}
.blog-category-hero-seo .hero-description > p {
  margin-bottom: 0;
}

.hero-directory {
  background-color: #f2f7fc;
}
.hero-directory .hero-content {
  margin: 16px 0;
}
@media (min-width: 992px) {
  .hero-directory .hero-content {
    margin: 48px 41px;
  }
}
@media (max-width: 767.98px) {
  .hero-directory .hero-content .postcode-hero-text p {
    -ms-flex-pack: center;
    justify-content: center;
  }
}
.hero-directory .illustration-wrapper {
  position: relative;
  top: 0;
  padding-top: 16px;
  text-align: center;
}
.hero-directory .illustration-wrapper img {
  width: 195px;
}
@media (min-width: 992px) {
  .hero-directory .illustration-wrapper {
    position: absolute;
    top: 50%;
    right: 80px;
    left: 0;
    padding-top: 0;
    text-align: right;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  .hero-directory .illustration-wrapper img {
    width: 255px;
  }
}

.hero-b2b-image {
  position: relative;
  height: 600px;
  margin-right: -15px;
  margin-left: -15px;
}
.hero-b2b-image::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: "";
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0));
}
@media (min-width: 1440px) {
  .hero-b2b-image {
    margin-right: -135px;
    margin-left: -135px;
  }
}
.hero-b2b-image img {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover; /* or object-fit: contain; */
}

.hero-b2b-body {
  margin-top: -456px;
}
@media (min-width: 768px) {
  .hero-b2b-body .hero-form-container {
    max-width: 470px;
  }
}
@media (min-width: 768px) {
  .hero-b2b-body .panel {
    padding: 3rem;
  }
}
.hero-b2b-body .form-control {
  height: 3.5rem;
}
.hero-b2b-body .hero-subtitle {
  max-width: 470px;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.625;
  text-shadow: 0 0 16px rgba(0, 0, 0, 0.6);
}
@media (min-width: 768px) {
  .hero-b2b-body .hero-subtitle {
    font-size: 1.25rem;
    line-height: 1.625;
  }
}
.hero-b2b-body .hero-title {
  max-width: 640px;
  font-family: questasans;
  font-size: 2.375rem;
  line-height: 2.75rem;
  text-shadow: 0 0 16px rgba(0, 0, 0, 0.6);
}
@media (min-width: 768px) {
  .hero-b2b-body .hero-title {
    margin-bottom: 18px;
    font-size: 3rem;
    line-height: 3.5rem;
  }
}

.how-it-works .background-dots {
  background-position: 0 1rem;
  background-size: 268px 343px;
}
@media (max-width: 991.98px) {
  .how-it-works .background-dots {
    background-position: 0 6px;
    background-size: 147px 333px;
  }
}
.how-it-works .how-to-request-quotes-container {
  background-color: #f2f7fc;
}
@media (max-width: 991.98px) {
  .how-it-works .how-to-request-quotes-container {
    background: linear-gradient(to bottom, #fff 0%, #fff 76px, #f2f7fc 76px, #f2f7fc 100%);
  }
}
.how-it-works .how-to-request-quotes-container .video {
  width: 100%;
  aspect-ratio: 16/9;
}
.how-it-works .how-to-request-quotes-container .circle.circle-hiw {
  width: 20px;
  height: 20px;
  font-size: 0.875rem;
}

.check-white {
  display: inline-block;
  width: 1.25rem;
  min-width: 1.25rem;
  height: 1.25rem;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath fill='none' fill-rule='evenodd' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M3.5 12.5l5 5 12-12'/%3E%3C/svg%3E%0A");
}

.check-secondary {
  display: inline-block;
  width: 1.5rem;
  min-width: 1.5rem;
  height: 1.5rem;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath fill='none' fill-rule='evenodd' stroke='%232b65d9' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M3.5 12.5l5 5 12-12'/%3E%3C/svg%3E%0A");
}

.check-secondary-outlined {
  display: inline-block;
  width: 1rem;
  min-width: 1rem;
  height: 1rem;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E%3Cpath fill='none' fill-rule='evenodd' stroke='%232b65d9' stroke-linecap='round' stroke-linejoin='round' d='M8 14.5A6.5 6.5 0 1 0 1.5 8c0 3.5 2.397 4.816 4 6 .377.278-2.535-1.23-2-1 .997.428 3 1.5 4.5 1.5zm-3-6 2.5 2C8.5 8.833 9.667 7.333 11 6'/%3E%3C/svg%3E%0A");
}

.check-success {
  display: inline-block;
  width: 1.5rem;
  min-width: 1.5rem;
  height: 1.5rem;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath fill='none' fill-rule='evenodd' stroke='%2313c265' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M3.5 12.5l5 5 12-12'/%3E%3C/svg%3E%0A");
}

.profile-icon, .testimonial-icon {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border: 1px solid #e6e4e3;
}

.testimonial-icon {
  -ms-flex: 0 0 48px;
  flex: 0 0 48px;
  width: 48px;
  height: 48px;
}

.icon-circle {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 6rem;
  height: 6rem;
  border-radius: 50%;
}
.icon-circle img {
  width: 4rem;
  height: 4rem;
}
@media (min-width: 576px) {
  .icon-circle {
    width: 7.5rem;
    height: 7.5rem;
  }
  .icon-circle img {
    width: 5rem;
    height: 5rem;
  }
}

.icon-search {
  position: absolute;
  top: 50%;
  right: 12px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.icon-modify-verification-phone {
  position: absolute;
  top: 50%;
  right: 0;
  z-index: 10;
  cursor: pointer;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.icon-modify-verification-phone:active {
  -webkit-filter: brightness(0) saturate(100%) invert(55%) sepia(41%) saturate(3159%) hue-rotate(109deg) brightness(100%) contrast(85%);
  filter: brightness(0) saturate(100%) invert(55%) sepia(41%) saturate(3159%) hue-rotate(109deg) brightness(100%) contrast(85%);
}
.icon-modify-verification-phone:hover {
  -webkit-filter: brightness(0) saturate(100%) invert(38%) sepia(70%) saturate(1062%) hue-rotate(116deg) brightness(92%) contrast(101%);
  filter: brightness(0) saturate(100%) invert(38%) sepia(70%) saturate(1062%) hue-rotate(116deg) brightness(92%) contrast(101%);
}

.company-logo-container-wrapper {
  max-width: 270px;
}
.company-logo-container-wrapper .company-logo-container {
  height: 84px;
  padding: 14px 7px;
  border: 1px solid #e6e4e3;
}
.company-logo-container-wrapper .company-logo-container img {
  max-width: 100%;
  max-height: 56px;
}
@media (min-width: 768px) {
  .company-logo-container-wrapper .company-logo-container {
    height: 130px;
    padding: 20px 18px;
  }
  .company-logo-container-wrapper .company-logo-container img {
    max-height: 80px;
  }
}

.companyprofile-logo-container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
  height: 160px;
  background-color: #ffffff;
  border: 1px solid #e6e4e3;
}
.companyprofile-logo-container.pro {
  height: 128px;
}
@media (min-width: 768px) {
  .companyprofile-logo-container.pro {
    height: 188px;
  }
}
.companyprofile-logo-container.company-pro-list-logo {
  width: 176px;
  height: 98px;
  padding: 1rem 0.875rem;
}
@media (max-width: 575.98px) {
  .companyprofile-logo-container.company-pro-list-logo {
    width: 80px;
    height: 80px;
    padding: 0.875rem 0.375rem;
  }
}
.companyprofile-logo-container.selected-company-list-logo {
  width: 176px;
  height: 138px;
  padding: 1.5rem 0.75rem;
}
@media (max-width: 575.98px) {
  .companyprofile-logo-container.selected-company-list-logo {
    width: 80px;
    height: 80px;
    padding: 0.75rem 0.5rem;
  }
}
.companyprofile-logo-container img {
  -o-object-fit: contain;
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
}

.object-contain {
  -o-object-fit: contain;
  object-fit: contain;
}

.object-cover {
  -o-object-fit: cover;
  object-fit: cover;
}

.directory-top-banner {
  height: 25vh;
  max-height: 320px;
}
.directory-top-banner img {
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.list-group-check {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  list-style: none;
}
.list-group-check .list-group-check-item {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: stretch;
  align-items: stretch;
  padding-left: 28px;
  margin-bottom: 0;
  font-size: 18px;
  line-height: 1.45;
  border: 0;
}
.list-group-check .list-group-check-item::before {
  position: absolute;
  top: 3.05px;
  left: 0;
  display: inline-block;
  width: 20px;
  height: 20px;
  content: "";
  border: 0;
}
@media (min-width: 768px) {
  .list-group-check .list-group-check-item {
    font-size: 18px;
    line-height: 1.45;
  }
  .list-group-check .list-group-check-item::before {
    top: 3.05px;
  }
}
.list-group-check .list-group-check-item.list-group-check-item-success::before {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='none' fill-rule='evenodd' stroke='%2313c265' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 9.5L7.5 15 18 4.5'/%3E%3C/svg%3E%0A");
  background-size: 20px 20px;
}
.list-group-check .list-group-check-item.list-group-check-item-secondary::before {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='none' fill-rule='evenodd' stroke='%232b65d9' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 9.5L7.5 15 18 4.5'/%3E%3C/svg%3E%0A");
  background-size: 20px 20px;
}
.list-group-check .list-group-check-item.list-group-check-item-gray-500::before {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='none' fill-rule='evenodd' stroke='%23a6a2a1' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 9.5L7.5 15 18 4.5'/%3E%3C/svg%3E%0A");
  background-size: 20px 20px;
}
.list-group-check .list-group-check-item:not(:last-of-type) {
  margin-bottom: 4px;
}

.category-list {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}
.category-list li {
  margin-bottom: 0;
}
@media (min-width: 992px) {
  .category-list li {
    margin-bottom: 0.5rem;
  }
}
.category-list li a {
  display: block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #403e3d;
}
@media (min-width: 992px) {
  .category-list li a {
    padding: 0;
  }
}
.category-list + .category-list li:last-child {
  margin-bottom: 0;
}

.nap-items-list .nap-item {
  margin-left: 0.5rem;
}
.nap-items-list .nap-item:first-child {
  margin-left: 0;
}

.loader {
  width: 2.5rem;
  height: 2.5rem;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 40 40' %3E%3Cpath fill='%23f2f7fc' d='M20 35c-8.284 0-15-6.716-15-15 0-8.284 6.716-15 15-15 8.284 0 15 6.716 15 15 0 8.284-6.716 15-15 15zm0-2c7.18 0 13-5.82 13-13S27.18 7 20 7 7 12.82 7 20s5.82 13 13 13z'/%3E%3Cpath fill='%232b65d9' d='M29.192 10.808A12.96 12.96 0 0 0 20 7V5c4.142 0 7.892 1.679 10.607 4.393l-1.415 1.415z'%3E%3CanimateTransform attributeType='xml' attributeName='transform' type='rotate' from='0 20 20' to='360 20 20' dur='0.7s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
}

.iframe-loading {
  height: 100%;
  min-height: 400px;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 40 40' %3E%3Cpath fill='%23D0E9F4' d='M20 35c-8.284 0-15-6.716-15-15 0-8.284 6.716-15 15-15 8.284 0 15 6.716 15 15 0 8.284-6.716 15-15 15zm0-2c7.18 0 13-5.82 13-13S27.18 7 20 7 7 12.82 7 20s5.82 13 13 13z'/%3E%3Cpath fill='%231493C8' d='M29.192 10.808A12.96 12.96 0 0 0 20 7V5c4.142 0 7.892 1.679 10.607 4.393l-1.415 1.415z'%3E%3CanimateTransform attributeType='xml' attributeName='transform' type='rotate' from='0 20 20' to='360 20 20' dur='0.7s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E") center center no-repeat;
  background-size: 2.5rem 2.5rem;
}

.btn-loading {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.btn-loading::after {
  display: block;
  width: calc(1rem * 1);
  height: calc(1rem * 1);
  margin-right: calc(calc(calc(1rem * 1) + 0.5rem) * -1);
  margin-left: 0.5rem;
  content: "";
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' %3E%3Cpath fill='%23CCC9C8' d='M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zm0 1.067a6.933 6.933 0 1 0 0 13.866A6.933 6.933 0 0 0 8 1.067z'/%3E%3Cpath fill='%232B65D9' d='M8 0c2.21 0 4.21.896 5.657 2.343l-.754.755A6.912 6.912 0 0 0 8 1.067V0z'%3E%3CanimateTransform attributeType='xml' attributeName='transform' type='rotate' from='0 8 8' to='360 8 8' dur='0.7s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E") no-repeat;
}

.form-loader {
  padding: 20px 20px 262px;
  margin: -20px;
  background-color: #faf8f7;
}
@media (min-width: 768px) {
  .form-loader {
    margin: -32px;
  }
}
@media (min-width: 992px) {
  .form-loader {
    padding: 50px 50px 400px;
    margin: -50px;
  }
}

.sky-loading-indicator-loader {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  background-color: #faf8f7;
}

.loading-icon {
  width: 46px;
  height: 46px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 40 40' %3E%3Cpath fill='%23e6e4e3' d='M20 35c-8.284 0-15-6.716-15-15 0-8.284 6.716-15 15-15 8.284 0 15 6.716 15 15 0 8.284-6.716 15-15 15zm0-2c7.18 0 13-5.82 13-13S27.18 7 20 7 7 12.82 7 20s5.82 13 13 13z'/%3E%3Cpath fill='%232b65d9' d='M29.192 10.808A12.96 12.96 0 0 0 20 7V5c4.142 0 7.892 1.679 10.607 4.393l-1.415 1.415z'%3E%3CanimateTransform attributeType='xml' attributeName='transform' type='rotate' from='0 20 20' to='360 20 20' dur='0.7s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
  background-size: 46px;
}

.b2b-loading-container {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  text-align: center;
  background: #ffffff;
}

.loading-box {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
  background-color: #faf8f7 !important;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 40 40' %3E%3Cpath fill='%23e6e4e3' d='M20 35c-8.284 0-15-6.716-15-15 0-8.284 6.716-15 15-15 8.284 0 15 6.716 15 15 0 8.284-6.716 15-15 15zm0-2c7.18 0 13-5.82 13-13S27.18 7 20 7 7 12.82 7 20s5.82 13 13 13z'/%3E%3Cpath fill='%232b65d9' d='M29.192 10.808A12.96 12.96 0 0 0 20 7V5c4.142 0 7.892 1.679 10.607 4.393l-1.415 1.415z'%3E%3CanimateTransform attributeType='xml' attributeName='transform' type='rotate' from='0 20 20' to='360 20 20' dur='0.7s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 48px;
}
.loading-box > div {
  opacity: 0.1;
}

.menu-links [class*=col-] {
  margin-bottom: 1rem;
}
.menu-links [class*=col-]:last-child {
  margin-bottom: 0;
}
@media (min-width: 992px) {
  .menu-links [class*=col-] {
    margin-bottom: 0;
  }
}
.menu-links .col-6:last-child,
.menu-links .col-6:nth-last-child(2) {
  margin-bottom: 0;
}
.menu-links .col-6:last-child li:last-child,
.menu-links .col-6:nth-last-child(2) li:last-child {
  margin-bottom: 0;
}
.menu-links .menu-nav {
  -ms-flex-direction: column;
  flex-direction: column;
  margin-bottom: 0;
}
.menu-links .menu-nav li a {
  display: inline-block;
  padding: 0.5rem 0;
}
@media (min-width: 992px) {
  .menu-links .menu-nav li {
    margin-bottom: 0.5rem;
  }
  .menu-links .menu-nav li a {
    padding: 0;
  }
}
@media (min-width: 992px) {
  .menu-links .menu-nav li:last-child {
    margin-bottom: 0;
  }
}
.menu-links .menu-nav a {
  color: #403e3d;
}

.modal-content {
  padding: 20px;
  border-radius: 0;
}
@media (min-width: 768px) {
  .modal-content {
    padding: 48px;
  }
}

.modal-header {
  margin-bottom: 0.5rem;
}
@media (min-width: 768px) {
  .modal-header {
    margin-bottom: 1rem;
  }
}

.navbar {
  z-index: 1;
  line-height: 1.33;
  color: #403e3d;
  background-color: #ffffff;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.08), 0 0 4px 0 rgba(0, 0, 0, 0.16);
}
.navbar .container, .navbar .b2c-page-container, .navbar .hero-container {
  padding: 0 15px;
}
.navbar .navbar-brand {
  line-height: 1;
}
.navbar.navbar-border-bottom {
  border-bottom: solid 1px rgba(115, 107, 103, 0.15);
}

@media (min-width: 992px) {
  .navbar-nav:not(.navbar-open-on-click) .dropdown > .dropdown-menu {
    display: none;
  }
  .navbar-nav:not(.navbar-open-on-click) .dropdown:hover > .dropdown-menu {
    display: block;
  }
  .navbar-open-on-click .nav-item.show .dropdown-toggle::after {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}
.navbar-toggler {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding: 0;
  font-size: 17px;
  font-weight: 700;
  border: 0;
}
.navbar-toggler.open {
  color: #2b65d9;
}
.navbar-toggler.open .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath fill='none' fill-rule='evenodd' stroke='%232b65d9' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M3.1 5.9c1.93.21 4.562.247 7.897.108C16 5.8 19.765 6.115 20 6.1c.326-.02.659-.054 1-.1M3 12.2c-.112-.211.222-.311 1-.3 3.31.047 8.782.199 11 .1 3-.133 5-.133 6 0M3 18c-.667-.067.833-.1 4.5-.1 1.949 0 4.08.181 6.5.2 1.422.011 3.755-.022 7-.1'/%3E%3C/svg%3E");
}
@media only screen and (max-width: 360px) {
  .navbar-toggler .header-seo-hidden-menu {
    display: none;
  }
}
.navbar-toggler .navbar-toggler-icon {
  width: 1.75rem;
  height: 1.5rem;
  margin-left: 0.25rem;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath fill='none' fill-rule='evenodd' stroke='%231a1817' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M3.1 5.9c1.93.21 4.562.247 7.897.108C16 5.8 19.765 6.115 20 6.1c.326-.02.659-.054 1-.1M3 12.2c-.112-.211.222-.311 1-.3 3.31.047 8.782.199 11 .1 3-.133 5-.133 6 0M3 18c-.667-.067.833-.1 4.5-.1 1.949 0 4.08.181 6.5.2 1.422.011 3.755-.022 7-.1'/%3E%3C/svg%3E");
}

.nav-item {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  font-size: 1rem;
  color: #403e3d;
}
@media (min-width: 992px) {
  .nav-item {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
}
.nav-item .nav-link {
  padding: 0;
  font-weight: 700;
  color: #403e3d;
}
@media (min-width: 992px) {
  .nav-item {
    margin-right: 1.5rem;
  }
}
.nav-item:last-child {
  margin-right: 0;
}
.nav-item .dropdown-toggle::after {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  margin-top: 3px;
  vertical-align: inherit;
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E%3Cpath fill='none' fill-rule='evenodd' stroke='%23403e3d' stroke-linecap='round' stroke-linejoin='round' d='M2 5.5l6 6 6-6'/%3E%3C/svg%3E");
  border: 0;
}
@media (hover: hover) {
  .nav-item:hover .nav-link {
    color: #2b65d9 !important;
  }
  .nav-item:hover .dropdown-toggle::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E%3Cpath fill='none' fill-rule='evenodd' stroke='%232b65d9' stroke-linecap='round' stroke-linejoin='round' d='M2 5.5l6 6 6-6'/%3E%3C/svg%3E");
  }
}
@media (hover: none) {
  .nav-item.dropdown.show .nav-link {
    color: #2b65d9;
  }
  .nav-item.dropdown.show .dropdown-toggle::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E%3Cpath fill='none' fill-rule='evenodd' stroke='%232b65d9' stroke-linecap='round' stroke-linejoin='round' d='M2 5.5l6 6 6-6'/%3E%3C/svg%3E");
  }
}

.dropdown-menu {
  padding: 0.75rem 0;
  margin-top: 1rem;
  border-radius: 0;
}
.dropdown-menu .dropdown-item {
  padding: 0.5rem 1.5rem;
}
.dropdown-menu .dropdown-item:active {
  color: #2b65d9;
  background-color: #f2f7fc;
}
.dropdown-menu .dropdown-divider {
  margin: 0.5rem 1rem 0.75rem;
  border-top: 1px solid #e6e4e3;
}
@media (min-width: 992px) {
  .dropdown-menu .dropdown-divider {
    margin: 0.5rem 1rem;
  }
}

@media (min-width: 992px) {
  .dropdown-menu {
    position: relative;
    margin-top: -0.5rem;
    margin-bottom: 0;
    margin-left: -1.5rem;
    background: #fff;
    border: solid 1px rgba(115, 107, 103, 0.15);
    box-shadow: 0 4px 16px 0 rgba(26, 24, 23, 0.15);
  }
  .dropdown-menu::after, .dropdown-menu::before {
    position: absolute;
    bottom: 100%;
    left: 3rem;
    width: 0;
    height: 0;
    pointer-events: none;
    content: " ";
    border: solid transparent;
  }
  .dropdown-menu::after {
    margin-left: -12px;
    border-color: transparent;
    border-width: 12px;
    border-bottom-color: #fff;
  }
  .dropdown-menu::before {
    margin-left: -13px;
    border-color: transparent;
    border-width: 13px;
    border-bottom-color: #e6e4e3;
  }
  .dropdown-menu .dropdown-item:hover {
    color: #2b65d9;
    background-color: #f2f7fc;
  }
}
.panel {
  position: relative;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 0;
}
@media (min-width: 768px) {
  .panel {
    padding: 32px;
  }
}
@media (min-width: 992px) {
  .panel {
    padding: 50px;
  }
}
.panel.panel-shadowed {
  margin-bottom: 0.5rem;
  box-shadow: 0.5rem 0.5rem 0 0 rgba(51, 20, 5, 0.03);
}
.panel.panel-bordered {
  border: 1px solid #e6e4e3;
}
.panel.panel-top {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.panel.panel-middle {
  border-top: 0;
  border-radius: 0;
}
.panel.panel-bottom {
  border-top: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

a.panel {
  display: -ms-flexbox;
  display: flex;
  color: #1a1817;
}
a.panel:hover {
  color: #1a1817;
  text-decoration: none;
}
a.panel:active {
  font-weight: 400;
}
a.panel.panel-bordered:active {
  border-color: #ccc9c8;
}
a.panel.panel-shadowed:active {
  box-shadow: 0.5rem 0.5rem 0 0 rgba(20, 48, 102, 0.05);
}

.panel-small {
  padding: 20px !important;
}

.panel-medium {
  padding: 32px !important;
}

.panel-large {
  padding: 50px !important;
}

@media (min-width: 576px) {
  .panel-sm-small {
    padding: 20px !important;
  }
  .panel-sm-medium {
    padding: 32px !important;
  }
  .panel-sm-large {
    padding: 50px !important;
  }
}
@media (min-width: 768px) {
  .panel-md-small {
    padding: 20px !important;
  }
  .panel-md-medium {
    padding: 32px !important;
  }
  .panel-md-large {
    padding: 50px !important;
  }
}
@media (min-width: 992px) {
  .panel-lg-small {
    padding: 20px !important;
  }
  .panel-lg-medium {
    padding: 32px !important;
  }
  .panel-lg-large {
    padding: 50px !important;
  }
}
@media (min-width: 1200px) {
  .panel-xl-small {
    padding: 20px !important;
  }
  .panel-xl-medium {
    padding: 32px !important;
  }
  .panel-xl-large {
    padding: 50px !important;
  }
}
.border-top {
  border-top: 1px solid #e6e4e3 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-bottom {
  border-bottom: 1px solid #e6e4e3 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-right {
  border-right: 1px solid #e6e4e3 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-left {
  border-left: 1px solid #e6e4e3 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

@media (min-width: 576px) {
  .border-top-sm {
    border-top: 1px solid #e6e4e3 !important;
  }
  .border-top-sm-0 {
    border-top: 0 !important;
  }
  .border-bottom-sm {
    border-bottom: 1px solid #e6e4e3 !important;
  }
  .border-bottom-sm-0 {
    border-bottom: 0 !important;
  }
  .border-right-sm {
    border-right: 1px solid #e6e4e3 !important;
  }
  .border-right-sm-0 {
    border-right: 0 !important;
  }
  .border-left-sm {
    border-left: 1px solid #e6e4e3 !important;
  }
  .border-left-sm-0 {
    border-left: 0 !important;
  }
}
@media (min-width: 768px) {
  .border-top-md {
    border-top: 1px solid #e6e4e3 !important;
  }
  .border-top-md-0 {
    border-top: 0 !important;
  }
  .border-bottom-md {
    border-bottom: 1px solid #e6e4e3 !important;
  }
  .border-bottom-md-0 {
    border-bottom: 0 !important;
  }
  .border-right-md {
    border-right: 1px solid #e6e4e3 !important;
  }
  .border-right-md-0 {
    border-right: 0 !important;
  }
  .border-left-md {
    border-left: 1px solid #e6e4e3 !important;
  }
  .border-left-md-0 {
    border-left: 0 !important;
  }
}
@media (min-width: 992px) {
  .border-top-lg {
    border-top: 1px solid #e6e4e3 !important;
  }
  .border-top-lg-0 {
    border-top: 0 !important;
  }
  .border-bottom-lg {
    border-bottom: 1px solid #e6e4e3 !important;
  }
  .border-bottom-lg-0 {
    border-bottom: 0 !important;
  }
  .border-right-lg {
    border-right: 1px solid #e6e4e3 !important;
  }
  .border-right-lg-0 {
    border-right: 0 !important;
  }
  .border-left-lg {
    border-left: 1px solid #e6e4e3 !important;
  }
  .border-left-lg-0 {
    border-left: 0 !important;
  }
}
@media (min-width: 1200px) {
  .border-top-xl {
    border-top: 1px solid #e6e4e3 !important;
  }
  .border-top-xl-0 {
    border-top: 0 !important;
  }
  .border-bottom-xl {
    border-bottom: 1px solid #e6e4e3 !important;
  }
  .border-bottom-xl-0 {
    border-bottom: 0 !important;
  }
  .border-right-xl {
    border-right: 1px solid #e6e4e3 !important;
  }
  .border-right-xl-0 {
    border-right: 0 !important;
  }
  .border-left-xl {
    border-left: 1px solid #e6e4e3 !important;
  }
  .border-left-xl-0 {
    border-left: 0 !important;
  }
}
.panel-warning {
  padding: 20px;
  font-size: 0.875rem;
  background-color: #faf8f7;
}

.sd-dropdown-item-icon {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 16px;
  height: 16px;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E%3Cpath fill='none' fill-rule='evenodd' stroke='%232b65d9' stroke-linecap='round' stroke-linejoin='round' d='M2 5.5l6 6 6-6'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
}

.sd-dropdown-item-icon.top-arrow {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.sd-btn-no-shadow:focus {
  box-shadow: none;
}

.sd-text-normal {
  white-space: normal;
}

.sd-cursor-pointer {
  cursor: pointer;
}

.sd-hovered-panel {
  border-radius: 3px;
}
.sd-hovered-panel:hover {
  border-color: #2b65d9;
}

.sd-faq-panel[aria-expanded=true] {
  background-color: #faf8f7;
}

.company-profile-badge {
  padding: 0 6px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.partner-badge.active {
  background-color: #fcf5ed;
}
@media (min-width: 768px) {
  .partner-badge:hover {
    background-color: #fcf5ed;
  }
}

.popover {
  border-color: rgba(115, 107, 103, 0.15);
  border-radius: 0;
}
.popover .popover-body {
  padding: 1rem;
  box-shadow: 0 4px 16px 0 rgba(26, 24, 23, 0.15);
}
.popover .popover-body .popover-heading {
  line-height: 1.25rem;
}

.rating-label {
  display: inline-block;
  padding: 2px 10px;
  font-family: hkgrotesk;
  color: #ffffff;
  border-radius: 0;
}

.rating {
  font-weight: 700;
  color: #ffffff;
}
.rating.background-white {
  color: #a6a2a1;
}

.rating-box {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 48px;
  height: 28px;
}
.rating-box.responsive-rating {
  width: auto;
  height: auto;
  padding: 0 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5rem;
}

.company-rating-unknown.rating-box {
  background-color: #a6a2a1;
}
.company-rating-unknown .rating {
  font-weight: 700;
  color: #ffffff;
}

.company-rating-very-bad.rating-box,
.rating-bad.rating-box {
  background-color: #f26d61;
}
.company-rating-very-bad .rating,
.rating-bad .rating {
  font-weight: 700;
  color: #ffffff;
}
.company-rating-very-bad .rating.background-white,
.rating-bad .rating.background-white {
  color: #f26d61;
}

.company-rating-bad.rating-box,
.rating-poor.rating-box {
  background-color: #eb9846;
}
.company-rating-bad .rating,
.rating-poor .rating {
  font-weight: 700;
  color: #ffffff;
}
.company-rating-bad .rating.background-white,
.rating-poor .rating.background-white {
  color: #eb9846;
}

.company-rating-medium.rating-box,
.rating-fair.rating-box {
  background-color: #13c265;
}
.company-rating-medium .rating,
.rating-fair .rating {
  font-weight: 700;
  color: #ffffff;
}
.company-rating-medium .rating.background-white,
.rating-fair .rating.background-white {
  color: #13c265;
}

.company-rating-good.rating-box,
.rating-good.rating-box {
  background-color: #13c265;
}
.company-rating-good .rating,
.rating-good .rating {
  font-weight: 700;
  color: #ffffff;
}
.company-rating-good .rating.background-white,
.rating-good .rating.background-white {
  color: #13c265;
}

.company-rating-very-good.rating-box,
.rating-excellent.rating-box {
  background-color: #13c265;
}
.company-rating-very-good .rating,
.rating-excellent .rating {
  font-weight: 700;
  color: #ffffff;
}
.company-rating-very-good .rating.background-white,
.rating-excellent .rating.background-white {
  color: #13c265;
}

.already-submitted {
  margin-top: 100px;
}

/**
  Review form
 */
/* stylelint-disable selector-max-class */
.review-form-container .ratings *:focus {
  outline: 0;
}
.review-form-container .ratings .rating .rating-container {
  max-height: 32px;
}
.review-form-container .ratings .rating .rating-star {
  display: inline-block;
  width: 32px;
  height: 32px;
  color: #ffffff;
  background-color: #a6a2a1;
  background-image: url("../images/move/icons/svg/rating-star.svg");
  background-repeat: no-repeat;
  background-position: center;
  border: 1px solid #a6a2a1;
  border-radius: 50%;
}
.review-form-container .ratings .rating .rating-star:first-child {
  margin-left: 0;
}
.review-form-container .ratings .rating .rating-star:last-child {
  margin-right: 0;
}
.review-form-container .ratings .rating .rating-star:hover {
  cursor: pointer;
}
.review-form-container .ratings .rating.rating-bad .rating-star.active {
  background-color: #f26d61;
  border-color: #f26d61;
}
.review-form-container .ratings .rating.rating-poor .rating-star.active {
  background-color: #eb9846;
  border-color: #eb9846;
}
.review-form-container .ratings .rating.rating-fair .rating-star.active {
  background-color: #13c265;
  border-color: #13c265;
}
.review-form-container .ratings .rating.rating-good .rating-star.active {
  background-color: #13c265;
  border-color: #13c265;
}
.review-form-container .ratings .rating.rating-excellent .rating-star.active {
  background-color: #13c265;
  border-color: #13c265;
}
.review-form-container .review-input-error {
  margin-bottom: 0;
  border: 2px solid #eb5042;
}
.review-form-container .text-error {
  color: #eb5042;
}
.review-form-container .rating {
  font-weight: 700;
  color: #ffffff;
}
.review-form-container .rating.background-white {
  color: #13c265;
}

/* stylelint-enable selector-max-class */
.review .rating {
  font-weight: 700;
  color: #ffffff;
}

.review-summary .rating-box {
  width: 64px;
}
.review-summary .rating-box.responsive-rating {
  width: auto;
  height: auto;
  padding: 0.125rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5rem;
}

.review-ratings {
  width: 170px;
  min-width: 170px;
}

.review-reply {
  border-radius: 0;
}

a {
  font-family: hkgrotesk;
}

@media (min-width: 992px) {
  .latest-article .custom-flex-container {
    display: -ms-flexbox;
    display: flex;
  }
  .latest-article .left-column {
    -ms-flex: 0 0 60%;
    flex: 0 0 60%;
  }
  .latest-article .right-column {
    -ms-flex: 0 0 40%;
    flex: 0 0 40%;
  }
}

.line-container {
  position: relative;
}
.line-container .horizontal-line {
  position: absolute;
  top: 19px;
  left: 40px;
  width: 75%;
  border: 0;
  border-top: 2px dashed rgb(208, 233.4, 244);
}
@media (min-width: 992px) {
  .line-container .horizontal-line {
    top: 23px;
    left: 48px;
  }
}
.line-container .horizontal-line.horizontal-line-lg {
  top: 31px;
  left: 64px;
  width: 65%;
}
@media (min-width: 992px) {
  .line-container .horizontal-line.horizontal-line-lg {
    top: 47px;
    left: 96px;
  }
}
.line-container .vertical-line {
  position: absolute;
  top: 40px;
  left: 20px;
  height: 70%;
  border: 0;
  border-left: 2px dashed rgb(208, 233.4, 244);
}
.line-container .vertical-line.vertical-line-lg {
  top: 64px;
  left: 32px;
}

.circle {
  position: relative;
  z-index: 15;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  font-weight: 700;
  color: #1493c8;
  border: solid 2px #1493c8;
  border-radius: 50%;
}
@media (min-width: 992px) {
  .circle {
    width: 48px;
    height: 48px;
  }
}
.circle.circle-sm {
  width: 40px;
  height: 40px;
}
.circle.circle-xs-not-resizable {
  width: 24px;
  height: 24px;
  font-size: 0.875rem;
  color: #2b65d9;
  border: solid 1px #2b65d9;
}
.circle.circle-xs {
  width: 24px;
  height: 24px;
}
@media (min-width: 992px) {
  .circle.circle-xs {
    width: 28px;
    height: 28px;
  }
}
.circle.circle-bg-secondary {
  background-color: #2b65d9;
  border: solid 2px #2b65d9;
}
.circle.circle-lg {
  width: 64px;
  height: 64px;
}
.circle.circle-lg img {
  width: 40px;
  height: 40px;
}
@media (min-width: 992px) {
  .circle.circle-lg {
    width: 96px;
    height: 96px;
  }
  .circle.circle-lg img {
    width: 64px;
    height: 64px;
  }
}
.circle.circle-shadow {
  position: absolute;
  top: 0.25rem;
  left: 1.25rem;
  z-index: 5;
  background-color: rgb(208, 233.4, 244);
  border: 0;
}

.circle-xs + .vertical-line {
  margin-left: 11px;
  border-left: 2px solid #1493c8;
}
@media (min-width: 992px) {
  .circle-xs + .vertical-line {
    margin-left: 13px;
  }
}

.circle-xs.circle-bg-secondary + .vertical-line {
  border-color: #2b65d9;
}

.horizontal-line-gray-300 {
  border-top: 1px solid #e6e4e3;
}

@media (min-width: 768px) {
  .w-lg-auto {
    width: auto !important;
  }
}

@media (min-width: 768px) {
  .h-lg-auto {
    height: auto !important;
  }
}

.h-2_5 {
  height: 2.5rem;
}

.mh-2_5 {
  max-height: 2.5rem;
}
@media (min-width: 992px) {
  .mh-2_5.mh-lg-3 {
    max-height: 3rem;
  }
}

.h-4 {
  height: 4rem;
}
@media (min-width: 576px) {
  .h-4.h-md-3 {
    height: 3rem;
  }
}

@media (max-width: 767.98px) {
  .col-6.col-6-closer:nth-child(2n-1) {
    padding-right: 8px;
  }
  .col-6.col-6-closer:nth-child(2n) {
    padding-left: 8px;
  }
}

.row.no-wrap .col.min-w-300px,
.row.no-wrap > [class^=col-].min-w-300px {
  min-width: 300px;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100, .menu-links .menu-nav li a {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.w-33 {
  width: 33% !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.h-33 {
  height: 33% !important;
}

@media (min-width: 576px) {
  .w-sm-25 {
    width: 25% !important;
  }
  .w-sm-50 {
    width: 50% !important;
  }
  .w-sm-75 {
    width: 75% !important;
  }
  .w-sm-100 {
    width: 100% !important;
  }
  .w-sm-auto {
    width: auto !important;
  }
  .w-sm-33 {
    width: 33% !important;
  }
  .h-sm-25 {
    height: 25% !important;
  }
  .h-sm-50 {
    height: 50% !important;
  }
  .h-sm-75 {
    height: 75% !important;
  }
  .h-sm-100 {
    height: 100% !important;
  }
  .h-sm-auto {
    height: auto !important;
  }
  .h-sm-33 {
    height: 33% !important;
  }
}
@media (min-width: 768px) {
  .w-md-25 {
    width: 25% !important;
  }
  .w-md-50 {
    width: 50% !important;
  }
  .w-md-75 {
    width: 75% !important;
  }
  .w-md-100 {
    width: 100% !important;
  }
  .w-md-auto {
    width: auto !important;
  }
  .w-md-33 {
    width: 33% !important;
  }
  .h-md-25 {
    height: 25% !important;
  }
  .h-md-50 {
    height: 50% !important;
  }
  .h-md-75 {
    height: 75% !important;
  }
  .h-md-100 {
    height: 100% !important;
  }
  .h-md-auto {
    height: auto !important;
  }
  .h-md-33 {
    height: 33% !important;
  }
}
@media (min-width: 992px) {
  .w-lg-25 {
    width: 25% !important;
  }
  .w-lg-50 {
    width: 50% !important;
  }
  .w-lg-75 {
    width: 75% !important;
  }
  .w-lg-100 {
    width: 100% !important;
  }
  .w-lg-auto {
    width: auto !important;
  }
  .w-lg-33 {
    width: 33% !important;
  }
  .h-lg-25 {
    height: 25% !important;
  }
  .h-lg-50 {
    height: 50% !important;
  }
  .h-lg-75 {
    height: 75% !important;
  }
  .h-lg-100 {
    height: 100% !important;
  }
  .h-lg-auto {
    height: auto !important;
  }
  .h-lg-33 {
    height: 33% !important;
  }
}
@media (min-width: 1200px) {
  .w-xl-25 {
    width: 25% !important;
  }
  .w-xl-50 {
    width: 50% !important;
  }
  .w-xl-75 {
    width: 75% !important;
  }
  .w-xl-100 {
    width: 100% !important;
  }
  .w-xl-auto {
    width: auto !important;
  }
  .w-xl-33 {
    width: 33% !important;
  }
  .h-xl-25 {
    height: 25% !important;
  }
  .h-xl-50 {
    height: 50% !important;
  }
  .h-xl-75 {
    height: 75% !important;
  }
  .h-xl-100 {
    height: 100% !important;
  }
  .h-xl-auto {
    height: auto !important;
  }
  .h-xl-33 {
    height: 33% !important;
  }
}
.static-text.intro,
.static-text.intro p {
  font-size: 1.125rem;
}

.static-text {
  color: #403e3d;
  /* Selector needs to be more strict to override only wordpress-sized images */
  /* stylelint-disable selector-no-qualifying-type */
}
.static-text .tablepress {
  margin-bottom: 1.5rem;
  font-size: 0.875rem;
  line-height: 1.42857143;
  border: 1px solid #e6e4e3;
}
.static-text .tablepress tfoot th,
.static-text .tablepress thead th {
  background-color: #e6e4e3;
  border-color: #e6e4e3;
}
.static-text .tablepress .odd td {
  background-color: #faf8f7;
}
.static-text .tablepress .row-hover tr:hover td {
  background-color: #f5f3f2;
}
.static-text .tablepress tr {
  border-bottom: 1px solid #e6e4e3;
}
.static-text .tablepress th,
.static-text .tablepress td {
  padding: 0.5rem 1.12rem;
}
@media (max-width: 575.98px) {
  .static-text .tablepress th,
  .static-text .tablepress td {
    padding: 0.5rem;
  }
}
.static-text .responsive-table {
  max-width: 100%;
  overflow-x: auto;
}
.static-text h1,
.static-text .h1 {
  margin-top: 2rem;
  margin-bottom: 1rem;
}
@media (min-width: 768px) {
  .static-text h1,
  .static-text .h1 {
    margin-top: 3.5rem;
    margin-bottom: 2rem;
  }
}
.static-text h2,
.static-text .h2 {
  margin-top: 1.75rem;
  margin-bottom: 0.75rem;
}
@media (min-width: 768px) {
  .static-text h2,
  .static-text .h2 {
    margin-top: 2.5rem;
    margin-bottom: 1.5rem;
  }
}
.static-text h3,
.static-text .h3 {
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
}
@media (min-width: 768px) {
  .static-text h3,
  .static-text .h3 {
    margin-top: 2rem;
    margin-bottom: 0.75rem;
  }
}
.static-text h4,
.static-text .h4,
.static-text .wp-block-yoast-faq-block .schema-faq-question,
.wp-block-yoast-faq-block .static-text .schema-faq-question {
  margin-top: 1rem;
  margin-bottom: 0.25rem;
}
@media (min-width: 768px) {
  .static-text h4,
  .static-text .h4,
  .static-text .wp-block-yoast-faq-block .schema-faq-question,
  .wp-block-yoast-faq-block .static-text .schema-faq-question {
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
  }
}
.static-text h5,
.static-text .h5 {
  margin-top: 1rem;
  margin-bottom: 0.25rem;
}
.static-text h1:first-child,
.static-text h2:first-child,
.static-text h3:first-child,
.static-text h4:first-child,
.static-text h5:first-child {
  margin-top: 0;
}
.static-text h4 {
  font-size: 1.25rem;
}
.static-text p {
  margin-bottom: 1rem;
}
.static-text p img {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
.static-text a {
  color: #2b65d9;
}
.static-text a:hover {
  color: #2b65d9;
}
.static-text a:hover.btn-secondary {
  color: #ffffff;
}
.static-text ul,
.static-text ol:not(.breadcrumb) {
  padding-left: 53px;
  margin-top: 1rem;
  margin-bottom: 1rem;
  list-style-type: none;
}
.static-text ul li,
.static-text ol:not(.breadcrumb) li {
  position: relative;
}
.static-text ul li::before,
.static-text ol:not(.breadcrumb) li::before {
  position: absolute;
  left: -27px;
  content: "•";
}
.static-text img[class*=align],
.static-text img[class*=wp-image-] {
  display: block;
  float: none;
  max-width: 100%;
  height: auto;
  margin-right: auto;
  margin-left: auto;
}

.sticky-form {
  border-top: solid 1px rgba(115, 107, 103, 0.15);
  box-shadow: 0 4px 16px 0 rgba(26, 24, 23, 0.15);
}
.sticky-form .close {
  position: absolute;
  top: 0;
  right: 0;
  opacity: 1;
}
@media (max-width: 991.98px) {
  .sticky-form .close {
    right: 15px;
  }
}

@-webkit-keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: 0.75s linear infinite spinner-border;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@-webkit-keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentcolor;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: 0.75s linear infinite spinner-grow;
  animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    -webkit-animation-duration: 1.5s;
    animation-duration: 1.5s;
  }
}
.font-hk-grotesk {
  font-family: hkgrotesk;
}

h1,
.h1 {
  margin-bottom: 1rem;
  font-variant-numeric: lining-nums;
  line-height: 1.25;
}
@media (min-width: 768px) {
  h1,
  .h1 {
    margin-bottom: 2rem;
    font-size: 2.5rem;
    line-height: 1.2;
  }
}

h2,
.h2 {
  margin-bottom: 0.75rem;
  font-variant-numeric: lining-nums;
  line-height: 1.23;
}
@media (min-width: 768px) {
  h2,
  .h2 {
    margin-bottom: 1.5rem;
    font-size: 1.875rem;
    line-height: 1.33;
  }
}

h3,
.h3 {
  margin-bottom: 0.5rem;
  font-variant-numeric: lining-nums;
  line-height: 1.27;
}
@media (min-width: 768px) {
  h3,
  .h3 {
    margin-bottom: 0.75rem;
    font-size: 1.5rem;
    line-height: 1.33333333;
  }
}

h4,
.h4,
.wp-block-yoast-faq-block .schema-faq-question {
  margin-bottom: 0.25rem;
  font-variant-numeric: lining-nums;
  line-height: 1.33;
  color: #403e3d;
}
@media (min-width: 768px) {
  h4,
  .h4,
  .wp-block-yoast-faq-block .schema-faq-question {
    margin-bottom: 0.5rem;
    font-size: 1.25rem;
    line-height: 1.3;
  }
}

h5,
.h5 {
  margin-bottom: 0.25rem;
  font-variant-numeric: lining-nums;
  line-height: 1.33;
  color: #403e3d;
}
@media (min-width: 768px) {
  h5,
  .h5 {
    font-size: 1.0625rem;
    line-height: 1.41;
  }
}

h6,
.h6 {
  margin-bottom: 0;
  font-variant-numeric: lining-nums;
  line-height: 1.42857143;
}
@media (min-width: 768px) {
  h6,
  .h6 {
    font-size: 0.875rem;
    line-height: 1.42857143;
  }
}

a {
  font-family: hkgrotesk;
  color: #2b65d9;
  text-decoration: none;
  cursor: pointer;
}
a:hover {
  color: #2b65d9;
  text-decoration: underline;
}

p > a {
  font-family: hkgrotesk;
}

p {
  font-size: 1rem;
  line-height: 1.625;
}

.p-small {
  font-size: 0.875rem;
  line-height: 1.42857143;
}

.p-large {
  font-size: 1.125rem;
  line-height: 1.625;
}

@media (min-width: 768px) {
  .p-lg-large {
    font-size: 1.125rem;
    line-height: 1.625;
  }
}
@media (min-width: 992px) {
  .p-lg-base {
    font-size: 1rem !important;
    line-height: 1.625 !important;
  }
}
.p-x-large, .hero-text.hero-description {
  font-size: 1.125rem;
  line-height: 1.625;
}
@media (min-width: 768px) {
  .p-x-large, .hero-text.hero-description {
    font-size: 1.25rem;
    line-height: 1.625;
  }
}

.pre-title {
  font-size: 1.125rem;
  line-height: 1.444444;
  color: #2142a6;
}
@media (min-width: 768px) {
  .pre-title {
    font-size: 1.25rem;
    line-height: 1.6;
  }
}

.smaller, .radio-block p, .review-summary .review-count {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.line-height-xs {
  line-height: 1;
}

.line-height-sm {
  line-height: 1.42857143;
}

.font-size-0_875 {
  font-size: 0.875rem;
}

.font-size-md, .review-summary .review-count {
  font-size: 1rem !important;
}

.font-size-lg {
  font-size: 1.125rem;
}

.font-size-xs {
  font-size: 0.625rem;
}

.lead {
  font-size: 1.125rem;
  line-height: 1.625;
}

.text-lg {
  font-size: 2.875rem;
}

.text-gray-dark {
  color: #1a1817;
}

.text-gray {
  color: #403e3d;
}

.text-gray-light {
  color: #73706f;
}

.text-gray-lighter {
  color: #a6a2a1;
}

.text-empty-state {
  font-style: italic;
  color: #a6a2a1;
}

.text-primary-dark {
  color: #009952;
}

.help-text {
  line-height: 1.25rem;
}

.text-secondary-dark {
  color: #2142a6;
}

.label {
  font-weight: 700;
  color: #403e3d;
}

.text-outstanding {
  font-size: 17px;
  font-weight: 700;
}

.link-secondary {
  color: #2b65d9;
  text-decoration: none;
}
.link-secondary:hover {
  color: #2b65d9;
  text-decoration: underline;
}
.link-secondary.text-decoration-underline {
  text-decoration: underline;
}
.link-secondary.text-decoration-none:hover {
  text-decoration: none;
}
.link-secondary.bold {
  font-weight: 700;
}
.link-secondary.large {
  font-size: 1.125rem;
  line-height: 1.625;
}
.link-secondary.small {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.link-grey {
  color: #403e3d;
}
.link-grey:hover {
  color: #403e3d;
}

.link-grey-light {
  color: #73706f;
}
.link-grey-light:hover {
  color: #73706f;
}

.word-break {
  word-break: break-word;
}

.no-word-break {
  word-break: normal;
}

.text-decoration-none:hover {
  text-decoration: none;
}

.navigation-list-toggle {
  display: block;
  margin-top: 1rem;
  color: #2b65d9;
}
.navigation-list-toggle::after {
  display: inline-block;
  content: "";
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E%3Cpath fill='none' fill-rule='evenodd' stroke='%232b65d9' stroke-linecap='round' stroke-linejoin='round' d='M2 5.5l6 6 6-6'/%3E%3C/svg%3E") no-repeat;
  border: 0;
}
.navigation-list-toggle::after {
  width: 1rem;
  height: 1rem;
  vertical-align: middle;
}
.navigation-list-toggle:not([href]):not([tabindex]) {
  color: #2b65d9;
}
.navigation-list-toggle:not([href]):not([tabindex]):focus, .navigation-list-toggle:not([href]):not([tabindex]):hover {
  color: #2b65d9;
}
.navigation-list-toggle:active {
  font-weight: 400;
}
.navigation-list-toggle::after {
  margin-left: 0.5rem;
}

.sd-toggled + .navigation-list-toggle::after {
  display: inline-block;
  content: "";
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E%3Cpath fill='none' fill-rule='evenodd' stroke='%232b65d9' stroke-linecap='round' stroke-linejoin='round' d='M2 5.5l6 6 6-6'/%3E%3C/svg%3E") no-repeat;
  border: 0;
}
.sd-toggled + .navigation-list-toggle::after {
  width: 1rem;
  height: 1rem;
  vertical-align: middle;
}
.sd-toggled + .navigation-list-toggle::after {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.move-company-registration .hide-placeholder::-webkit-input-placeholder {
  color: #a6a2a1;
}

.move-company-registration .hide-placeholder::-moz-placeholder {
  color: #a6a2a1;
}

.move-company-registration .hide-placeholder:-ms-input-placeholder {
  color: #a6a2a1;
}

.move-company-registration .hide-placeholder::-ms-input-placeholder {
  color: #a6a2a1;
}

.move-company-registration .hide-placeholder::placeholder {
  color: #a6a2a1;
}
.move-company-registration .sd-form-group .error,
.move-company-registration .form-group .error {
  margin-top: 0.5rem;
  font-size: 0.875rem;
}
.move-company-registration .sd-form-group .placeholder,
.move-company-registration .form-group .placeholder {
  display: none;
}
.move-company-registration .regions .card.hover {
  border-color: #2b65d9;
}
.move-company-registration .sd-control-label small {
  margin-left: 0.5rem;
  font-size: 0.875rem;
  color: #73706f;
  text-transform: capitalize;
}
.move-company-registration .sd-control-label small .parentheses {
  display: none;
}

.info-panel-trigger {
  width: 14px;
  height: 14px;
  margin-left: 4px;
  font-size: 12px;
  line-height: 1.2;
  color: #ffffff;
  text-align: center;
  background-color: #2b65d9;
  border: 1px solid #2b65d9;
  border-radius: 50%;
}
.info-panel-trigger.active, .info-panel-trigger[aria-describedby] {
  color: #2b65d9;
  background-color: #ffffff;
}
.info-panel-trigger:hover {
  cursor: pointer;
}

.selected-companies-v2-wrapper:last-child {
  margin-bottom: 0;
}
.selected-companies-v2-wrapper .company-location-row a {
  white-space: nowrap;
  visibility: hidden;
}
.selected-companies-v2-wrapper .company-location-row p {
  /* stylelint-disable */
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  /* stylelint-enable */
}
.selected-companies-v2-wrapper .company-location-row p.expanded {
  display: block;
}
.selected-companies-v2-wrapper .company-location-row.show-more a {
  visibility: visible;
}
.selected-companies-v2-wrapper .selected-company-v2 {
  margin-bottom: 2rem;
}
@media (max-width: 575.98px) {
  .selected-companies-v2-wrapper .selected-company-v2 {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}
.selected-companies-v2-wrapper .selected-company-v2:last-child {
  margin-bottom: 0;
}

.cities-list-wrapper {
  overflow-x: auto;
}
.cities-list-wrapper .row > .col-4 {
  min-width: 300px;
}
@media (max-width: 767.98px) {
  .cities-list-wrapper .row > .col-4 {
    min-width: 240px;
  }
}
.cities-list-wrapper a {
  color: inherit;
  text-decoration: none;
}

.city-element-image {
  width: 72px;
  height: 58px;
}
@media (max-width: 991.98px) {
  .city-element-image {
    width: 56px;
    height: 50px;
  }
}
.city-element-image img {
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.city-element-description {
  border: 1px solid #e6e4e3;
  border-left: 0;
}
.city-element-description-name {
  margin-right: 0.375rem;
  line-height: 1.5rem;
}
.city-element-description-companies-count {
  line-height: 1rem;
  color: #73706f;
}
.city-element:hover .city-element-description-name {
  text-decoration: underline;
}

/**
  Below specify custom Move SASS files
 */

/*# sourceMappingURL=bootstrap-move.css.map */